import { Avatar } from '@mui/material';
import { useEffect, useState } from 'react';
import api from '../../services/api';
import LoadingShort from '../../load/loadingShort';


export default function GetUser({ id }) {
    const [user, setUser] = useState('')

    useEffect(() => {
        api.get(`/user/${id}`)
            .then(response => {
                // console.log(response)
                setUser(response.data.user)

            }).catch(err => {
                // console.log(err)
            })
    }, [id])


    return (
        
        user ?
            <div className="d-flex" style={{ alignItems: 'center' }} >
                <picture className="me-2" >
                    <Avatar src={`data:image/jpeg;base64,${user?.foto_perfil}`} alt="img" style={{ width: '3em', height: '3em' }} />
                </picture>

                <div>
                    <p className="lh-sm m-0 text-dark">
                        <strong>{user?.nome}</strong>
                    </p>
                    <p className="lh-sm m-0 text-dark">
                        <strong>{user?.email}</strong>
                    </p>
                </div>
            </div >
            :
            < LoadingShort />
    )
}
