import FacebookIcone from '../icones/facebook'
import TiktokIcone from '../icones/tiktok'
import WhatsapIcone from '../icones/whatsap'

export default function ContactosTermosPrivacidade() {
    return (
        <>
            <div className="text-center mb-2">
                {/* {{-- <small className="p-2">Contacta-nos</small> --}} */}
                <br />
                {/* {{-- <a target="blank" href="https://mail.google.com/mail" className="text text-decoration-none mx-2" title="Enviar email">@include('icones.email')</a> --}} */}
                <a target="blank" href="https://www.tiktok.com/@meencontre.com?lang=pt-BR" className="text text-decoration-none mx-2 p-2" title="Encontra-nos no facebook"><TiktokIcone /></a>
                <a target="blank" href="https://www.facebook.com/MeEncontre-100505192678823" className="text text-decoration-none mx-1 p-2" title="Encontra-nos no facebook"><FacebookIcone /></a>
                <a target="blank" href="https://api.whatsapp.com/send?phone=924683811" className="text text-decoration-none mx-2 p-2" title="Contactar pelo whatsap"><WhatsapIcone /></a>
            </div>

            {/* TERMOS DE SEGURANCA E PRIVACIDADE */}
            <div className=" text-center m-3">
                <span>
                    <a className="text-decoration-none text-success" target="blank" href="/termos-e-condicoes">
                        <small>.Termos & condições .Privacidade</small>
                    </a>
                    <br />
                    <small> © {new Date().getFullYear()} JM</small>
                </span>
            </div>
        </>
    )
}
