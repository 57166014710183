
import { useEffect, useRef, useState, useCallback } from 'react';
import LoadingSkeleton from '../../load/loadingSkeleton';
import { useDispatch } from 'react-redux';
import Loading from '../../load/loading';
import api from '../../services/api';
import CombineCards from '../../components/cards/combineCards';
import RegisterAccess from '../../services/registerAccess';


export default function MeusGuardados({ setHandleUpdate, handleUpdate, perfil }) {
  const dispatch = useDispatch();
  const [feedItemsConfig, setFeedItemsConfig] = useState();
  const [feedItems, setFeedItems] = useState('');
  const [page, setPage] = useState(1);
  const feedRef = useRef(null); 

  const fetchMoreData = useCallback(async () => {

    dispatch({
      type: 'alert',
      payload: { alert: null },
    });

    try {
      const newData = await fetchData(page);
      setFeedItemsConfig(newData);
      setFeedItems((prevItems) => [...prevItems, ...newData.data]);
      setPage(page + 1);

    } catch (error) {
      // return console.log(error.code)

      if (error.code === "ERR_NETWORK") {
        dispatch({
          type: 'alert',
          payload: { alert: { message: 'Neste momento, estás offline.' } },
        });
      }
    }
  }, [page, handleUpdate]); // A função depende apenas de page e handleUpdate, que é estável entre renderizações

  const fetchData = async (currentPage) => {
    const response = await api.get(`/perfil/meus-salvos?page=${currentPage}`);
    return response.data.anuncios;
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        fetchMoreData();
      }
    });

    if (feedRef.current) {
      observer.observe(feedRef.current);
    }

    return () => {
      if (feedRef.current) {
        observer.unobserve(feedRef.current);
      }
    };
  }, [feedRef, fetchMoreData, handleUpdate]);


  return (
    <>
       <RegisterAccess page={'pagina meus guardados'} />

      {(page === 1 && !feedItems) &&
        <div ref={feedRef} >
          <LoadingSkeleton />
        </div>
      }

      <CombineCards
        publicacoes={feedItems}
        setHandleUpdate={setHandleUpdate}
        perfil={perfil}
      />

      {(feedItemsConfig?.next_page_url) &&
        <div ref={feedRef} className="text-center text-success">
          <Loading />
          <small>Encontre mais publicações</small>
        </div>

      }
      {(feedItems && !feedItemsConfig?.next_page_url) &&
        <p style={{ textAlign: 'center' }}>
          <small>Sem publicações guardadas</small>
        </p>
      }
    </>
  );
}
