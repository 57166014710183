
import { useDispatch } from 'react-redux';
import { Avatar } from '@mui/material';
import { useState } from 'react';
import LoadingShort from '../load/loadingShort';
import { IconButton } from "@mui/material";
import EditeFotoIcone from '../icones/editeFoto';
import api from '../services/api';
import compressImage from '../services/compressImage'

export default function ImageUpdate({ user }) {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    const update = (e) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('image', image);
        formData.append('user_id', user?.id);

        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        api.post(`/perfil/image-update`, formData)
            .then(response => {
                setLoading(false);

                // dispatch({
                //     type: 'user',
                //     payload: { user: response.data.user }
                // })
                dispatch({
                    type: 'alert',
                    payload: { alert: response.data }
                })
                window.location.reload()
                // console.log(response)
            }).catch(err => {
                setLoading(false);
                // console.log(err)
            })
    }

    return (
        <div className='text-center'>
            <picture title="Editar imagem de perfil" style={{ cursor: 'pointer' }}>
                {user?.foto_perfil ?
                    <div className='d-inline-block' style={{ position: 'relative' }}>
                        <label className="my-2" htmlFor="img" style={{ cursor: 'pointer' }}>
                            {image ?
                                <Avatar src={URL.createObjectURL(image)} alt="img" style={{ border: 'solid rgb(2, 71, 0) 1px', width: '10em', height: '10em' }} />
                                :
                                <>
                                    <Avatar src={apiUrl + user?.foto_perfil} alt="img" style={{ border: 'solid rgb(2, 71, 0) 1px', width: '10em', height: '10em' }} />
                                    <IconButton className='bg-success' style={{ position: 'absolute', right: 0, bottom: 35 }}>
                                        <EditeFotoIcone />
                                    </IconButton>
                                </>
                            }
                            <input accept="image/png, image/jpg, image/jpeg" id="img" name="img" type="file" style={{ display: 'none' }} onChange={async (e) => setImage(await compressImage(e.target.files[0]))} />
                        </label>
                    </div>
                    :
                    <div className='d-inline-block' style={{ position: 'relative' }}>
                        <label className="my-2" htmlFor="img" style={{ cursor: 'pointer' }}>
                            {image ?
                                <Avatar src={URL.createObjectURL(image)} alt="img" style={{ border: 'solid rgb(2, 71, 0) 1px', width: '10em', height: '10em' }} />
                                :
                                <>
                                    <img src="/img/utilizador.svg" className="img-fluid mb-2" alt="imagem" style={{ width: '150px' }} />
                                    <IconButton className='bg-success' style={{ position: 'absolute', right: 0, bottom: 35 }}>
                                        <EditeFotoIcone />
                                    </IconButton>
                                </>
                            }
                            <input accept="image/png, image/jpg, image/jpeg" id="img" name="img" type="file" style={{ display: 'none' }} onChange={(e) => setImage(e.target.files[0])} />
                        </label>
                    </div>
                }
            </picture>
            <br />

            {image &&
                <button
                    onClick={update}
                    type="submit"
                    className=" btn btn-success mx-auto">
                    {loading ?
                        <LoadingShort />
                        :
                        'Salvar'
                    }
                </button>
            }

        </div>

    )
}
