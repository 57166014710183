import HeaderCardInformacaoUser from '../header/headerCard-informacaoUser'
import Auth from '../../services/auth/auth'
import CreateComentario from '../createComentario'
import { useState } from 'react'
import HeaderCardPerfilUser from '../header/headerCard-perfilUser'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import TextoFormatado from '../../utils/textoFormatado'
// import ComentariosAutoLoader from '../comentariosAutoLoader'

export default function CardPublicacao({ publicacao, perfil, setHandleUpdate }) {
    const [handlePublicacao, setHandlePublicacao] = useState(publicacao)
    // const [openComentarios, setOpenComentarios] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const viewPublicacao = (publicacao) => {
        dispatch({
            type: 'viewPublicacao',
            payload: { viewPublicacao: publicacao },
        });

        navigate(`/full-view/${publicacao?.id}`)
    }

    return (
        <div className='card'>
            <div className="card-body text-dark ">
                {perfil ?
                    <HeaderCardPerfilUser setHandleUpdate={setHandleUpdate} publicacao={publicacao} />
                    :
                    <HeaderCardInformacaoUser publicacao={publicacao} />
                }
                {/* <TextoFormatado texto={publicacao.descricao} /> */}
                <TextoFormatado
                    texto={publicacao.descricao}
                    publicacao={publicacao}
                    viewPublicacao={viewPublicacao}
                />
            </div>

            <div className="card-body pb-1 text-end border-bottom ">
                {handlePublicacao?.comentarios.length < 2 ?
                    <small className="fw-bold">{handlePublicacao?.comentarios.length} comentário </small>
                    :
                    <small className="fw-bold">{handlePublicacao?.comentarios.length} comentários
                        <br />
                        <Link onClick={() => viewPublicacao(publicacao)} to={`/full-view/${publicacao?.id}`} className="text-decoration-none text-success">
                            <small>Ver comentários</small>
                        </Link>
                        <br />
                        {/* <span onClick={() => setOpenComentarios(!openComentarios)}>Ver comentários</span> */}
                    </small>
                }
            </div>

            <Auth>
                <div className="card-body">
                    <CreateComentario
                        user_id={1}
                        publicacao={publicacao}
                        setHandlePublicacao={setHandlePublicacao}
                    />
                    {/* 
                    {openComentarios &&
                        <div style={{ maxHeight: '35vh', overflow: 'scroll' }}>
                            <ComentariosAutoLoader
                                publicacao={handlePublicacao}
                                setHandlePublicacao={setHandlePublicacao}
                            />
                        </div>
                    } */}
                </div>
            </Auth>
        </div>
    )
}
