// TEMPLATE ADMINISTRAVO PRINCIPAL 
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import ListItemText from '@material-ui/core/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
// import Collapse from '@mui/material/Collapse';
// import ExpandLess from '@mui/icons-material/ExpandLess';
// import ExpandMore from '@mui/icons-material/ExpandMore';
import AppsIcon from '@material-ui/icons/Apps';
import { Box } from '@material-ui/core';
// import CachedIcon from '@mui/icons-material/Cached';
// import { Avatar, Button, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
// import { signOut } from '../../actions/accountActions';
import PesquisarEstabelecimento from './pesquisarUser';
// import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import LoadingBackdrop from '../../load/loadingBackdrop';
import UserAdmin from './avatarAdmin';
import { useLocation } from 'react-router-dom';

const drawerWidth = 230;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    rootAcordiao: {
        width: '100%',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        position: 'relative',
        // height: '100vh'
        // zIndex: 1,
    },
    drawerPaper: {
        width: drawerWidth,
        background: '#62aa8c',
        // background: '#0c0b1e',
        color: '#fff',

        // [theme.breakpoints.down('xs')]: {
        //     zIndex: 1
        // },

        '&::-webkit-scrollbar': {
            width: '3px'
        },
        ' &::-webkit-scrollbar-thumb': {
            backgroundColor: '#000',
            // borderRadius: '10px',
        },
        ' &::-webkit-scrollbar-track': {
            backgroundColor: '#fff'
        }
    },

    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        // padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        // marginBottom: 40,

    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        background: '#ebebf4',
        paddingTop: 0,
        minHeight: '100vh',
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        paddingTop: 0,
        marginLeft: 0,
        background: '#ebebf4',
        minHeight: '100vh',
    },

    img: {
        maxHeight: 55,
        width: 160,
        margin: 10,
    },

    icones: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 10,
    },

    icone: {
        margin: 7,
        cursor: 'pointer',
        // '&:hover': {
        //     backgroundColor: '#b1b7ea',
        //     borderRadius: 3,
        // },
    },

    titulo: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },

    // ACORDIAO ####################
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    hoverList: {
        transition: 'all .3s',
        '&:hover': {
            background: '#24274a',
        },
    },
    hoverListItem: {
        // padding: 3,
        margin: 1,
        fontSize: 15,
        transition: 'all .3s',
        '&:hover': {
            // color: '#fff',
            backgroundColor: '#355d4d',
            borderRadius: 3,
            // fontSize: 20,
        },
    },
    ondeEstou: {
        backgroundColor: '#355d4d',
        borderRadius: 3,
    },

    // IMAGEM DE PERFIRL DE ADMIN LOGADO
    profile: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'right',
    },
    info: {
        margin: '0 10px',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    }
}));


export default function TemplateAdmin({ element: component, ...rest }) {
    const url = useLocation();
    useEffect(() => { document.title = "meencontre-Admin" }, [])
    const user = useSelector(state => state.account.user);
    // const user = JSON.parse(localStorage.getItem("user"))
    // configView VARIAVEL QUE CONTROLA O MODO DE EXIBIÇÃO DO TEMPLATE ADMIN
    const configView = JSON.parse(localStorage.getItem("configView"))
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(configView);
    const navegate = useNavigate()

    const handleDrawerOpen = () => {
        localStorage.setItem("configView", JSON.stringify(true));
        setOpen(true);
    };

    const handleDrawerClose = () => {
        localStorage.setItem("configView", JSON.stringify(false));
        setOpen(false);
    };

    return (
        <>
            {!user
                ?
                <LoadingBackdrop open={true} text={'meencontre - Gestor'} />
                :
                <div className={classes.root}>
                    <CssBaseline />
                    {/* ################################## INICIO NavBar  ####################################### */}
                    <AppBar
                        position="fixed"

                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: open,
                        })}

                        style={{ backgroundColor: '#fff', color: '#000' }}
                    >
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, open && classes.hide)}
                            >
                                <MenuIcon />
                            </IconButton>

                            <div className={classes.titulo} >
                                <Typography variant="h1" style={{ fontFamily: 'Work Sans', fontSize: 22, marginLeft: 15 }}>
                                    <strong> GESTOR</strong>
                                </Typography>
                            </div>

                            <div className={classes.icones}>
                                <PesquisarEstabelecimento />
                                {/* <CachedIcon titleAccess='Limpar cach' onClick={() => window.location.reload()} className={classes.icone} /> */}
                                {/* <AppsIcon titleAccess='Menu' className={classes.icone} /> */}
                            </div>

                            <div className={classes.profile}>
                                <div className={classes.info}>
                                    <p>{user?.nome}
                                    <br />
                                    <small>{user?.perfil}</small>
                                    </p>
                                </div>

                                <div className={classes.profilePhoto}>
                                    {/* <Avatar src={user?.dadosPessoais.fotoURL} alt="" /> */}
                                    <UserAdmin />
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {/* ################################## FIM NavBar  ####################################### */}

                    {/* ################################## INICIO NavLATERAL  ####################################### */}
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <Box className={classes.drawerHeader} style={{ background: '#fff', marginBottom: 40 }}>

                            {/* <img src="" alt="CNP" height={44} className={classes.img} /> */}
                            <Link to={'/'} className="navbar-brand text-success position-relative">
                            <img className="img-fluid d-block m-auto mx-3" src="/icones/iconesMeencontre/meencotreHorizontal.svg" alt="meencontre" style={{ width: '100%' }} />
                                {/* <h3><strong> MeEncontre</strong></h3> */}
                            </Link>

                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon color='primary' /> : <ChevronRightIcon color='primary' />}
                            </IconButton>
                        </Box>

                        <List
                            style={{ padding: 10 }}
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                        >
                            <div className={`${classes.hoverListItem} ${url.pathname === '/admin/dashboard' && classes.ondeEstou}`}>
                                <ListItemButton  onClick={() => { navegate('/admin/dashboard')}}>
                                    Dashboard
                                </ListItemButton>
                            </div>

                            <div className={`${classes.hoverListItem} ${url.pathname === '/admin' && classes.ondeEstou}`}>
                                <ListItemButton disabled  onClick={() => { navegate('/admin')}}>
                                    Menu
                                </ListItemButton>
                            </div>
                            <div className={`${classes.hoverListItem} ${url.pathname === '/admin/mapa' && classes.ondeEstou}`}>
                                <ListItemButton  onClick={() => { navegate('/admin/mapa') }}>
                                    Mapa
                                </ListItemButton>
                            </div>

                            <div className={`${classes.hoverListItem} ${url.pathname === '/admin/configuracoes' && classes.ondeEstou}`}>
                                <ListItemButton  onClick={() => { navegate('/admin/configuracoes')}}>
                                    Configurações
                                </ListItemButton>
                            </div>
                            <div className={`${classes.hoverListItem} ${url.pathname === '/admin/control-accesses' && classes.ondeEstou}`}>
                                <ListItemButton  onClick={() => { navegate('/admin/control-accesses')}}>
                                    Control Accesses
                                </ListItemButton>
                            </div>

                        </List>
                    </Drawer>
                    {/* ################################## FIM  NavBar  ####################################### */}


                    {/* ##################################  INICIO CONTAINER  ####################################### */}
                    <main
                        // style={{ maxWidth: '100vw' }}
                        className={clsx(classes.content, {
                            [classes.contentShift]: open,
                        })}
                    >
                        <div className={classes.drawerHeader} />

                        {component}

                    </main>
                    {/* ##################################  FIM CONTAINER  ####################################### */}
                </div >
            }
        </>
    );
}
