import { useSelector } from 'react-redux';
import ConfiguracoesIcone from '../icones/configuracoes';
import GuardadosIcone from '../icones/guardados';
import TransferenciaIcone from '../icones/transferencia';
import ImageUpdate from './imageUpdate';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react'
import { useDispatch } from 'react-redux';


// ESTA VARIAVEL SERVE PARA DESTACAR O MENU OU LOCAL ACTUAL
const ondeEstou = 'border-bottom border-4 border-success'

export default function Header({ estatistica }) {
    const dispatch = useDispatch();
    const user = useSelector(state => state.account.user);
    const url = useLocation();
    // console.log(url.pathname)

    // LIMPAR O ALET DO SISTEMA SEMPRE A PAGINA FOR RECARREGADA.
    useEffect(() => {
        dispatch({
            type: 'alert',
            payload: { alert: null },
        });
    }, [])

    return (
        <div className="card border-0 text-center p-2 mb-4 ">

            <div className="mt-4">
                <ImageUpdate user={user} />
            </div>

            <div>
                <h3 className="text-capitalize">{user?.nome}</h3>
                <h6>{user?.email}</h6>
                {/* <h6>{user?.contacto}</h6> */}
                {/* <small><Link to="" className="text-success">Ver mais</Link></small> */}

                <hr className=" bg-success m-2" />
            </div>

            <div className="d-flex col">

                <Link className={`text-decoration-none text-dark ${url.pathname === '/perfil/minhas-publicacoes' && ondeEstou}`} to={'/perfil/minhas-publicacoes'}>
                    <button className="btn">
                        Publicações
                        {estatistica &&
                            <strong> {estatistica?.totalAnuncios + estatistica.totalDenuncias + estatistica?.totalPublicacoes}</strong>
                        }
                    </button>
                </Link>

                <Link className={`text-decoration-none text-dark ${url.pathname === '/perfil/meus-patrimonios' && ondeEstou}`} to={'/perfil/meus-patrimonios'}>
                    <button className="btn">
                        Patrimônios
                        {estatistica &&
                            <strong> {estatistica?.totalPatrimonios}</strong>
                        }
                    </button>
                </Link>

                <span className="d-none d-md-block">
                    <Link className={`text-decoration-none text-dark d-none d-md-block ${url.pathname === '/perfil/historico-transferencias' && ondeEstou}`} to={'/perfil/historico-transferencias'}>
                        <button className="btn">
                            Transferências
                        </button>
                    </Link>
                </span>

                <Link className={`text-decoration-none text-dark d-none d-md-block ${url.pathname === '/perfil/meus-guardados' && ondeEstou}`} to={'/perfil/meus-guardados'}>
                    <button className="btn">
                        Guardados
                    </button>
                </Link>

                <div className="text-end" style={{ flexGrow: 1 }}>

                    <div>
                        <button type="button" className="btn" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots" viewBox="0 0 16 16">
                                <path
                                    d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                            </svg>
                        </button>

                        <ul className="dropdown-menu dropdown-menu-end">
                            <li className="dropdown-item d-block d-md-none">
                                <TransferenciaIcone />
                                <Link className="text-decoration-none text-dark"
                                    to={'/perfil/historico-transferencias'}>
                                    Transferências
                                </Link>
                            </li>

                            <li className=" d-block d-md-none">
                                <Link className="text-decoration-none" to={'/perfil/meus-guardados'}>
                                    <button className="dropdown-item" type="button">
                                        <GuardadosIcone /> Guardados
                                    </button>
                                </Link>
                            </li>

                            <li>
                                <Link className="text-decoration-none" to={'/configuracoes'}>
                                    <button className="dropdown-item" type="button">
                                        <ConfiguracoesIcone /> Configurações
                                    </button>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )
}
