import HomeIcone from '../icones/home'
import AnuncioIcone from '../icones/anuncio'
import FiltroIcone from '../icones/filtro'
import EncontrarIcone from '../icones/encontrar'
import GuardadosIcone from '../icones/guardados'
import PatrimonioIcone from '../icones/patrimonio'
import PatrimonioDenunciadoIcone from '../icones/patrimonioDenunciado'
import CarinhasorrisoIcone from '../icones/carinhasorriso'
import EsperancaIcone from '../icones/esperanca'
import AddAnuncioIcone from '../icones/addAnuncioIcone'

export default function Definicoes() {
  return (
    
<div className="container" style={{textAlign: 'justify'}}>
    <h5 >Definições</h5>
    <p >
        <strong>meencontre:</strong> É um website/aplicação que conecta pessoas para ações conjuntas na procura 
        e divulgação bens e pessoas desaparecidas. Também possibilita o usuario fazer registro de bens pessoais
        denominados como patrimônios, meencontre ajuda a protege-los e procurar em caso de perdas ou for roubo.
    </p>
    <p>
        <strong><HomeIcone/> Inicio:</strong> página início do site e/ou Aplicações meencontre onde
        podemos ver anuncios, publicações dos usuarios e resultados obtidos "encontrados" com os serviços do meencontre.
    </p>
    <p>
        <strong><AddAnuncioIcone/> Fazer anuncio:</strong> publicação de conteudo dados (visual, textual,
        fotografias, desenhos), referente a algo/alguem que está sendo procurado ou também que se pretende encontrar o
        seu proprietário ou responsavel.
    </p>
    {/* <p>
        <strong><FiltroIcone/> Filtrar:</strong> filtro para buscar uma informação sobre dados inserido de
        forma especifica.
    </p> */}
    <p>
        <strong><FiltroIcone/> Encontrar:</strong> opção para ver conteudos de forma categórica, te
        possibilitando apenas ver conteúdos do teu interesse.
    </p>
    <p>
        <strong><GuardadosIcone/> Guardados:</strong> anuncios ou publicações que desejas continuar sempre
        ver e ter contacto “ para fazer isto basta clicar na opção guardar que aparece nas publicações”.
    </p>
    <p>
        <strong><PatrimonioIcone/> Patrimônio:</strong> definimos como qualquer bem pessoal que no minimo
        tenha um número de idenficação única.
    </p>
    <p>
        <strong><PatrimonioIcone/> Registar / Registar patrimônio:</strong> funcionalidade que permite os
        usuarios fazerem registo de patrimónios, apenas inserindo o nome do patrimônio no número de identificação (ID)
        e a uma imagem bem visivel do patrimônio.
    </p>

    <p>
        <strong><PatrimonioDenunciadoIcone/> Denuncia:</strong> é um serviço que permite o usuario informar dentro do
        meencontre que seu patrimônio está sendo procurado. As denuncias se aplicam apenas a patrimônios
        que já estão registrados no meencontre.
    </p>
    <p>
        <strong><PatrimonioDenunciadoIcone/> Patrimônios denunciados:</strong> lista de todos os patrimônios que estão
        sendo procurado pelos seus proprietários dentro do meencontre.
    </p>

    <p>
        <strong><CarinhasorrisoIcone/> Encontrados:</strong> resultados obtidos pela utilização do Site e
        Aplicações mencontre.
    </p>
    <p>
        <strong><EsperancaIcone/> Esperança:</strong> reação dos usuarios em anuncios. Significa enviar votos
        de esperanças para pessoas que faz o anuncio.
    </p>
    <hr/>
</div>

  )
}
