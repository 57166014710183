
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function ConfigConta() {
  const user = useSelector(state => state.account.user);

  return (
    <div className="container">

      <h5>Configuração de conta</h5>
      <hr className="d-none d-md-block" />

      <div className="row my-3 border-bottom">
        <div className="col"> <strong>Nome</strong></div>
        <div className="col text-end">
          <p className="m-0">{user?.nome}</p> <small><Link to={'/configuracoes/edite-nome'} className="text-decoration-none">Alterar</Link></small>
        </div>
      </div>

      <div className="row my-3 border-bottom">
        <div className="col"><strong>E-mail</strong></div>
        <div className="col text-end">
          <p className="m-0">{user?.email}</p> <small><Link to={'/configuracoes/edite-email'}  className="text-decoration-none">Alterar</Link></small>
        </div>
      </div>

      <div className="row my-3 border-bottom">
        <div className="col"><strong>Contacto</strong></div>
        <div className="col text-end">
          <p className="m-0">{user?.contacto}</p>
          <small> <Link to={'/configuracoes/edite-contacto'} className="text-decoration-none">
            {user?.contacto ?
              'Alterar'
              :
              'Adicionar contacto'
            }
          </Link>
          </small>
        </div>
      </div>

      <div className="row my-3 border-bottom">
        <div className="col"><strong>Senha</strong></div>
        <div className="col text-end"><small><Link to={'/configuracoes/edite-senha'} className="text-decoration-none">Alterar senha</Link></small></div>
      </div>

      <div className="row my-3">
        <div className="col">
          <button className='btn disabled' ><Link  to={'/configuracoes/desativar-conta'} className=" text-decoration-none text-danger">Desativar conta</Link></button>
          {/* {{-- <p><a href="">Apagar conta</a></p> --} */}
        </div>
      </div>

      <hr className="d-block d-md-none" />
    </div>
  )
}
