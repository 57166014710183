import './LoadingSkeleton.css'; // Arquivo de estilos CSS para o esqueleto

const LoadingSkeleton = () => {
    return (
        <div className="social-network-skeleton">
            <main className="skeleton-main">
                <div className="post-skeleton">
                    <header className="user-info">
                        <div className="avatar"></div>
                        <div className="user-details">
                            <div className="username"></div>
                            <div className="timestamp"></div>
                        </div>
                    </header>
                    <br />
                    <div className="post-header"></div>
                    <div className="post-content"></div>
                </div>
            </main>
        </div>
    );
};

export default LoadingSkeleton;
