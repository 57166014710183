import { useEffect, useState } from 'react'
import EliminarIcone from '../../icones/eliminar'
import LoadingShort from '../../load/loadingShort'
import api from '../../services/api'
import { useDispatch } from 'react-redux';
import Loading from '../../load/loading';

export default function CreateGeneros() {
    const dispatch = useDispatch();
    const [generos, setGeneros] = useState('')
    const [novoGenero, setNovoGenero] = useState([])
    const [loading, setLoading] = useState('')


    useEffect(() => {
        api.get('/generos')
            .then(res => {
                setGeneros(res.data.generos);
            }).catch(err => '')

    }, [])

    const handleInputChange = (e) => {
        setNovoGenero({ ...novoGenero, [e.target.name]: e.target.value, });
    };

    const create = (e) => {
        e.preventDefault();
        setLoading('Salvar');

        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        api.post(`/create-genero`, novoGenero)
            .then(response => {
                // console.log(response)
                setLoading(false);
                setGeneros(response.data.generos)

                dispatch({
                    type: 'alert',
                    payload: { alert: response.data },
                });

            }).catch(err => {
                // console.log(err)
                setLoading(false);
            })
    }


    const delet = (id) => {
        setLoading(id);

        dispatch({
            type: 'alert',
            payload: { alert: null }
        });

        api.post(`/delete-genero/${id}`)
            .then(response => {
                setLoading(false);
                setGeneros(response.data.generos)

                dispatch({
                    type: 'alert',
                    payload: { alert: response.data },
                });

            }).catch(err => {
                setLoading(false);
            })
    }

    return (
        <div class="card card-body mb-4">
            <div class="row g-3 m-0" >
                <h4>ADD GENERO</h4>

                <div class="col-md-6">
                    <input onChange={handleInputChange} required type="text" class="form-control" placeholder="Novo genero" name="genero" />
                    <button
                        onClick={create}
                        class="btn btn-success mt-2">
                        {loading === 'Salvar'
                            ?
                            <LoadingShort />
                            :
                            'Salvar'
                        }
                    </button>
                </div>

                <div class="col-md-6">
                    <strong>Generos cadastradas</strong>
                    {generos ?
                        <ul>
                            {generos.map((genero) => (
                                <li key={genero.id} >
                                    <button onClick={() => delet(genero.id)} className="btn btn-outline-success border-0 p-0" type="submit" title="apagar genero">
                                        {loading === genero.id
                                            ?
                                            <LoadingShort />
                                            :
                                            <EliminarIcone />
                                        }
                                    </button>
                                    {genero.genero}
                                </li>
                            ))}
                        </ul>
                        :
                        <Loading />
                    }
                </div>
            </div>
        </div>

    )
}
