import { useEffect, useState } from 'react'
import { Avatar } from '@mui/material';
import { useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import Loading from '../../load/loading';
import CardPatrimonio from '../../components/cards/cardPatrimonio';
import api from '../../services/api';
import { useSelector } from 'react-redux';
import LoadingShort from '../../load/loadingShort';
import MessageError from '../../messages/messageErrorCollapse';
import { useDispatch } from 'react-redux';
import RegisterAccess from '../../services/registerAccess';

export default function Transferencia() {
    const user_id = useSelector(state => state.account.user?.id);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const { id } = useParams()
    const [patrimonio, setPatrimonio] = useState('')
    const [emailDestinatario, setEmailDestinatario] = useState('')
    const [success, setSuccess] = useState('')
    const [error, setError] = useState('')

    useEffect(() => {
        api.get(`/perfil/patrimonio/${id}`)
            .then(response => {
                response.data.patrimonio.user_id == user_id ?
                    setPatrimonio(response.data.patrimonio)
                    :
                    navigate(-1)
                // console.log(response)
            }).catch(err => {
                // console.log(err)
            })
    }, [id])

    const verifyEmail = () => {
        setLoading('verify');
        setError('')
        setSuccess('')

        api.get(`/perfil/virify-email/${emailDestinatario}`)
            .then(response => {
                // console.log(response)
                setSuccess(response.data.destinatario)
                setLoading(false);
            }).catch(err => {
                // console.log(err)
                setError(err.response.data.message)
                setLoading(false);
            })
    }

    const transfer = () => {
        setLoading('transfer');
        setError('')
        // setSuccess('')

        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        api.post(`/perfil/transfer-patrimomio`, {
            patrimonio_id: id,
            destinatario_id: success.id
        })
            .then(response => {
                // console.log(response)
                setSuccess(response.data.destinatario)
                setLoading(false);

                dispatch({
                    type: 'alert',
                    payload: { alert: response.data },
                });

                // navigate(-1)
                window.location.reload()
            }).catch(err => {
                // console.log(err)
                setError(err.response.data.message)
                setLoading(false);
            })
    }

    return (
        <>
           <RegisterAccess page={'pagina de transferencia de patrimonio'} />
            {patrimonio ?
                <>
                    <div className="modal-content border-bottom-0" style={{ marginBottom: -3 }}>

                        <div className="modal-header border-0 alert-success">
                            <h5 className="modal-title">Transferência de patrimônio</h5>
                        </div>

                        <div className='card-body'>
                            <p className="m-2">Insera email de usuario/a destinatário/a</p>

                            <div className="d-flex card-body p-2 mb-4">
                                <input onChange={(e) => setEmailDestinatario(e.target.value)} className="form-control" placeholder="Inserir email" id="email" type="email" name="user_email" required autoFocus />

                                <button
                                    onClick={verifyEmail}
                                    type="submit"
                                    disabled={!emailDestinatario}
                                    className="btn btn-sm btn-success border-0 mx-1">
                                    {loading === 'verify' ?
                                        <LoadingShort />
                                        :
                                        'Verificar'
                                    }
                                </button>
                            </div>

                            {/* RESULTADO DE VERIFICACO DE EMAIL */}
                            <div className="m-2">
                                {error && <MessageError message={error} />}

                                {success &&
                                    <div className="d-inline-flex" style={{ alignItems: 'center' }}>
                                        <picture className="me-2" >
                                            <Avatar src={`data:image/jpeg;base64,${success?.foto_perfil}`} alt="img" style={{ border: 'solid rgb(2, 71, 0) 1px', width: '5em', height: '5em' }} />
                                        </picture>

                                        <div>
                                            <p className="lh-sm m-0 text-dark">
                                                <strong>{success?.nome}</strong>
                                            </p>
                                            <p className="lh-sm m-0 text-dark">
                                                <strong>{success?.email}</strong>
                                            </p>
                                            <button
                                                onClick={transfer}
                                                type="submit"
                                                disabled={!emailDestinatario}
                                                className="btn btn-sm btn-success border-0 mt-2">

                                                {loading === 'transfer' ?
                                                    <LoadingShort />
                                                    :
                                                    'Transferir'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <CardPatrimonio item={patrimonio} />
                </>
                :
                <Loading />
            }
        </>

    )
}
