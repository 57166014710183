
import { Card, makeStyles } from "@material-ui/core";
import { useEffect, useState, } from "react";
import MessageError from "../../messages/messageError";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Avatar, Typography } from "@mui/material";
import Loading from "../../load/loading";
import { useNavigate } from "react-router-dom";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityIcon from '@mui/icons-material/DescriptionOutlined';
import { Button } from "@mui/material";
import api from "../../services/api";
// import FerramentaNavegacaoExportacao from "../ferramentaNavegacaoExportacao";
import HeaderSession from "../../utils/headerSession";
import DateHora from "../../utils/timeCalculate";
import FerramentaNavegacaoExportacao from "../../utils/ferramentaNavegacaoExportacao";


const useStyles = makeStyles((theme) => ({
    hover: { '&:hover': { backgroundColor: '#62aa8c' }, }
}))

export default function ControlAccesses() {
    const navigate = useNavigate()
    const classes = useStyles()
    const [controlAccesses, setControlAccesses] = useState('')
    const [messageError, setMessageError] = useState('')
    const [configLista, setConfigLista] = useState('')
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(100)
    const apiUrl = process.env.REACT_APP_API_URL;
    // const [filter, setFilter] = useState('')


    useEffect(() => {
        setControlAccesses('')
        api.get(`/control-accesses?page=${page}&perPage=${perPage}`)
            .then(response => {
                // console.log(response)
                setControlAccesses(response.data.controlAccesses.data);
                setConfigLista(response.data.controlAccesses);
            }).catch(err => {
                // console.log(err)
                if (err.message === "Network Error") {
                    setMessageError(err.message)
                }
            })
    }, [page, perPage])

    // const filtracontrolAccess = (valor) => {
    //     setcontrolAccesss(filter.filter((controlAccess) => (controlAccess?.nome?.toLowerCase().includes(valor.toLowerCase()))))
    // }

    return (
        <div>
            {/* <RegisterAccess page={'Lista de controlAccesss'} /> */}
            {messageError && <MessageError message={messageError} />}
            <HeaderSession title={'CONTROL ACCESSES'} />

            {controlAccesses ?
                <>
                    <Card variant="outlined" style={{ marginTop: 10 }} sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer id={'listagemcontrolAccess'} >
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" ><Typography noWrap> <strong>Detalhes</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>User</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>Pais</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>Cidade</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>Operadora</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>Pagina acessada</strong></Typography></TableCell>
                                        <TableCell ><Typography noWrap> <strong>Tipo de  dispositivo</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>IP</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>Lat</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>Lon</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>Data</strong></Typography></TableCell>
                                        <TableCell><Typography noWrap> <strong>Resumo</strong></Typography></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {controlAccesses.map((controlAccess) => (
                                        <TableRow key={controlAccess?.id} className={classes.hover}>

                                            <TableCell onClick={() => { navigate(`/admin/gest-controlAccess/${controlAccess?.id}`) }} align="center" >
                                                <Button color="primary" size="small" variant="outlined">
                                                    <VisibilityIcon fontSize="small" />
                                                </Button>
                                            </TableCell>

                                            <TableCell align="center" scope="row" style={{ display: 'flex' }}>
                                                <Avatar src={apiUrl + controlAccess?.user?.foto_perfil} />
                                                {controlAccess?.user ?
                                                    controlAccess?.user?.nome
                                                    :
                                                    'Anônimo'
                                                }
                                            </TableCell>

                                            <TableCell ><Typography noWrap>{controlAccess?.country}</Typography></TableCell>
                                            <TableCell >{controlAccess?.city} </TableCell>
                                            <TableCell > <Typography noWrap>{controlAccess?.operadora}</Typography></TableCell>
                                            <TableCell > <Typography noWrap>{controlAccess?.pagina}</Typography></TableCell>
                                            <TableCell > <Typography noWrap>{controlAccess?.dispositivo}</Typography></TableCell>
                                            <TableCell > <Typography noWrap>{controlAccess?.ip}</Typography></TableCell>
                                            <TableCell > <Typography noWrap>{controlAccess?.lat}</Typography></TableCell>
                                            <TableCell > <Typography noWrap>{controlAccess?.lon}</Typography></TableCell>
                                            <TableCell > <Typography noWrap><DateHora date={controlAccess?.created_at} /> </Typography></TableCell>
                                            <TableCell > <Typography noWrap>{controlAccess?.region}</Typography></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>

                    <FerramentaNavegacaoExportacao
                        configLista={configLista}
                        lista={controlAccesses}
                        page={page}
                        setPage={setPage}
                        setPerPage={setPerPage}
                    // setOrder={setOrder}
                    // exportarExcel={exportarExcel} 
                    />
                </>
                :
                <Loading text={'Buscando Access'} />
            }
        </div>
    )
}
