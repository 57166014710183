import CardAnuncio from './cardAnuncio';
import CardPublicacao from './cardPublicacao';
import CardPatrimonioDenunciado from './cardPatrimonioDenunciado';
import LoadingSkeleton from '../../load/loadingSkeleton';
import CardEncontrado from './cardEncontrado';

const CombineCards = ({ publicacoes, setHandleUpdate, perfil }) => {

    return (
        publicacoes
            ?
            publicacoes.map((publicacao) =>
            (
                <div key={publicacao.id} className="col alert alert-dismissible fade show p-0 my-1" role="alert">
                    {publicacao.tipo === 'publicacao' &&
                        <CardPublicacao setHandleUpdate={setHandleUpdate} publicacao={publicacao} perfil={perfil} />
                    }
                    {(publicacao.tipo === 'anuncio' && publicacao.categoria_id !== 1 && publicacao.status == false) &&
                        <CardAnuncio setHandleUpdate={setHandleUpdate} publicacao={publicacao} perfil={perfil} />
                    }
                    {(publicacao.tipo === 'denuncia' && publicacao.categoria_id === 1 && publicacao.status == false) &&
                        <CardPatrimonioDenunciado publicacao={publicacao} perfil={perfil} />
                    }
                    {((publicacao.tipo === 'anuncio' || publicacao.tipo === 'denuncia') && publicacao.status == true) &&
                        <CardEncontrado publicacao={publicacao} perfil={perfil} />
                    }
                </div>
            ))
            :
            <div >
                <LoadingSkeleton />
                <LoadingSkeleton />
            </div>
    );
}

export default CombineCards;
