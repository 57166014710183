import { useState } from 'react'
import PesquisarIcone from '../icones/pesquisar'
import { useNavigate } from 'react-router-dom'

export default function FormSearch() {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')

  return (
    <form onSubmit={(e) => { e.preventDefault(); navigate(`/search?p=${search}`) }} className="d-flex" >

      <input onChange={(e) => setSearch(e.target.value)} className="form-control me-2 rounded-pill bg-transparent" type="search" placeholder="Pesquisar" aria-label="Search" required autoFocus/>
      <button className="btn border-0 p-1 " type="submit">
        <PesquisarIcone />
      </button>
    </form>
  )
}
