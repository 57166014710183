import CarinhasorrisoIcone from '../../icones/carinhasorriso';
import HeaderCardPerfilUser from '../header/headerCard-perfilUser';
import HeaderCardInformacaoUser from '../header/headerCard-informacaoUser'
import TextoFormatadoFullview from '../../utils/textoFormatadoFullview';
import MidiaAnuncio from '../../utils/midiaAnuncio';
import CountEsperancasComentarios from '../../utils/countEsperancasComentarios';

export default function CardEncontrado({ publicacao, perfil }) {

    return (
        <div className='card'>
            <div className="card-body">
                {perfil ?
                    <HeaderCardPerfilUser publicacao={publicacao} />
                    :
                    <HeaderCardInformacaoUser publicacao={publicacao} />
                }
                <CarinhasorrisoIcone /> Encontrado
            </div>

            {/* {-- imagem ou video sobre o anuncio --} */}
            <MidiaAnuncio publicacao={publicacao}/>

            <div className="card-body">
                <TextoFormatadoFullview texto={publicacao?.descricao_final}/>
            </div>

            <CountEsperancasComentarios publicacao={publicacao} />
        </div>
    )
}
