import { format, parseISO } from 'date-fns';

export default function DateHora({ date }) {

    return (
        <span >
            {/* {date?.substring(0, 10).split('-').reverse().join('/')} */}
           
            {/* {format(parseISO(date), 'dd-MM-yyyy HH:mm:ss')} */}

            {format(parseISO(date), 'dd-MM-yyyy HH:mm')}
        </span>
    )
} 