import { useEffect, useState } from 'react'
import api from '../../services/api';
import LoadingShort from '../../load/loadingShort';
import { useNavigate } from 'react-router-dom';
import Loading from '../../load/loading';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    card: {
        // position: 'relative',
        // width: '26%',
        // minWidth: 180,
        // height: 110,
        margin: 10,
        // border: '1px solid #e0e0e0',
        borderRadius: 10,
        transition: '.5s',
        '&:hover': { background: '#e0e0e0', transform: 'scale(1.1)', },

        cursor: 'pointer', 
        boxShadow:' 0 1rem 1rem #62aa8c'

        // [theme.breakpoints.down('xs')]: {width: '100%', },
    },
}))

export default function Dashboard() {
    const classes = useStyles()
    const [resumo, setResumo] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
        api.get('/dashboard-resumo')
            .then(response => {
                // console.log(response)
                setResumo(response.data);
            }).catch(err => console.log(err))

    }, [])


    const Card = ({ total, titulo, url }) => {
        return (
            <div className={classes.card + ' card'} onClick={() => navigate(url)}>
                <div className="p-3">
                    <h4>
                        {resumo ? total : <LoadingShort />}
                        <br /> {titulo}
                    </h4>
                </div>
            </div>)
    }

    return (
        // boxShadow:' 0 2rem 3rem #62aa8c' 
        resumo ?
            <div className="mt-5 mx-auto container row row-cols-1 row-cols-md-4 g-1 text-center">
                <Card total={resumo?.usersAtivos.length} titulo={'USUARIOS ACTIVOS'} url={'/admin/list-users'} />
                <Card total={resumo?.users.length} titulo={'USUARIOS TOTAL'} url={'/admin/list-users'} />
                <Card total={resumo?.patrimonios.length} titulo={'PATRIMONIOS'} url={'/admin/control-patrimonios'} />
                <Card total={resumo?.anuncios.length} titulo={'ANUNCIOS'} url={'/admin/control-anuncios'} />
                <Card total={resumo?.publicacoes.length} titulo={'PUBLICAÇÕES'} url={'/admin/control-anuncios'} />
                <Card total={''} titulo={'NOTIFICAÇÕES'} url={'#'} />
                <Card total={''} titulo={'COMENTARIOS'} url={'#'} />
                <Card total={''} titulo={'ESPERANÇAS'} url={'#'} />
                <Card total={''} titulo={'GUARDADOS'} url={'#'} />
                <Card total={''} titulo={'TRANFERÊNCIAS'} url={'#'} />
                <Card total={''} titulo={'FEEDBACKS'} url={'#'} />
                <Card total={''} titulo={'AGRADECIMENTOS'} url={'#'} />
            </div>
            :
            <Loading />
    )
}
