// ICONES
import HomeIcone from '../icones/home'
import PatrimonioIcone from '../icones/patrimonio'
import ConfiguracoesIcone from '../icones/configuracoes'
import BackIcone from '../icones/back'
import ApoiosIcone from '../icones/apoios'
import ParceirosIcone from '../icones/parceiros'
import AgradecimentosIcone from '../icones/agradecimentos'
import DesenvolvedorIcone from '../icones/desenvolvedor'
import SabermaisIcone from '../icones/sabermais'
// ICONES

import EncontrarSomente from './encontrarSomente'
import ContactosTermosPrivacidade from './contactos-termosPrivacidade'
import BotaoCriarConta from './botaoCriarConta'
import Notificacoes from './notificacoes'
import ImagePerfil from './imageUser/imagePerfil'
import ImageNavbar from './imageUser/imageNavbar'
import { Link } from "react-router-dom";
import TerminarSessao from './terminarSessao'
import LoadingBackdrop from '../load/loadingBackdrop'
import { useEffect, useState } from 'react'
import MessageState from '../messages/messageState'
import Guest from '../services/auth/guest'
import Auth from '../services/auth/auth'
import UsuarioLogado from './usuarioLogado'
import { useLocation } from 'react-router-dom';
import GetNotificacoes from './cards/getNotificacoes'
import { useDispatch } from 'react-redux';
import api from '../services/api'
import PesquisarIcone from '../icones/pesquisar'
import UserLogado from './userLogado'


// ESTA VARIAVEL SERVE PARA DESTACAR O MENU OU LOCAL ACTUAL
const ondeEstou = 'border-bottom border-4 border-success'

export default function NavBar() {
    const dispatch = useDispatch();
    const [notificacoes, setNotificacoes] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const url = useLocation();

    // LIMPAR O ALET DO SISTEMA SEMPRE A PAGINA FOR RECARREGADA.
    useEffect(() => {
        dispatch({
            type: 'alert',
            payload: { alert: null },
        });
    }, [])

    const notificacoesViewed = () => {
        notificacoes.length > 0 &&
            api.post(`/notificacoes-viewed`, {

            }).then(response => {
                // console.log(response)

            }).catch(err => {
                // console.log(err)
            })
    }

    return (
        <>
            {/* LOADING DE EXECUCAO DE ALGUMA TAREFA */}
            <LoadingBackdrop open={isLoading} text={'A tua sessáo está sendo terminada'} />
            {/* MENSAGENS ALERTS PARA RETORNOS DE API */}
            <MessageState />

            {/* NAVBAR TELAS MAIORES */}
            <nav className="navbar navbar-light bg-white shadow fixed-top py-1 d-none d-md-block" >
                <div className="container">
                    <Link to={'/'} className="navbar-brand text-success position-relative">
                        <h2>
                            <strong>
                                <img src="/icones/iconesMeencontre/meencotreHorizontal.svg" alt="meencontre - Um lugar de esperanças" style={{ height: 40 }} />
                                {/* <span className="text-danger position-absolute top-0" style={{ fontSize: '1rem' }}>(Beta)</span> */}
                            </strong>
                        </h2>
                    </Link>

                    <Auth>
                        <div className='d-flex' style={{ alignItems: 'center', gap: 10 }}>
                            <div className="navbar navbar-expand  text-center p-1 ">
                                <ul className="navbar-nav align-items-center" >
                                    <li className={`nav-item mx-2 ${url.pathname === '/feed' && ondeEstou}`}>
                                        <Link to={'/feed'} className="nav-link py-0">
                                            <HomeIcone />
                                            {/* <br /> <small>Inicio</small> */}
                                        </Link>
                                    </li>

                                    <li className={`nav-item mx-2 ${url.pathname === '/registo-de-patrimonio' && ondeEstou}`}>
                                        <Link to={'/registo-de-patrimonio'} className="nav-link py-0">
                                            <PatrimonioIcone />
                                            {/* <br /><small>Registo</small> */}
                                        </Link>
                                    </li>

                                    <EncontrarSomente />

                                    <li className={`nav-item mx-2`}>
                                        <Link onClick={notificacoesViewed} href={"/#"} className="nav-link py-0" data-bs-toggle="dropdown" aria-expanded="false">
                                            <Notificacoes count={notificacoes.length} />
                                            {/* <br /><small>Notificações</small> */}
                                        </Link>

                                        <div className="nav-item dropdown-menu  dropdown-menu-end card-body mt-4" style={{ width: 400, maxHeight: '70vh', overflowY: 'auto' }}>
                                            <p>Notificações</p>
                                            <GetNotificacoes setCountNotificacoes={setNotificacoes} />
                                        </div>
                                    </li>
                                </ul>

                                <UserLogado/>

                                {/* <Link href={"/#"} className="text-decoration-none text-success " data-bs-toggle="dropdown" aria-expanded="false">
                                    <ImageNavbar />
                                </Link>
                                <ul className="nav-item dropdown-menu  dropdown-menu-end card-body mt-4">
                                    <Link to={'/perfil/minhas-publicacoes'} className="text-decoration-none">
                                        <li className="m-2">
                                            <ImagePerfil nome={true} />
                                            <hr className=" bg-success mb-0" />
                                            <p className=" text-success text-center" style={{ fontSize: '0.7rem' }}>Ver o meu perfil</p>
                                        </li>
                                    </Link>

                                    <li className="m-2">
                                        <Link to={'/configuracoes'} className="dropdown-item">
                                            <ConfiguracoesIcone />
                                            <span>Configurações</span>
                                        </Link>
                                    </li>

                                    <li className="m-2">
                                        <TerminarSessao setIsLoading={setIsLoading} />
                                        <hr className="bg-success" />
                                    </li>

                                    <li>
                                        <ContactosTermosPrivacidade />
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </Auth>
                    
                    {/* 
                    <Guest>
                        <div className="p-0 navbar d-none d-md-block">
                            <Link to={'/login'} className="btn btn-outline-success border-0 text-decoration-none ">
                                Entrar
                            </Link>
                        </div>
                    </Guest> */}
                </div>
            </nav>


            {/* NAVBAR MOBILE */}
            <nav className="navbar navbar-light bg-white fixed-top py-1 d-block d-md-none border-bottom" style={{ minWidth: 345 }}>

                <div className="container">
                    <a href={'/'} className="navbar-brand text-success position-relative">
                        <h2>
                            <strong>
                                <img src="/icones/iconesMeencontre/meencotreHorizontal.svg" alt="meencontre -Um lugar de esperanças" style={{ height: 40 }} />
                                {/* <span className="text-danger position-absolute top-0" style={{ fontSize: '1rem' }}>(Beta)</span> */}
                            </strong>
                        </h2>
                    </a>

                    {/*  ################### offcanvas #################################  */}
                    <Guest>
                        <button className="navbar-toggler   border-0 " type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </Guest>

                    <Auth>
                        <button className="navbar-toggler d-block  border-0" type="button" data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                            <ImageNavbar />
                        </button>
                    </Auth>

                    <div className="offcanvas offcanvas-end " tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">

                        <div className="position-relative mx-3 p-2 text-end" data-bs-dismiss="offcanvas" aria-label="Close">
                            <BackIcone />
                            <span> voltar</span>
                        </div>

                        <Guest>
                            <div className="container p-4 text-end d-block">
                                <br />
                                <BotaoCriarConta />
                            </div>
                        </Guest>

                        <div className="offcanvas-body">
                            <Auth>
                                {/* <Link to={'/perfil/minhas-publicacoes'} className="text-decoration-none" > */}
                                <a href={'/perfil/minhas-publicacoes'} className="text-decoration-none" >
                                    <div className="m-2 ">
                                        <ImagePerfil nome={true} />
                                        <hr className=" bg-success mb-0" />
                                    </div>
                                </a>
                                <UsuarioLogado />
                            </Auth>

                            <a href={'/apoios'} className="dropdown-item my-2">
                                <ApoiosIcone /> Apoios
                            </a>

                            <a href={'/parceiros'} className="dropdown-item my-2">
                                <ParceirosIcone />Parceiros
                            </a>

                            <a href={'/agradecimentos'} className="dropdown-item my-2">
                                <AgradecimentosIcone />Agradecimentos
                            </a>

                            <Link to={'/'} target="blank" className="disabled dropdown-item">
                                <DesenvolvedorIcone />Desenvolvedor
                            </Link>
                            <hr className=" bg-success" />

                            <Link to={'/sobre-meencontre'} className="dropdown-item">
                                <h6>
                                    <SabermaisIcone /> Saber mais
                                </h6>
                            </Link>

                            <Auth>
                                <Link to={'/configuracoes'} className="dropdown-item my-2">
                                    <ConfiguracoesIcone />
                                    <span> Configurações</span>
                                </Link>

                                {/*  ############################# TERMINAR SESSÃO#####################################  */}

                                <TerminarSessao setIsLoading={setIsLoading} />
                            </Auth>

                            <div className="w-100 mx-auto mt-5">
                                <ContactosTermosPrivacidade />
                            </div>
                        </div>
                    </div>
                    {/*  ########################-}} */}


                    {/*  Menu com icones  */}
                    <Auth>
                        <div className="container  navbar-expand text-center">
                            {/*  home  */}
                            <ul className="navbar-nav align-items-center py-0">
                                <li className={`nav-item mx-2 col  py-0 ${url.pathname === '/feed' && ondeEstou}`}>
                                    {/* <Link to={'/'} className="nav-link " aria-current="page"> */}
                                    <a href={'/feed'} className="nav-link " aria-current="page">
                                        <HomeIcone />
                                    </a>
                                </li>

                                {/*  registrar patrimonio  */}
                                <li className={`nav-item mx-2 col  py-0 ${url.pathname === '/registo-de-patrimonio' && ondeEstou}`}>
                                    <Link to={'/registo-de-patrimonio'} className="nav-link ">
                                        <PatrimonioIcone />
                                    </Link>
                                </li>

                                {/*  filtar  */}
                                <li className={`nav-item mx-2 col  py-0 ${url.pathname === '/search' && ondeEstou}`}>
                                    <Link to={'/search'} className="nav-link">
                                        {/* <FiltroIcone /> */}
                                        <PesquisarIcone />
                                    </Link>
                                </li>

                                {/*  encontrarSomente  */}
                                <EncontrarSomente />

                                {/*  notificações  */}
                                <li className={`nav-item mx-2 col  py-0 ${url.pathname === '/notificacoes' && ondeEstou}`}>
                                    <Link to={'/notificacoes'} className="nav-link ">
                                        <Notificacoes count={notificacoes.length} />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Auth>
                </div>
            </nav >
        </>

    )
}
