
import { useState } from "react";
import { Typography, Card, Divider } from "@material-ui/core";
import { Button, DialogActions, Grid, MenuItem, TextField } from "@mui/material";
import api from "../../services/api";
import LoadingBackdrop from "../../load/loadingBackdrop";
import MessageSuccess from "../../messages/messageSuccess";
import MessageError from "../../messages/messageError";
import DateHora from "../../utils/timeCalculate";

export default function InformacoesUser({ user }) {
    const [edit, setEdit] = useState()
    const [editDados, setEditDados] = useState(user)
    const [messageSuccess, setMessageSuccess] = useState('')
    const [messageError, setMessageError] = useState('')
    const [municipios, setMunicipios] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [open, setOpen] = useState(false);
    const [select, setSelect] = useState(false);
    const [activeButtonSalvar, setActiveButtonSalvar] = useState(false);
    const dadosPessoaisId = editDados?._id
    const provinciaId = editDados?.provincia?._id;
    const municipioId = editDados?.municipio?._id;
    // const user = useSelector(state => state.account.user);


    const HandleClick = () => { setSelect(true) }

    function HandleChange(e) {

        (e.target.name === 'provinciaId' || e.target.name === 'municipioId') ?
            setEditDados({ ...editDados, [e.target.name]: e.target.value, })
            :
            setEditDados({ ...editDados, [e.target.name]: e.target.value, provinciaId, municipioId })

        e.target.name === 'provinciaId' &&
            api.get(`/municipios-by-provincia/${e.target.value}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                }).catch(err => 'console.log(err)')
    }

    const submitEdit = async () => {

        setOpen(true)
        setMessageSuccess('')
        setMessageError('')
        user?.tipo === 'Admin' && setEditDados({ ...editDados, 'userId': user._id })

        await api.put('/dados-pessoais/edit', { ...editDados, dadosPessoaisId, 'conselhoId': '63de9655f1d8e62abe119174' })
            .then(res => {
                setEditDados(res.data.dadosPessoais)
                setEdit(false)
                setOpen(false)
                setMessageSuccess(res.data.msg)
                setSelect(false)

            }).catch(err => {
                setOpen(false)
                setMessageError(err.response.data.message)
                setSelect(false)
            })
    }

    const handleSubmitEdit = async e => {
        setMessageError('');
        if (!(await validate())) return
    };
    async function validate() {
        try {
            // await validacaoEditDadosPessoal(editDados)
            submitEdit()
        }
        catch (error) {
            setMessageError(error);
        }
    }

    return (
        <Card style={{ padding: 20, marginBottom: 15, minHeight: '48vh' }}>
            {messageSuccess && <MessageSuccess message={messageSuccess} />}
            {messageError && <MessageError message={messageError} />}
            <LoadingBackdrop open={open} />

            <div style={{ display: 'flex' }}>
                <Typography style={{lineHeight: 1.5 }} variant="h5">
                    Informações de User
                </Typography>
                {/* <ButtonEditar  setEdit={setEdit} edit={edit} /> */}
            </div>
            <Divider />

            {!edit &&
                <>
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>Status:</strong> {editDados?.status} </Typography>
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>Perfil:</strong> {editDados?.perfil} </Typography>
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>User_Id:</strong> {editDados?.id} </Typography>
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>Nome:</strong> {editDados?.nome} </Typography>
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>E-mail:</strong>{editDados?.email}  </Typography>
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>Telefone:</strong> {editDados?.contacto} </Typography>
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>Gênero:</strong> {editDados?.genero}   </Typography>
                    {/* <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>Data de Nascimento:</strong> <Date date={editDados?.dataNascimento} /> </Typography> */}
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>N_identidade:</strong> {editDados?.n_identidade} </Typography>
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>Verificacao de identidade:</strong> {editDados?.verificacao_identidade} </Typography>
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>Provincia:</strong> {editDados?.provincia} </Typography>
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>Município:</strong> {editDados?.municipio} </Typography>
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>Endereço:</strong> {editDados?.bairro} </Typography>
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>Provider:</strong> {editDados?.provider} </Typography>
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>provider_id:</strong> {editDados?.provider_id} </Typography>
                    <Typography style={{lineHeight: 1.5 }} variant="body1"><strong>Data de criação:</strong> <DateHora date={editDados?.created_at} /> </Typography>
                </>
            }

            {edit &&
                <Card style={{ padding: 20, marginBottom: 15, border: '1px solid orange' }}>
                    <Typography style={{lineHeight: 1.5, marginBottom: 20 }} variant="body1">Faça a edição apenas dos dados necessário </Typography>

                    <Grid container >
                        <Grid xs={12} md item m={1}>
                            <TextField
                                type="text"
                                label="Nome Completo"
                                fullWidth
                                size="small"
                                name="nome"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={editDados?.nome}
                            />
                        </Grid>
                    </Grid>
                    <Grid container >
                        <Grid xs={12} md item m={1}>
                            <TextField
                                style={{ marginTop: 10 }}
                                type="text"
                                label="Genero"
                                select
                                fullWidth
                                size="small"
                                name="genero"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={editDados?.genero || ''}
                            >
                                <MenuItem key={'M'} value={'M'} name="genero">
                                    Masculino
                                </MenuItem>
                                <MenuItem key={'F'} value={'F'} name="genero">
                                    Feminino
                                </MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid container >
                        <Grid xs={12} md item m={1}>

                            <TextField
                                style={{ marginTop: 10 }}
                                type="text"
                                label="Provincia"
                                select={select}
                                fullWidth
                                size="small"
                                name="provincia"
                                variant="outlined"
                                onClick={HandleClick}
                                onChange={HandleChange}
                                defaultValue={editDados?.provincia?.designacao || ''}
                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                            >
                                {provincias.map((provincia) => (
                                    <MenuItem key={provincia._id} value={provincia._id}>
                                        {provincia.designacao}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid xs={12} md item m={1}>
                            <TextField
                                style={{ marginTop: 10 }}
                                type="text"
                                label="Município"
                                select={select}
                                fullWidth
                                size="small"
                                name="municipio"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={editDados?.municipio?.designacao || ''}
                            >
                                {municipios?.map((municipio) => (
                                    <MenuItem key={municipio._id} value={municipio._id}>
                                        {municipio.designacao}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>

                    <Grid container >
                        <Grid xs={12} md item m={1}>
                            <TextField
                                style={{ marginTop: 10 }}
                                type="text"
                                label="Morada (Bairro, rua)"
                                fullWidth
                                size="small"
                                name="bairro"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={editDados?.bairro}
                            />
                        </Grid>

                        <Grid xs={12} md item m={1}>
                            <TextField
                                style={{ marginTop: 10 }}
                                type="tel"
                                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                label="Telefone 1"
                                fullWidth
                                size="small"
                                name="telefone1"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={editDados?.telefone1}
                            />
                        </Grid>
                    </Grid>

                    <Grid container >
                        <Grid xs={12} md item m={1}>
                            <TextField
                                style={{ marginTop: 10 }}
                                type="number"
                                label="Telefone 2"
                                fullWidth
                                size="small"
                                name="telefone2"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={editDados?.telefone2}
                            />
                        </Grid>

                        <Grid xs={12} md item m={1}>
                            <TextField
                                style={{ marginTop: 10 }}
                                type="text"
                                label="NIF"
                                fullWidth
                                size="small"
                                name="nif"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={editDados?.nif}
                            />
                        </Grid>
                    </Grid>

                    <Grid container >
                        <Grid xs={6} md item m={1}>
                            <TextField
                                style={{ marginTop: 10 }}
                                type="email"
                                label="Email"
                                fullWidth
                                size="small"
                                name="email"
                                variant="outlined"
                                onChange={HandleChange}
                                defaultValue={editDados?.email}
                            />
                        </Grid>
                        <Grid xs={6} md item m={1}></Grid>
                    </Grid>

                    <DialogActions >
                        <Button color="warning" onClick={() => { setEdit(false) }} size="small" style={{ marginTop: 20, marginRight: 10 }}>Cancelar</Button>
                        {/* <Button disabled={!activeButtonSalvar} variant="outlined" color="success" onClick={() => { submitEdit() }} size="small" style={{ marginTop: 20 }}>Salvar</Button> */}
                        <Button disabled={!activeButtonSalvar} variant="contained" color="success" onClick={() => { handleSubmitEdit() }} size="small" style={{ marginTop: 20 }}>Salvar</Button>
                    </DialogActions>
                </Card>
            }
        </Card>
    )
}