
export default function Publicidades() {
    return (
        <div className="text-center">
            <strong> Publicidades</strong>
            <br />

            <div>
                <a target="blank" href="https://www.cnp.ao" title="Visitar CNP">
                    <img className="w-100" src="/img/publicidades/cnp.jpg" alt="cnp" />
                </a>
            </div>
        </div>
    )
}
