import { useEffect, useState } from 'react'
import EliminarIcone from '../../icones/eliminar'
import LoadingShort from '../../load/loadingShort'
import api from '../../services/api'
import { useDispatch } from 'react-redux';
import Loading from '../../load/loading';

export default function CreateTiposPatrimonio() {
    const dispatch = useDispatch();
    const [patrimoniosTipo, setPatrimoniosTipo] = useState('')
    const [novoPatrimonioTipo, setNovoPatrimonioTipo] = useState([])
    const [loading, setLoading] = useState('')

    useEffect(() => {
        api.get('/patrimonio-tipos')
            .then(response => {
                // console.log(response)
                setPatrimoniosTipo(response.data.tiposPatrimonio);
            }).catch(err => '')

    }, [])

    const handleInputChange = (e) => {
        setNovoPatrimonioTipo({ ...novoPatrimonioTipo, [e.target.name]: e.target.value, });
    };

    const create = (e) => {
        e.preventDefault();
        setLoading('Salvar');

        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        api.post(`/create-patrimonio-tipo`, novoPatrimonioTipo)
            .then(response => {
                // console.log(response)
                setLoading(false);
                setPatrimoniosTipo(response.data.tiposPatrimonio)

                dispatch({
                    type: 'alert',
                    payload: { alert: response.data },
                });

            }).catch(err => {
                // console.log(err)
                setLoading(false);
            })
    }


    const delet = (id) => {
        setLoading(id);

        dispatch({
            type: 'alert',
            payload: { alert: null }
        });

        api.post(`/delete-tipo-patrimonio/${id}`)
            .then(response => {
                setLoading(false);
                setPatrimoniosTipo(response.data.tiposPatrimonio)

                dispatch({
                    type: 'alert',
                    payload: { alert: response.data },
                });

            }).catch(err => {
                setLoading(false);
            })
    }

    return (
        <div class="card card-body mb-4">
            <div class="row g-3 m-0" >
                <h4>ADD TIPO DE PATRIMONIO</h4>

                <div class="col-md-6">
                    <input onChange={handleInputChange} required type="text" class="form-control" placeholder="Novo tipo de patrimonio" name="patrimonio" />
                    <button
                        onClick={create}
                        class="btn btn-success mt-2">
                        {loading === 'Salvar'
                            ?
                            <LoadingShort />
                            :
                            'Salvar'
                        }
                    </button>
                </div>

                <div class="col-md-6">
                    <strong>Tipos de patrimonios cadastrados</strong>
                    {patrimoniosTipo ?
                        <ul>

                            {patrimoniosTipo.map((tipo) => (
                                <li key={tipo.id} >
                                    <button
                                        onClick={() => delet(tipo.id)}
                                        className="btn btn-outline-success border-0 p-0"
                                        title="apagar tipo de patrimonio">
                                        {loading === tipo.id
                                            ?
                                            <LoadingShort />
                                            :
                                            <EliminarIcone />
                                        }
                                    </button>
                                    {tipo.patrimonio}
                                </li>
                            ))}
                        </ul>
                        :
                        <Loading />
                    }
                </div>
            </div>
        </div>

    )
}
