import { useEffect, useState } from 'react'
import Auth from '../../services/auth/auth'
import DateHora from "../../utils/dateHora";
import { Avatar } from '@mui/material';
import AgradecimentosIcone from '../../icones/agradecimentos'
import { useSelector } from 'react-redux';
import api from '../../services/api';
import EnviarIcone from '../../icones/enviar';
import LoadingShort from '../../load/loadingShort';
import Loading from '../../load/loading';
import RegisterAccess from '../../services/registerAccess';

export default function Agradecimentos() {
    const user = useSelector(state => state.account.user);
    const [agradecimentos, setAgradecimentos] = useState('')
    const [novoAgradecimento, setNovoAgradecimento] = useState('')
    const [loading, setLoading] = useState(false)
    const [config, setConfig] = useState('')
    const [page, setPage] = useState(1)
    const [refresh, setRefresh] = useState('')

    const handleInputChange = (e) => {
        setNovoAgradecimento({ ...novoAgradecimento, [e.target.name]: e.target.value, });
    };

    useEffect(() => {
        api.get(`/get/agradecimentos/feedbacks`)
            .then(response => {
                // console.log(response)
                setAgradecimentos(response.data.feedbackAgradecimentos.data);
                setConfig(response.data.feedbackAgradecimentos);
                setPage(page + 1)
            }).catch(err => '')
    }, [refresh]);


    const encontrarMais = () => {
        setLoading(true)
        api.get(`/get/agradecimentos/feedbacks?page=${page}`)
            .then(response => {
                // console.log(response)
                setAgradecimentos((prevItems) => [...prevItems, ...response.data.feedbackAgradecimentos.data]);
                setConfig(response.data.feedbackAgradecimentos);
                setPage(page + 1)
                setLoading(false)
            }).catch(err => setLoading(false))
    }

    const createAgradecimento = (e) => {
        e.preventDefault();
        setLoading(true);

        api.post(`/create/agradecimento/feedback`, {
            ...novoAgradecimento,
            tipo: 'agradecimento'

        }).then(response => {
            setRefresh(response.data)
            // console.log(response)

            setLoading(false);
            // setHandlePublicacao(response.data.publicacao)

        }).catch(err => {
            // console.log(err)
            setLoading(false);
        })
    }

    return (
        <div>
        <RegisterAccess page={'pagina agradecimentos'} />
            <div className="card shadow mb-4">
                <div className="card-body">
                    <h5 className="card-title text-center "><AgradecimentosIcone /> Agradecimentos</h5>
                    <p className=" card-text text-justify" >
                        Nessa página são publicados todos os emails e menagens que o meencontre recebe de usuários
                        agradecendo por ter encontrado a pessoa ou algum bem que procurava atraveis do “meencontre“.
                        São depoimentos que nos deixa bastante feliz, pois é uma prova que nosso trabalho
                        está sendo realizado com sucesso.
                    </p>
                </div>

                <Auth>
                    <div className="card-body pt-0">
                        <form onSubmit={createAgradecimento} className=" border-0 bg-transparent d-flex">
                            <textarea onChange={handleInputChange} required className="form-control mb-1 bg-transparent" name="descricao" placeholder={`Dopes fazer um agradecimento, ${user?.nome}`} />
                            <button disabled={!novoAgradecimento?.descricao} type="submit" className="btn btn-outline-success btn-sm border-0 m-1" style={{ height: '30px' }}>
                                {loading ?
                                    <LoadingShort />
                                    :
                                    // 'Enviar'
                                    <EnviarIcone />
                                }
                            </button>
                        </form>
                    </div>
                </Auth>

            </div>

            {agradecimentos ?
                <>
                    {agradecimentos.map((agradecimento) =>
                        <div key={agradecimento.id} className="card card-body mb-2">
                            {/* {{-- imagem --}} */}
                            <div className="d-inline-flex " style={{ alignItems: 'center' }}>
                                <picture className="me-2" >
                                    {agradecimento?.user?.foto_perfil ?
                                        <Avatar src={`data:image/jpeg;base64,${agradecimento?.user?.foto_perfil}`} alt="img" style={{ border: 'solid rgb(2, 71, 0) 1px' }} />
                                        :
                                        <img src="/img/utilizador.svg" alt="img" style={{ width: '40px' }} title="Carrega já imagem de perfil" />
                                    }
                                </picture>

                                <div>
                                    <p className="lh-sm m-0 text-dark">
                                        <strong>{agradecimento?.user?.nome}</strong>
                                    </p>

                                    <small style={{ fontSize: 10 }}>
                                        <DateHora date={agradecimento.updated_at} />
                                    </small>
                                </div>
                            </div>
                            {/* {{-- fim imagem --}} */}

                            {agradecimento?.descricao.map((descricao, index) => (
                                <p key={index} className="card-text text-break lh-sm" style={{textAlign: 'justify'}}>
                                    {descricao}
                                </p>
                            ))}
                        </div>
                    )}

                    {config?.next_page_url &&
                        <div align='center'>
                            <button onClick={encontrarMais} className='btn btn-success m-2'>
                                {!loading ? 'Encontrar mais' : <LoadingShort />}
                            </button>
                        </div>
                    }
                </>
                :
                <Loading />
            }
        </div>
    )
}
