
import { Card, makeStyles } from "@material-ui/core";
import { useEffect, useState, } from "react";
import MessageError from "../../messages/messageError";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Avatar, InputBase, Typography } from "@mui/material";
import Loading from "../../load/loading";
import { useNavigate } from "react-router-dom";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityIcon from '@mui/icons-material/DescriptionOutlined';
import { Button } from "@mui/material";
import api from "../../services/api";
import Perfil from "../../utils/perfil";
// import FerramentaNavegacaoExportacao from "../ferramentaNavegacaoExportacao";
import HeaderSession from "../../utils/headerSession";
import DateHora from "../../utils/timeCalculate";


const useStyles = makeStyles((theme) => ({
    hover: { '&:hover': { backgroundColor: '#62aa8c' }, }
}))

export default function ListUsers() {
    const navigate = useNavigate()
    const classes = useStyles()
    const [users, setUsers] = useState('')
    const [messageError, setMessageError] = useState('')
    // const [configLista, setConfigLista] = useState('')
    const [page, setPage] = useState('')
    const [filter, setFilter] = useState('')
    const apiUrl = process.env.REACT_APP_API_URL;


    useEffect(() => {
        api.get(`/users?page=${page}`)
            .then(response => {
                // console.log(response)
                setUsers(response.data.users);
                setFilter(response.data.users);
                // setConfigLista(response.data);
            }).catch(err => {
                // console.log(err)
                if (err.message === "Network Error") {
                    setMessageError(err.message)
                }
            })
    }, [page])

    const filtrauser = (valor) => {
        setUsers(filter.filter((user) => (user?.nome?.toLowerCase().includes(valor.toLowerCase()))))
    }

    return (
        <div>
            {/* <RegisterAccess page={'Lista de users'} /> */}
            {messageError && <MessageError message={messageError} />}
            <HeaderSession title={'USERS'} />

            <div style={{ border: '1px solid #ddd', borderRadius: 5 }}>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    autoFocus
                    placeholder="Filtra pelo nome"
                    inputProps={{ 'aria-label': 'Filtrar lista' }}
                    onChange={(e) => { filtrauser(e.target.value) }}
                />
            </div>

            {users ?
                <>
                    <Card variant="outlined" style={{ marginTop: 10 }} sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer id={'listagemUser'} >
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>

                                        <TableCell align="center" ><Typography noWrap> <strong>Detalhes</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>Foto</strong></Typography></TableCell>

                                        <TableCell align="center" ><Typography noWrap> <strong>Nome</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>Status</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>Perfil</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>Data criação</strong></Typography></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {users.map((user) => (
                                        <TableRow key={user?.id} className={classes.hover}>

                                            <TableCell onClick={() => { navigate(`/admin/gest-user/${user?.id}`) }} align="center" >
                                                <Button color="primary" size="small" variant="outlined">
                                                    <VisibilityIcon fontSize="small" />
                                                </Button>
                                            </TableCell>
                                            <TableCell align="center" component="th" scope="row">
                                                <Avatar src={apiUrl + user?.foto_perfil} />
                                            </TableCell>

                                            <TableCell ><Typography noWrap>{user?.nome}</Typography></TableCell>
                                            <TableCell align="center" >{user?.status} </TableCell>
                                            <TableCell align="center" > <Typography noWrap>{user?.perfil}</Typography></TableCell>
                                            <TableCell > <Typography noWrap><DateHora date={user?.created_at} /> </Typography></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Card>

                    {/* <FerramentaNavegacaoExportacao
                        FerramentaNavegacaoExportacao={true}
                        exportToPDF={exportToPDF}
                        activeImpressao={activeImpressao}
                        print={print}
                        configLista={configLista}
                        lista={users}
                        page={page}
                        setPage={setPage}
                    /> */}
                </>
                :
                <Loading text={'Buscando users'} />
            }
        </div>
    )
}
