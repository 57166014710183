
import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Container } from "@material-ui/core";
import HeaderSession from "../../../utils/headerSession";
import NavLateral from "./navLateral";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";
import { useDispatch } from "react-redux";
import Loading from "../../../load/loading";

const useStyles = makeStyles((theme) => ({
    grid1: {
        width: 300,
        marginBottom: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '110vh',
        position: 'sticky',
        top: 70,
    },
    grid2: {
        flexGrow: 1,
        marginLeft: 15,
        // minHeight: '100vh',
        width: 500,
        zIndex: 1,
        // position: 'relative',
    }

}))

export default function TemplateGestUser({ element: component, ...rest }) {
    const classes = useStyles()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    // const { tipo } = useParams()
    const [user, setUser] = useState('')


    useEffect(() => {
        api.get(`/user/${id}`)
            .then(response => {

                // console.log(response)
                dispatch({
                    type: 'alert',
                    payload: { alert: null },
                });
                setUser(response.data.user)

            }).catch(err => {
                // console.log(err)

                dispatch({
                    type: 'alert',
                    payload: err.response.data
                })

                if (err.response.status === 422) {
                    window.setTimeout(() => {
                        navigate(-1)
                    }, 1000);
                }
            })
    }, [id])

    return (
        <Container>
            <HeaderSession title={'GESTÃO DE USER'} />

            {!!user ?
                <Grid container>
                    <Grid item className={classes.grid1}  >

                        <NavLateral user={user} />

                    </Grid>

                    <Grid xs item className={classes.grid2}>

                        {React.cloneElement(component, { user: user })}

                    </Grid>

                </Grid>
                :
                <Loading />
            }
        </Container >
    )
}