import { useEffect } from 'react';
import { Helmet } from 'react-helmet';


const SEO = ({ title, description, url, image }) => {
  // console.log(description)

  useEffect(() => {
    // Acessar a tag <meta> pelo nome
    const metaDescription = document.querySelector('meta[name="description"]');
    // Verificar se a tag <meta> existe e alterar o conteúdo
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    }

    const metaImage = document.querySelector('meta[property="og:image"]');
    if (metaImage) {
      metaImage.setAttribute('content', image);
    }


  }, [description, image]);



  return (
    <Helmet>
      <title>{'meencontre - ' + title}</title>
      <meta name="description" content={description} />

      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

export default SEO;
