// import { useState } from 'react'

export default function ConfigNotificacoes() {
    // const [config, setConfig] = useState('')

    return (
        <div className="container ">

            <h5>Configuraçao de notificação por email</h5>
            <hr className=" d-none d-md-block" />
            <form className="py-3"  method="POST">

                <div className="form-check">
                    <input disabled className="form-check-input" type="checkbox" id="1" name="comentarioAnuncio" />
                    <p> Envie-me um e-mail quando comentarem em meus anuncios</p>
                </div>

                <div className="form-check">
                    <input disabled className="form-check-input" type="checkbox" id="2" name="esperancaAnuncio" />
                    <p>Envie-me um e-mail quando enviarem esperança em meus anuncios</p>
                </div>

                {/* <div className="form-check">
            <input className="form-check-input" type="checkbox" id="3">
            <label className="form-check-label" for="3">
                <p>Envie-me um e-mail quando partilharem meu anuncio</p>
            </label>
        </div>  */}

                <div className="form-check">
                    <input disabled className="form-check-input" type="checkbox" id="3" name="comentarioPublicacao" />
                    <p>Envie-me um e-mail quando comentarem as minhas publicações</p>
                </div>

                <div className="form-check">
                    <input disabled className="form-check-input" type="checkbox" id="4" name="esperancaDenuncia" />
                    <p>Envie-me um e-mail quando enviarem esperança em minhas denuncias</p>
                </div>

                <div className="form-check">
                    <input disabled className="form-check-input" type="checkbox" id="5" name="comentarioDenuncia" />
                    <p> Envie-me um e-mail quando comentarem minhas denuncias</p>
                </div>

                <div className="form-check ">
                    <input disabled className="form-check-input" type="checkbox" id="6" name="denunciadoPesquisado" />
                    <p>Envie-me um e-mail quando meu patrimônio denunciado for pesquisado</p>
                </div>

                <button type="submit" className="disabled btn btn-outline-success border-0">Salvar alterações</button>
            </form>
        </div>

    )
}
