import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@mui/system';
import ServicosMenuBastionario from '../../utils/ServicesMenuControlGeral';
import { useSelector } from 'react-redux';
import MessageError from '../../messages/messageError';
import api from '../../services/api';
import HeaderSession from '../../utils/headerSession';


const useStyles = makeStyles((theme) => ({

    containerCardesService: {
        // height: '60vh',
        // marginTop: 30,
        padding: '2rem',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignContent: 'center',
        gap: '1rem',
        padding: 0,
    }
}))

export default function MenuControlGeral() {
    const classes = useStyles();
    const user = useSelector(state => state.account.user);
    const [messageError, setMessageError] = useState('')
    const [estagiarios, setEstagiarios] = useState([])
    const [advogados, setAdvogados] = useState([])
    const [membros, setMembros] = useState([])
    const [patronos, setPatronos] = useState([])
    // const [reload, setReload] = useState('')


    useEffect(() => {

        Promise.all([
            api.get('/membros?perfil=Estagiário(a)'),
            api.get('/membros?perfil=Advogado(a)'),
            api.get('/membros'),
            api.get('/patronos'),
            // api.get('/estagiarios/approveds'),

        ]).then(response => {
            setEstagiarios(response[0].data.totalItems);
            setAdvogados(response[1].data.totalItems);
            setMembros(response[2].data.totalItems);
            setPatronos(response[3].data.totalItems);
            // setRecemInscritos(response[4].data.totalItems);

            // console.log(response[4].data);

            // window.setTimeout(() => {
            //     setReload(response)
            //     // console.log('reload')
            // }, 5000);

        }).catch(error => {
            // if (error.response.status === 422) {
            //     setMessageError(error.response.data.message)
            // }
            // console.log(error)
        })
    }, [])


    return (
        <>
            {/* <RegisterAccess page={'menu bastionario'} /> */}
            <HeaderSession title={'CONTROL DE PROVINCIAS'} />
            {messageError && <MessageError message={messageError} />}

            <Box className={classes.containerCardesService}>
                <ServicosMenuBastionario
                    provincia={'luanda'}
                    icone={'/icones/mapaProvinciasAngola/luanda.svg'}
                    estagiarios={estagiarios}
                    advogados={advogados}
                    membrosGeral={membros}
                    patronos={patronos}
                />

                <ServicosMenuBastionario
                    provincia={'bengo'}
                    icone={'/icones/mapaProvinciasAngola/bengo.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'benguela'}
                    icone={'/icones/mapaProvinciasAngola/benguela.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'bie'}
                    icone={'/icones/mapaProvinciasAngola/bie.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'cabinda'}
                    icone={'/icones/mapaProvinciasAngola/cabinda.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'cuando-cubango'}
                    icone={'/icones/mapaProvinciasAngola/cuando-cubango.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'cuanza-norte'}
                    icone={'/icones/mapaProvinciasAngola/cuanza-norte.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'cuanza-sul'}
                    icone={'/icones/mapaProvinciasAngola/cuanza-sul.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'cunene'}
                    icone={'/icones/mapaProvinciasAngola/cunene.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'huambo'}
                    icone={'/icones/mapaProvinciasAngola/huambo.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'huila'}
                    icone={'/icones/mapaProvinciasAngola/huila.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'lunda-norte'}
                    icone={'/icones/mapaProvinciasAngola/lunda-norte.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'lunda-sul'}
                    icone={'/icones/mapaProvinciasAngola/lunda-sul.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'namibe'}
                    icone={'/icones/mapaProvinciasAngola/namibe.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'malange'}
                    icone={'/icones/mapaProvinciasAngola/malange.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'moxico'}
                    icone={'/icones/mapaProvinciasAngola/moxico.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'uige'}
                    icone={'/icones/mapaProvinciasAngola/uige.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />

                <ServicosMenuBastionario
                    provincia={'zaire'}
                    icone={'/icones/mapaProvinciasAngola/zaire.svg'}
                    estagiarios={0}
                    advogados={0}
                    membrosGeral={0}
                    patronos={0}
                />
            </Box>
        </>
    );
}





