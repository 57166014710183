import Definicoes from '../../components/definicoesConceitos'
import RegisterAccess from '../../services/registerAccess'

export default function TermosCondicoesServico() {
    return (
        <div className="mt-5 pt-4 bg-white" style={{ minHeight: '100vh', textAlign: 'justify' }}>
        <RegisterAccess page={'pagina de termos e condicoes'} />

            <header className=" border-bottom  fixed-top bg-white py-3">
                <div className="navbar navbar-light bg-white container ">
                    <div><a href="/" className="navbar-brand text-success" title="Voltar">
                        <img src="/icones/iconesMeencontre/meencotreHorizontal.svg" alt="meencontre -Um lugar de esperanças" style={{ height: 40 }} />
                    </a>
                    </div>

                    <ul className="nav nav-pills my-2" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="border-0 btn btn-outline-success mx-1  active " id="pills-home-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                aria-selected="true">Termos e condições
                            </button>
                        </li>

                        <li className="nav-item" role="presentation">
                            <button className="border-0 btn btn-outline-success mx-1 " id="pills-profile-tab" data-bs-toggle="pill"
                                data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                                aria-selected="false">Privacidade
                            </button>
                        </li>
                    </ul>
                </div>
            </header>

            <div className="container tab-content my-2" id="pills-tabContent" style={{ textAlign: 'justif' }}>
                <div className="pt-4 mx-auto col  col-lg-7 tab-pane fade show active" id="pills-home" role="tabpanel"
                    aria-labelledby="pills-home-tab">
                    <h4>Termos de Serviço</h4>
                    <hr />
                    <h6>Termos e Condições Gerais de Utilização do Website e Aplicações  meencontre.</h6>
                   
                    <br />
                    <p><b>Informação Geral</b></p>
                    <p>O endereço eletrônico www.meencontre.com e aplicativos  meencontre, são pertencentes à <b> Jonilson Miguel </b>
                        que é responsável pelo desenvolvimento, operação e manutenção dos mesmos.
                    </p>

                    <p>Os presentes Termos e Condições definem as regras de utilização do Site e/ou das Aplicações. Cada vez que
                        utilizar o site e/ou Aplicações verifique os Termos e Condições e assegure-se que os compreende.
                    </p>

                    <p>Ao acessar e utilizar nosso site e/ou Aplicações, você, usuário pessoa física ou jurídica, se obriga,
                        declara compreender e aceita as condições aqui estabelecidas.
                    </p>

                    <p>Ao utilizar os Serviços do site e/ou Aplicações, você declara e garante que:</p>
                    <ul>
                        <li>Todas as informações de cadastro que você enviar são verdadeiras e precisas;</li>
                        <li>Você manterá a precisão dessas informações e será responsável por elas;</li>
                        <li>Você tratará com respeito e dignidade outros usuarios do site e/ou Aplicações e os seus conteudos;
                        </li>
                        <li>Você se compromete em eliminar ou arquivar "terminar procura" seus anuncio quando o caso já for solucionado ou nos
                            comunicar pelas nossas vias de contactos disponiveis;</li>
                        <li>Que os anuncios ou as informações que você publicar não violam nenhuma lei ou regulamento aplicável.
                            Seu perfil pode ser excluído sem aviso prévio, se acreditarmos que você violou as regras.</li>
                        <li>Você aceita responsabilidade única por todas as suas atividades ao usar o site e/ou Aplicações,
                            incluindo seu comportamento e todo e qualquer conteúdo que você possa enviar, publicar ou
                            compartilhar.</li>
                        <li>Você não vai usar o site e/ou Aplicações  meencontre para qualquer finalidade ilegal ou não
                            autorizada.</li>
                        <li>Você será responsável por assegurar que você não viola as leis de sua jurisdição, incluindo, sem
                            limitação, as leis de direitos autorais.</li>
                        <li>Conhece e aceita os termos e condições em que a  meencontre presta o serviço, as regras de utilização
                            do site e da aplicação e que tais termos e condições podem ser alterados a qualquer momento. </li>
                        <li>Conservamos todas informações armazenadas na sua conta.</li>
                    </ul>

                    <hr />
                    <Definicoes />

                    <h5>Uso do nosso site e/ou Aplicações</h5>

                    <p>
                        <strong>Quem pode usar.</strong> É proibido o uso da site e/ou Aplicações por qualquer pessoa com menos
                        de 13 anos. Você representa que é ao menos maior de idade na jurisdição onde reside ou, se não for, que
                        seus pais ou a pessoa que tem a guarda legal precisam consentir com estes Termos de Serviço e afirmar
                        que aceitam este Contrato em seu nome e exerçam a responsabilidade por seu uso. Se você está aceitando
                        estes Termos de Serviço em nome de outra pessoa ou entidade, confirma que possui a autoridade legal para
                        vincular essa pessoa ou entidade a este Contrato.
                    </p>

                    <p>
                        Usuários não cadastrados são permitidos acessar o site e/ou Aplicações <strong> meencontre</strong> mas
                        com restrição a algumas funcionalidades podendo apenas ver conteúdos da pagina inicial e saber mais sobre
                        o projecto  meencontre.
                    </p>

                    <p>
                        Para utilizar todas as funcionalidades e recursos do site e/ou Aplicações <strong> meencontre</strong>,
                        você precisará realizar um cadastro, nos termos da nossa Política de Privacidade. Nessa oportunidade,
                        solicitamos o fornecimento de alguns dados pessoais seus, tais como nome, endereço, idade e endereço de
                        e-mail, dentre outros.
                    </p>

                    <p>
                        O usuário aceita e declara compreender que o acesso a determinadas áreas da plataforma será restrito.
                        Para acessá-las, o usuário deverá criar conta ou fazer login com o endreço de email e senha caso já
                        tenha. A senha é individual, sigilosa e intransferível, sendo o usuário o único responsável pela guarda
                        da mesma. O Usuário assume toda e qualquer responsabilidade pelo mau uso ou pela utilização da senha por
                        terceiros.
                    </p>

                    <p>
                        Ao fazer um anuncio ou publicação de qualquer categoria você se torna responsável pelas informações
                        prestadas e autoriza o uso dessa informação no site e/ou Aplicações <strong> meencontre</strong> e
                        em outros meios de comunicação incluindo redes sociais do meencontre.
                    </p>

                    <p>
                        Mesmo após exclusão de sua conta de usuario ou algum conteudo publicado ou anunciado por voçê,
                        manteremos, contudo, os dados guardados e não identificáveis, para fins estatísticos.
                    </p>

                    <p>
                        Enquanto estivermos em posse de informações ou dados seus, poderemos fornecê-los às autoridades
                        competentes para colaborar em investigações ou procedimentos judiciais, ainda que preparatórios ou
                        cautelares, desde que permitido por lei.
                    </p>

                    <p>
                        <strong>Política de Privacidade.</strong> Nossas práticas de privacidade estão definidas em nossa
                        Política de Privacidade. Pelo uso do site e/ou Aplicações  meencontre você concorda em aceitar nossa
                        Política de Privacidade, a despeito de se você é ou não um usuário registado.
                    </p>

                    <p>
                        <strong>Cancelamento.</strong> Você pode encerrar sua conta a qualquer momento ao ir para as
                        configurações da conta e desativar sua conta. Nós podemos encerrar ou suspender sua conta do site e/ou
                        Aplicações  meencontre se você violar qualquer Política ou por qualquer outro motivo.
                    </p>

                    <p>
                        <strong>Mudanças no site e/ou Aplicações  meencontre.</strong> Nós sempre estamos tentando aprimorar sua
                        experiência no site e/ou Aplicações  meencontre. Pode ser que precisemos adicionar ou mudar ferramentas e
                        recursos, e pode ser que façamos isso sem avisá-lo.
                    </p>

                    <p>
                        <strong>Feedback.</strong> Seu feedback e suas sugestões sobre como podemos melhorar o site e/ou
                        Aplicações  meencontre sempre são bem-vindos. Sinta-se a vontade para enviar para nós o seu feedback. Ao
                        enviar um feedback, você concorda em conceder a meencontre o direito de, a nosso critério, usar, revelar e
                        explorar o feedback, por completo ou em parte, livremente e sem compensação.
                    </p>

                    <hr />
                    <h5>Conduta</h5>
                    <p>
                        Ao utilizar os site e/ou Aplicações <strong> meencontre</strong>, você compreende que não deve:
                    </p>
                    <ul>
                        <li>Disseminar conteúdo ilegal, ofensivo, violento ou que viole a privacidade e a intimidade de
                            terceiros.
                        </li>
                        <li>Violar propriedade intelectual de terceiros.</li>
                        <li>Disseminar vírus ou código de computador malicioso, arquivos ou programas desenvolvidos para coletar
                            dados não autorizados, interromper, destruir ou limitar a funcionalidade da plataforma, de qualquer
                            software ou hardware de computador ou equipamentos de telecomunicações em geral "malware ou
                            spyware".
                        </li>
                        <li>Disfarçar ou ocultar o número de IP "internet protocol" que identifica sua conexão à internet</li>

                        <li>Usar qualquer dos elementos do site e/ou Aplicações <strong> meencontre</strong> para fins
                            comerciais, publicitários ou em qualquer outro que não represente a nossa finalidade principal
                        </li>
                    </ul>

                    <p>
                        Poderemos impedir que você utilize a plataforma ou qualquer de seus recursos caso, no nosso
                        entendimento, você viole quaisquer das condições destes Termos.
                    </p>

                    <p>
                        O encerramento, definitivo e/ou temporário, das atividades do site e/ou Aplicações
                        <strong> meencontre</strong> não resultará em qualquer obrigação, seja de que natureza for, para com o
                        USUÁRIO, inclusive no que diz respeito a comunicação prévia de tal fato, compensação, pagamento e/ou
                        indenização, a qualquer tempo e título.
                    </p>

                    <hr />
                    <h5>Licença dos seus conteúdos e propriedade intelectual</h5>
                    <p>
                        Ao criar qualquer conteúdo ou informação sobre forma de anuncio ou publicação no site e/ou Aplicações
                        <strong> meencontre</strong>, incluindo textos, fotografias, ilustrações,vídeos, etc você declara
                        autorizar, de forma gratuita, não exclusiva, perpétua, global e livre de remuneração, compensação,
                        retenção ou indenização, o uso do conteúdo pela site e/ou Aplicações <strong> meencontre</strong>, por
                        qualquer modalidade e suporte, para publicação, reprodução, transmissão, retransmissão, distribuição,
                        comunicação ao público, edição, adaptação e outras transformações, tradução para qualquer idioma,
                        inclusão em quaisquer outras obras, representação, execução, uso por radiodifusão e outros meios de
                        comunicação, mediante o emprego de qualquer tecnologia, exposição, inclusão em base de dados e quaisquer
                        outras modalidades de utilização existentes, autorizando também a criação de obras derivadas e o
                        sublicenciamento do material para uso em outras plataformas de comunicação, sem limite de tempo,
                        território ou número de utilizações.
                    </p>

                    <p>
                        Qualquer eventual omissão não implicará limitação de uso da informação por nós.
                    </p>

                    <p>
                        Caso você não concorde com a autorização acima, ou não tenha os direitos necessários para emitir essa
                        autorização com relação a um conteúdo, você não deve criar qualquer conteúdo ou informação sobre forma
                        de anuncio ou publicação na site e/ou Aplicações <strong> meencontre</strong>.
                    </p>

                    <p>
                        O <strong> meencontre</strong> não assume qualquer responsabilidade por estes atos e respectivo conteúdo
                        publicados no site e/ou aplicações, nem responde por quaisquer danos ou prejuízos resultantes dessas
                        ações efectuado pelo usuario.
                    </p>

                    <p>
                        São da responsabilidade do usuario todas as despesas, judiciais ou extrajudiciais, incluindo honorários
                        em virtude do incumprimento pelo usuario dos presentes Termos e Condições.
                    </p>

                    <hr />
                    <h5>Responsabilidade</h5>
                    <p>Os anúncios, publicações e registo de patrimônio são sempre da responsabilidade do usuario e não do
                        meencontre.
                    </p>
                </div>

                {/* {{-- Privacidade --}} */}
                <div className="pt-4 mx-auto col  col-lg-7 tab-pane fade" id="pills-profile" role="tabpanel"
                    aria-labelledby="pills-profile-tab">
                    <h4>Política de Privacidade</h4>
                    <hr />

                    <p>
                        O  meencontre reconhece que a sua privacidade é muito importante, e nós levamos isso a sério. Esta
                        Política de Privacidade descreve as nossas políticas e procedimentos ao coletar, usar, revelar e
                        compartilhar as suas informações pessoais quando você usa o site e aplicações  meencontre.
                    </p>

                    <p>
                        <strong>Informações de conta e de perfil:</strong> Quando você cria uma conta e um perfil no site e
                        aplicações  meencontre, nós coletamos o seu nome, informação de contato e outras informações que você
                        fornece, como preferencias de conteudos etc.. além de seu nome e foto, qualquer outra informação que você
                        escolher adicionar ao seu perfil não estarão disponíveis para serem vistos pelo público nem por
                        outros usuários do website e aplicações porque os perfis de usario são privados  a não que voçê de essa permissão.
                    </p>

                    <p>
                        Uma vez que você criar um perfil, apenas você tera ver e ter acesso e ver informações sobre as suas
                        atividades, como os anuncios e publicações, os conteudos de outros
                        usuarios que voçê guardou, os seus patrimônios registados e denunciados
                    </p>

                    <p>
                        <strong>Seu Conteúdo:</strong> Nós coletamos e armazenamos o conteúdo que você publica incluindo
                        seus anuncios, fotos, comentários, esperanças e enviadas e esperanças retiradas. Data e horário e todos os
                        comentários associados a ele ficam publicamente disponíveis juntamente com o seu nome.
                    </p>

                    <p>
                        <strong>Comunicações:</strong> Quando você se comunica conosco (por e-mail, por meio de feedback no site
                        ou de outra forma), guadamos um registo da sua comunicação.
                    </p>

                    <hr />
                    <h4>Como usamos a sua informação</h4>
                    <p>
                        Apenas usamos e podemos ceder a terceiros quando é necessario para ajudar no processo de procura de pessoas
                        ou bens dentro do site e aplicações  meencontre.
                    </p>

                </div>
            </div>
        </div>
    )
}
