import { useEffect, useState } from 'react'
import HeaderCardInformacaoUser from '../header/headerCard-informacaoUser'
import CreateEsperanca from '../createEsperanaca'
import CreateComentario from '../createComentario'
import { useSelector } from 'react-redux';
import api from '../../services/api'
import NavBar from '../navbar'
import { useParams, useNavigate } from 'react-router-dom'
import LocationIcone from '../../icones/location'
import DateHora from '../../utils/timeCalculate'
import ComentariosAutoLoader from '../comentariosAutoLoader'
import { Grid } from "@material-ui/core";
import LoadingBackdrop from '../../load/loadingBackdrop';
import TextoFormatadoFullview from '../../utils/textoFormatadoFullview';
import MidiaAnuncio from '../../utils/midiaAnuncio';
import CountEsperancasComentarios from '../../utils/countEsperancasComentarios';
import RegisterAccess from '../../services/registerAccess';
// import ViewPublicacaoReducer from '../../reducers/viewPublicacaoReducer';

export default function FullViewCard() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [publicacao, setPublicacao] = useState(useSelector(state => state.viewPublicacao.data))
    const [handlePublicacao, setHandlePublicacao] = useState('')
    // const alert = useSelector(state => state.alert.data?.message); 
    // console.log(publicacao)

    useEffect(() => {
        // !publicacao &&
        api.get(`/anuncio/${id}`)
            .then(response => {
                setPublicacao(response.data.anuncio)
                // console.log(response)
            }).catch(err => {
                // console.log(err)
                navigate(-1)
            })
    }, [id, handlePublicacao])

    const Info = ({ publicacao }) => {
        return (
            <>
                {publicacao?.categoria_id === 1 &&
                    <div>
                        <p className="m-0 lh-sm">{publicacao.nome} _{publicacao.patrimonio_id}</p>
                        <small className=" text-center lh-sm">
                            Denunciado em: <DateHora date={publicacao.created_at} />
                        </small>
                    </div>
                }
                {(publicacao?.tipo === 'anuncio' && publicacao?.categoria_id !== 1) &&
                    <div>
                        <p className="m-0 fw-light">
                            <LocationIcone />
                            <strong className="text-capitalize">
                                {publicacao?.provincia ?? ''}
                            </strong> município de
                            <strong className="text-capitalize">
                                {' ' + publicacao?.municipio}
                            </strong>.
                        </p>
                        <p className="m-0 fw-light"><strong className="text-capitalize">@{publicacao?.nome}</strong></p>

                        {publicacao?.categoria_id != 4 &&
                            <>
                                <p className="m-0 fw-light">
                                    {(publicacao?.nome_pai || publicacao?.nome_mae) && 'Filiação:'}

                                    {publicacao?.nome_pai !== null &&
                                        <strong className="text-capitalize">{' ' + publicacao?.nome_pai} </strong>
                                    }
                                    {publicacao?.nome_mae !== null &&
                                        <>
                                            e
                                            <strong className="text-capitalize">{' ' + publicacao?.nome_mae}</strong>
                                        </>
                                    }
                                </p>

                                {publicacao?.idade !== null &&
                                    <p className="m-0 fw-light">Idade: {publicacao?.idade} anos</p>
                                }
                                <p className="m-0 fw-light">Genero: {publicacao?.genero}</p>
                            </>
                        }
                    </div>
                }
            </>
        )
    }

    return (
        <div className='bg-white' style={{ minHeight: '93vh' }}>
<RegisterAccess page={'pagina fullView publicacao'} />

            <NavBar />

            {publicacao ?
                <>
                    <div className="d-none d-md-block" style={{ marginTop: -30 }}>
                        <Grid container className='container'>
                            <Grid item className="col mt-4" style={{ position: 'sticky', height: '100%', top: 70, }}>

                                <HeaderCardInformacaoUser publicacao={publicacao} />

                                <Info publicacao={publicacao} />

                                <div className="card-body px-0">
                                    <TextoFormatadoFullview texto={publicacao?.descricao} />
                                </div>

                                {/* {-- imagem ou video sobre o anuncio --} */}
                                {publicacao?.ficheiro && <MidiaAnuncio publicacao={publicacao} />}
                            </Grid>

                            <Grid item className="col-5 mt-4" style={{ marginLeft: 40 }}>
                                <div className="overflow-auto card border-0 ">

                                    <CountEsperancasComentarios publicacao={publicacao} />

                                    {(publicacao?.tipo === 'anuncio' || publicacao?.tipo === 'denuncia') &&
                                        <CreateEsperanca
                                            publicacao={publicacao}
                                            setHandlePublicacao={setHandlePublicacao}
                                        />
                                    }

                                    <CreateComentario
                                        view={'desktop'}
                                        publicacao={publicacao}
                                        setHandlePublicacao={setHandlePublicacao}
                                    />
                                </div>

                                <hr />
                                <ComentariosAutoLoader
                                    publicacao={publicacao}
                                    setHandlePublicacao={setHandlePublicacao}
                                />
                            </Grid>
                        </Grid>
                    </div>


                    {/* // {-- //////////////////////////////////////////////////////////////////////////////////////////////////// --}
                    //     {-- //////////////////////////////////////////////////////////////////////////////////////////////////// --}
                    // {-- //////////////////////////////////////////////////////////////////////////////////////////////////// --} */}

                    <div className="d-block d-md-none">
                        <div className="card-body">
                            <HeaderCardInformacaoUser publicacao={publicacao} />

                            <Info publicacao={publicacao} />
                        </div>

                        {/* {--imagem ou video sobre o anuncio --} */}
                        {publicacao?.ficheiro && <MidiaAnuncio publicacao={publicacao} />}

                        <div className="card-body">

                            <TextoFormatadoFullview texto={publicacao?.descricao} />

                            <CountEsperancasComentarios publicacao={publicacao} />

                            <div className="mt-3">
                                {(publicacao?.tipo === 'anuncio' || publicacao?.tipo === 'denuncia') &&
                                    <CreateEsperanca
                                        publicacao={publicacao}
                                        setHandlePublicacao={setHandlePublicacao}
                                    />
                                }

                                <CreateComentario
                                    view={'mobile'}
                                    publicacao={publicacao}
                                    setHandlePublicacao={setHandlePublicacao}
                                />

                                <hr />
                                <ComentariosAutoLoader
                                    publicacao={publicacao}
                                    setHandlePublicacao={setHandlePublicacao}
                                />
                            </div>
                        </div>
                    </div>
                </>
                :
                <LoadingBackdrop open={true} />
            }
        </div>
    )
}
