import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import api from '../../services/api'
import CreateAnuncioDocumento from './create-anuncio-documento'
import AnuncioPessoaIcone from '../../icones/anuncioPessoa'
import AnuncioDocumentoIcone from '../../icones/anuncioDocumento'
import CreateAnuncioPessoa from './create-anuncio-pessoa'
import apiCNP from '../../services/apiCNP'
import { useDispatch, useSelector } from 'react-redux';
import MessageError from '../../messages/messageError'
import compressImage from '../../services/compressImage'
import RegisterAccess from '../../services/registerAccess'


export default function CreateAnuncio() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const user_id = useSelector(state => state.account.user?.id);
    const [anuncio, setAnuncio] = useState({})
    const [categorias, setCategoria] = useState([])
    const [provincias, setProvincias] = useState([]);
    const [provincia, setProvincia] = useState('');
    const [municipios, setMunicipios] = useState([]);
    const [generos, setGeneros] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [outrosLocais, setOutrosLocais] = useState(false)

    useEffect(() => {
        apiCNP.get('/provincias')
            .then(response => {
                setProvincias(response.data.provincias);
            }).catch(err => '')

        api.get('/categorias-anuncio')
            .then(res => {
                setCategoria(res.data.categorias);
            }).catch(err => '')

        api.get('/generos')
            .then(res => {
                setGeneros(res.data.generos);
            }).catch(err => '')

    }, [])

    async function HandleChange(e) {
        e.target.files ?
            setAnuncio({ ...anuncio, [e.target.name]: await compressImage(e.target.files[0]) })
            :
            setAnuncio({ ...anuncio, [e.target.name]: e.target.value })

        e.target.name === 'provinciaId' &&
            apiCNP.get(`/municipios-by-provincia/${e.target.value}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                    setProvincia(res.data.provincia?.designacao);
                }).catch(err => '')
    }

    const createAnuncio = async () => {
        setIsLoading(true)

        const formData = new FormData();
        formData.append('user_id', user_id);
        formData.append('tipo', 'anuncio');
        formData.append('status', false);
        formData.append('categoria_id', anuncio?.categoria);
        formData.append('provincia', provincia ?? anuncio?.provincia ?? '');
        formData.append('municipio', anuncio?.municipio ?? '');
        formData.append('nome', anuncio?.nome ?? '');
        formData.append('nome_pai', anuncio?.nome_pai ?? '');
        formData.append('nome_mae', anuncio?.nome_mae ?? '');
        formData.append('idade', anuncio?.idade ?? '');
        formData.append('genero', anuncio?.genero ?? '');
        formData.append('descricao', anuncio?.descricao ?? '');
        formData.append('ficheiro', anuncio?.ficheiro ?? '');
        formData.append('numero_documento', anuncio?.numero_documento ?? '');
        // for (let dado of formData.values()) { console.log(dado); }

        api.post(`/create-anuncio`, formData)
            .then(response => {
                setIsLoading(false)
                // console.log(response)

                dispatch({
                    type: 'viewPublicacao',
                    payload: { viewPublicacao: response.data.publicacao },
                });

                navigate(`/full-view/${response.data.publicacao?.id}`)
            }).catch(err => {
                setIsLoading(false)
                // console.log(err)
            })
    }

    function autoResize() {
        const textarea = document.getElementById('myTextarea');
        // textarea.style.height = 'auto'; // Resetar altura para recalculá-la
        textarea.style.height = textarea.style.height = textarea.style.height;; // Resetar altura para recalculá-la
        textarea.style.height = textarea.scrollHeight + 'px'; // Ajustar altura conforme o conteúdo
    }

    return (
        <>
            <RegisterAccess page={'pagina criar anuncio'} />
            {/* MESSAGEM DE ERRO  */}
            {errorMessage && <MessageError message={errorMessage} />}

            <div className="modal-content">
                <div className="modal-header border-0 my-2">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        {anuncio?.categoria === 'Documento_Perdido' ?
                            <AnuncioDocumentoIcone />
                            :
                            <AnuncioPessoaIcone />
                        }
                        <strong> Anúncio</strong>
                    </h5>
                    <Link to={'/'} className="btn-close" title="fechar formulário" />
                </div>
                <div className="modal-body">
                    <div>
                        <select
                            onChange={HandleChange}
                            className="form-select"
                            name="categoria"
                            defaultValue={''}
                        >
                            <option value={''} selected disabled>Selecionar categoria de anuncio</option>
                            {categorias.map((categoria) => (
                                <option key={categoria.id} value={categoria.id} >{categoria.categoria}</option>
                            ))}
                        </select>
                    </div>
                    <br />
                    <strong>Dados sobre o anuncio</strong>
                    <br />
                    <br />

                    <label htmlFor='outrosLocais' className="mb-3">
                        <input onClick={() => setOutrosLocais(!outrosLocais)} id="outrosLocais" className="form-check-input me-2" type="checkbox" />
                        Outros locais
                    </label>

                    {anuncio?.categoria == 4 ?
                        <CreateAnuncioDocumento
                            anuncio={anuncio}
                            provincias={provincias}
                            municipios={municipios}
                            isLoading={isLoading}
                            createAnuncio={createAnuncio}
                            HandleChange={HandleChange}
                            setErrorMessage={setErrorMessage}
                            outrosLocais={outrosLocais}
                            autoResize={autoResize}
                        />
                        :
                        <CreateAnuncioPessoa
                            anuncio={anuncio}
                            provincias={provincias}
                            municipios={municipios}
                            isLoading={isLoading}
                            generos={generos}
                            createAnuncio={createAnuncio}
                            HandleChange={HandleChange}
                            setErrorMessage={setErrorMessage}
                            outrosLocais={outrosLocais}
                            autoResize={autoResize}
                        />
                    }
                </div>
            </div>
        </>
    )
}
