import ImagePerfil from '../imageUser/imagePerfil';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import api from '../../services/api';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import LoadingShort from '../../load/loadingShort';
import AddAnuncioIcone from '../../icones/addAnuncioIcone';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

export default function CreatePublicacao({ setHandleUpdate }) {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const user_id = useSelector(state => state.account.user?.id);
    const [publicacao, setPublicacaos] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleInputChange = (e) => {
        setPublicacaos({ ...publicacao, [e.target.name]: e.target.value, });
        autoResize()
    };

    // console.log(publicacao)

    const createPublicacao = (e) => {
        e.preventDefault();
        setIsLoading(true)

        api.post(`/create-publicacao`,
            {
                ...publicacao,
                user_id,
                categoria_id: 2,
                tipo: 'publicacao',
                status: false

            }).then(response => {
                setHandleUpdate(1)
                setIsLoading(false)
                handleClose()

                dispatch({
                    type: 'viewPublicacao',
                    payload: { viewPublicacao: response.data.publicacao },
                });

                navigate(`/full-view/${response.data.publicacao?.id}`)

            }).catch(err => {
                setIsLoading(false)
                // console.log(err)
            })
    }

    function autoResize() {
        const textarea = document.getElementById('publicacaoTextarea');
        // textarea.style.height = 'auto'; // Resetar altura para recalculá-la
        textarea.style.height = textarea.style.height = textarea.style.height; // Resetar altura para recalculá-la
        textarea.style.height = textarea.scrollHeight + 'px'; // Ajustar altura conforme o conteúdo
    }

    return (
        <div className="card card-body shadow mt-3">
            <div className="d-flex">
                <Link to={'/perfil/minhas-publicacoes'}>
                    <ImagePerfil />
                </Link>

                <button
                    onClick={handleClickOpen}
                    style={{ textAlign: 'start' }}
                    className="form-control p-2 rounded-pill" >
                    Faça uma publicação
                </button>
            </div>

            <BootstrapDialog
                scroll='body'
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                style={{ margin: -30 }}
            >

                <form onSubmit={createPublicacao}>
                    <div className="modal-content" style={{ minHeight: '100vh' }}>
                        <div className="modal-header  alert-success">
                            <h5 className="modal-title" id="publicacao">Publicação</h5>
                            <button type="button" className="btn-close" onClick={handleClose}></button>
                        </div>

                        <div className="modal-body">
                            <textarea
                                id="publicacaoTextarea"
                                autoFocus
                                className="form-control"
                                rows="3"
                                name="descricao"
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        {/*  ATALHO PARA CRIAR ANUNCIO */}
                        {/* <Link to={'/create-anuncio'} className="btn btn-success w-100"><small>Fazer anuncio <AnuncioIcone /></small></Link> */}

                        <div className="text-center m-2">
                            <button
                                disabled={isLoading}
                                type="submit"
                                className="w-50 btn btn-success">
                                {!isLoading
                                    ?
                                    'Publicar'
                                    :
                                    <LoadingShort />
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </BootstrapDialog>

            <hr className=" bg-success" />
            <Link to={'/create-anuncio'} className="btn btn-success p-1 w-100"><small>Fazer anuncio <AddAnuncioIcone /></small></Link>
        </div>
    )
}
