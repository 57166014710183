import Auth from '../../services/auth/auth'
import HeaderCardInformacaoUser from '../header/headerCard-informacaoUser'
import CreateComentario from '../createComentario'
import { useState } from 'react'
import CreateEsperanca from '../createEsperanaca'
import HeaderCardPerfilUser from '../header/headerCard-perfilUser'
import LocationIcone from '../../icones/location'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import MidiaAnuncio from '../../utils/midiaAnuncio'
import CountEsperancasComentarios from '../../utils/countEsperancasComentarios'
import TextoFormatado from '../../utils/textoFormatado'
// import ComentariosAutoLoader from '../comentariosAutoLoader'

// import Avatar from '@mui/material/Avatar';
// import AvatarGroup from '@mui/material/AvatarGroup';


export default function CardAnuncio({ setHandleUpdate, publicacao, perfil }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [handlePublicacao, setHandlePublicacao] = useState(publicacao)
    // console.log(publicacao)
    // const title = "Conteúdo incrível do meu site";

    const viewPublicacao = (publicacao) => {
        dispatch({
            type: 'viewPublicacao',
            payload: { viewPublicacao: publicacao },
        });

        navigate(`/full-view/${publicacao?.id}`)
    }

    return (
        <div className='card'>
            <div className="card-body">
                {perfil ?
                    <HeaderCardPerfilUser setHandleUpdate={setHandleUpdate} publicacao={publicacao} />
                    :
                    <HeaderCardInformacaoUser publicacao={publicacao} />
                }

                <p className="m-0 fw-light">
                    <LocationIcone />
                    <strong className="text-capitalize">
                        {publicacao?.provincia ?? ''}
                    </strong> município de
                    <strong className="text-capitalize">
                        {' ' + publicacao?.municipio}
                    </strong>.
                </p>

                <p className="m-0 fw-light"><strong className="text-capitalize">@ {publicacao?.nome}</strong></p>

                {publicacao?.categoria_id !== 4 &&
                    <>
                        <p className="m-0 fw-light">

                            {(publicacao?.nome_pai || publicacao?.nome_mae) && 'Filiação:'}

                            {publicacao?.nome_pai !== null &&
                                <strong className="text-capitalize">{' ' + publicacao?.nome_pai} </strong>
                            }
                            {publicacao?.nome_mae !== null &&
                                <>
                                    e  <strong className="text-capitalize">{' ' + publicacao?.nome_mae}</strong>
                                </>
                            }
                        </p>

                        {publicacao?.idade !== null && <p className="m-0 fw-light">Idade: {publicacao?.idade} anos</p>}
                        {publicacao?.idade !== null && <p className="m-0 fw-light">Genero: {publicacao?.genero}</p>}
                    </>
                }
            </div>

            {/* {-- imagem ou video sobre o anuncio --} */}
            <MidiaAnuncio publicacao={publicacao} viewPublicacao={viewPublicacao} />

            <div className="card-body">
                {/* <TextFormatado texto={publicacao.descricao} /> */}
                <TextoFormatado
                    texto={publicacao.descricao}
                    publicacao={publicacao}
                    viewPublicacao={viewPublicacao}
                />
                
            </div>

            <CountEsperancasComentarios publicacao={handlePublicacao} />

            <Auth>
            <div className="card-body">
                <CreateEsperanca
                    publicacao={publicacao}
                    setHandlePublicacao={setHandlePublicacao}
                />

                <CreateComentario
                    publicacao={publicacao}
                    setHandlePublicacao={setHandlePublicacao}
                />

                {/* <ComentariosAutoLoader
                    publicacao={handlePublicacao}
                    setHandlePublicacao={setHandlePublicacao}
                /> */}
            </div>
            </Auth>
        </div>
    )
}
