
import { Typography } from '@mui/material';
import { useState } from 'react';

export default function TextoFormatado({ texto, publicacao, viewPublicacao }) {
    const textFormatado = texto.slice(0, 200).split('\n')

    return (
        textFormatado.map((textLine, index) =>
            <Typography key={index} variant="body1" style={{ marginTop: 10, lineHeight: 1.2, }}>
               
                {textLine}

                {(texto.length > 200 && index === textFormatado.length - 1 ) && <span onClick={() => viewPublicacao(publicacao)} style={{ color: 'GrayText', cursor: 'pointer' }}>{'...'} Ver mais</span>}
            </Typography>
        )
    )
}

// export default function TextoFormatado({ texto,}) {
//     const [vertTudo, setVerTudo] = useState(200)

//     const textFormatado = texto.slice(0, vertTudo).split('\n')

//     const handleVertTudo = () => {
//         vertTudo === 200 ? setVerTudo(texto.length) : setVerTudo(200)
//     }

//     return (
//         textFormatado.map((textLine, index) =>
//             <Typography key={index} variant="body1" style={{ marginTop: 10, lineHeight: 1.2, }}>
//                 {textLine}

//                 {(texto.length > 200 && index === textFormatado.length - 1) && <span onClick={handleVertTudo} style={{ color: 'GrayText', cursor: 'pointer' }}> {vertTudo === 200 ? ' ...Ver mais' : ' Ver menos'}</span>}
//             </Typography>
//         )
//     )
// }
