import { Link } from 'react-router-dom'
import PatrimonioIcone from '../icones/patrimonio'
import GuardadosIcone from '../icones/guardados'

export default function UsuarioLogado() {
    return (
        <div className="mt-3">
            {/* <Link to={'/perfil/meus-guardados'} className="dropdown-item btn btn-success my-2"> <GuardadosIcone /> Guardados</Link> */}
            <a href={'/perfil/meus-guardados'} className="dropdown-item btn btn-success my-2"> <GuardadosIcone /> Guardados</a>

            <Link to={'/registo-de-patrimonio'} className="dropdown-item  btn btn-success my-2"> <PatrimonioIcone /> Registar patrimônio </Link>

            <Link to={'/sobre-patrimonios'} className="dropdown-item  btn btn-success my-2"><PatrimonioIcone /> Sobre patrimônios </Link>

            {/* <Link to="" className="dropdown-item disabled my-2" style="color: red">@include('icones.pedirajuda')Enviar pedido de ajuda</Link>  */}
            <hr className=" bg-success" />
        </div>
    )
}
