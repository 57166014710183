import { Box, Card, MenuItem, Typography } from "@mui/material";
// import FotoEdit from "../../../utils/fotoEdit";
// import StatusMembros from "../../../status/statusMembros";
import { Divider } from "@material-ui/core";
import Perfil from "../../../utils/perfil";
import { Link, useNavigate, useParams } from "react-router-dom";
import ImageUpdate from "../../../userProfile/imageUpdate";
import { useLocation } from 'react-router-dom';


export default function NavLateral({ user }) {
    const navigate = useNavigate()
    const url = useLocation();
    const { id } = useParams()
    // console.log(url)

    return (
        <Card style={{ padding: 10 }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ImageUpdate user={user} />
            </div>
            {/* <FotoEdit foto={user?.foto_perfil} dadosPessoal={user} /> */}

            <Typography style={{ fontFamily: 'Work Sans', lineHeight: 1.5 }} align="center" variant="body1">
                <strong>{user?.nome}</strong>
                <br />
                <Perfil perfil={user?.perfil} genero={user?.genero} />
                <br />
            </Typography>

            {/* <StatusMembros status={user.status} /> */}

            <Divider />

            <Box marginTop={2}>
            {/* <MenuItem ><Link  to={`/configuracoes/desativar-conta/${id}`} className=" text-decoration-none text-danger">Desativar conta</Link></MenuItem> */}
                <MenuItem  className="text-danger" onClick={() => { navigate(`/configuracoes/desativar-conta/${id}`) }} >Desativar conta </MenuItem>
                <MenuItem disabled selected={url.pathname === `/admin/gest-user/${user.id}`} onClick={() => { navigate(`/admin/.../${user.id}`) }} >Informações de user </MenuItem>
                <MenuItem disabled onClick={() => { navigate(`/admin/.../${user.id}`) }} >Anuncios </MenuItem>
                <MenuItem disabled onClick={() => { navigate(`/admin/.../${user.id}`) }} >Publicacoes </MenuItem>
                <MenuItem disabled onClick={() => { navigate(`/admin/.../${user.id}`) }} >Patrimonios </MenuItem>
                <MenuItem disabled onClick={() => { navigate(`/admin/.../${user.id}`) }} >Comentários </MenuItem>
                <MenuItem disabled onClick={() => { navigate(`/admin/.../${user.id}`) }} >Esperanças </MenuItem>
                <MenuItem disabled onClick={() => { navigate(`/admin/.../${user.id}`) }} >Notificações </MenuItem>
                <MenuItem disabled onClick={() => { navigate(`/admin/.../${user.id}`) }} >Guardados </MenuItem>
                <MenuItem disabled onClick={() => { navigate(`/admin/.../${user.id}`) }} >Transferências </MenuItem>
                <MenuItem disabled onClick={() => { navigate(`/admin/.../${user.id}`) }} >Feedbacks </MenuItem>
                <MenuItem disabled onClick={() => { navigate(`/admin/.../${user.id}`) }} >Agradecimentos </MenuItem>
            </Box>

        </Card>
    )
}