
import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import GuardarIcone from '../icones/guardar';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import LoadingShort from '../load/loadingShort';


export default function HeaderConfigs(props) {
    // const user_id = useSelector(state => state.account.user?.id);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const url = useLocation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ fontSize: 20, flexGrow: 1 }}>
                {props.title}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        // sx={{ ml: 1 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <MoreHorizOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        // mt: .5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {props.configArea === 'publicacao' &&
                    <div>
                        {/* {(user_id !== props.publicacao.user.id && url.pathname !== '/perfil/meus-guardados') && */}
                        {( url.pathname !== '/perfil/meus-guardados') &&
                            <MenuItem size='small' onClick={() => props.SalvePublicacao()}>
                                {props.loading ?
                                    <LoadingShort />
                                    :
                                    <> Guardar <GuardarIcone /> </>
                                }
                            </MenuItem>
                        }
                        {/* {props.publicacao.tipo === 'anuncio' &&
                                    <MenuItem size='small' onClick={() => navigate()}>
                                        Entrar em contacto
                                    </MenuItem>
                                } */}

                    </div>
                }

                {props.configArea === 'publicacaoPerfil' &&
                    <div>
                        {(props.publicacao.tipo === 'anuncio' && props.publicacao.status == false) ?
                            <MenuItem size='small' onClick={() => navigate(`/perfil/encerrar-procura/${props.publicacao.id}`)}>
                                Encerrar procura
                            </MenuItem>
                            :
                            props.publicacao.tipo === 'anuncio' &&
                            <MenuItem disabled>
                                Concluído
                            </MenuItem>
                        }
                        <MenuItem onClick={() => props.handleClickOpen()}>
                            Eliminar
                        </MenuItem>
                    </div>
                }

                {props.configArea === 'patrimonio' &&
                    <div>
                        {props.patrimonio.denuncia == false ?
                            <>
                                <MenuItem onClick={() => navigate(`/perfil/transferir-patrimonio/${props.patrimonio.id}`)}>
                                    Transferir
                                </MenuItem>
                                <MenuItem onClick={() => props.handleClickOpen()}>
                                    Denunciar
                                </MenuItem>
                            </>
                            :
                            <MenuItem onClick={() => props.handleClickOpen()}>
                                Retirar Denunciar
                            </MenuItem>
                        }
                        <MenuItem onClick={() => props.handleClickOpenEliminar()}>
                            Eliminar
                        </MenuItem>
                    </div>
                }
            </Menu>
        </div>
    );
}
