import { useState } from 'react'
import { useSelector } from 'react-redux';
import ImagePerfil from './imageUser/imagePerfil';
import LoadingShort from '../load/loadingShort';
import EnviarIcone from '../icones/enviar';
import api from '../services/api';

export default function CreateComentario({ publicacao, setHandlePublicacao, view }) {
    const [comentario, setComentario] = useState('')
    const [loading, setLoading] = useState(false)
    const user = useSelector(state => state.account.user);

    const handleInputChange = (e) => {
        setComentario({ ...comentario, [e.target.name]: e.target.value, });
    };

    const createComentario = (e) => {
        e.preventDefault();
        setLoading(true);

        api.post(`/create-comentario`, {
            ...comentario,
            user_id: user?.id,
            anuncios_publicacoes_id: publicacao?.id,
            tipoPublicacao: publicacao.tipo

        }).then(response => {
            // console.log(response)
            clearInput()
            setLoading(false);
            setHandlePublicacao(response.data.publicacao)

            // dispatch({
            //     type: 'alert',
            //     payload: { alert: response.data },
            // });

        }).catch(err => {
            // console.log(err)
            setLoading(false);
        })
    }

    const clearInput = () => {
        document.getElementById(`${view}input${publicacao?.id}`).value = '';
        setComentario('')
    }

    return (
        <form onSubmit={createComentario} className="mt-3 d-flex align-items-center  ">
            <ImagePerfil />

            <input
                id={`${view}input${publicacao?.id}`}
                required
                className="form-control rounded-pill text-break me-2"
                type="text"
                name="descricao"
                placeholder="Escreve um comentário"
                defaultValue={comentario}
                onChange={handleInputChange}
            />

            <button
                title='Enviar'
                type="submit"
                disabled={loading || !comentario}
                className="btn btn-outline-success border-0 p-1 "
            >
                {loading ?
                    <LoadingShort />
                    :
                    // 'Enviar'
                    <EnviarIcone />
                }
            </button>
        </form>
    )
}
