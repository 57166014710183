
import { useEffect, useState } from 'react';
import { signIn } from '../../actions/accountActions';
import { useDispatch } from 'react-redux';
import LoadingShort from '../../load/loadingShort';
import MessageError from '../../messages/messageError';
import { FormControl, IconButton, InputAdornment, Typography } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FacebookLoginButton from '../../providder/facebookLoginButton';
import GoogleLoginButton from '../../providder/googleLoginButton';

import { gapi } from 'gapi-script'
import RegisterAccess from '../../services/registerAccess';


const LoginForm = () => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)
    const [errorLogin, setErrorLogin] = useState()
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    useEffect(() => { document.title = "login" }, [])

    const clientId = "391185611802-gekb7civkan01taps77sqgp75ihhaoj7.apps.googleusercontent.com"
    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: clientId,
                scope: ''
            })
        }
        gapi.load('client:auth2', start)
    })


    // const csrf = () => { api.get('http://127.0.0.1:8000/sanctum/csrf-cookie') }
    //  console.log(csrf)

    const login = async (e) => {
        e.preventDefault()
        setLoading(true)
        setErrorLogin('')
        // await csrf()
        try {
            const credentials = { email, password };
            await dispatch(signIn(credentials));
            setLoading(false)
            // navigate("/feed")
            window.location.reload()

        } catch (error) {
            setLoading(false)
            // console.error('Erro ao fazer login:', error);
            setErrorLogin(error.error)
        }
    };

    return (
        <>
            <RegisterAccess page={'pagina login'} />
            {/* MESSAGEM DE ERRO  */}
            {errorLogin && <MessageError message={errorLogin} />}
            <div className='bg-white d-flex' style={{ minHeight: '100vh', marginTop: -105 }}>

                <div className="row align-items-center container p-0 m-auto" >
                    <div className="col d-none d-md-block">
                        <a href={'/'} >
                            <img className="m-auto d-block" src="/icones/iconesMeencontre/meencotreVertical.svg" alt="meencontre" />
                        </a>
                        <h1 className='text-center' style={{ fontSize: 25 }}>
                            Um lugar de esperanças
                        </h1>
                    </div>

                    <div className="col" >
                        <div className='mx-auto' style={{ maxWidth: 400 }}>

                            <div className="d-block d-md-none">
                                <a href={'/'} >
                                    <img className="img-fluid d-block m-auto" src="/icones/iconesMeencontre/meencotreVertical.svg" alt="meencontre" style={{ width: '70%' }} />
                                </a>

                                <h1 className='text-center' style={{ fontSize: 25 }}>
                                    Um lugar de esperanças
                                </h1>
                            </div>

                            <br />
                            <form>
                                {/* <!-- Email --> */}
                                <input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-control" placeholder="E-mail" id="email" type="email" name="email" required autoFocus />

                                {/* <!-- Password --> */}
                                <FormControl fullWidth variant="outlined" margin="normal">
                                    {/* <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel> */}
                                    <OutlinedInput
                                        disabled={!email}
                                        required
                                        size="small"
                                        name="password"
                                        placeholder="Senha"
                                        onChange={(e) => setPassword(e.target.value)}
                                        id="outlined-adornment-password"
                                        autoComplete="current-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    // label="Senha"
                                    />
                                </FormControl>

                                <div className="border-0 col  text-center " style={{ background: 'none' }}>
                                    <br />
                                    <button
                                        disabled={loading || !password}
                                        onClick={login}
                                        className="btn btn-success w-50">
                                        {loading ?
                                            <LoadingShort />
                                            :
                                            'Entrar'
                                        }
                                    </button>
                                    <br />
                                    <br />

                                    <a className="text-decoration-none text-success btn disabled" href="/">
                                        <small>
                                            Esqueceu sua senha?
                                        </small>
                                    </a>
                                    
                                    <br />
                                    <small className="text-dark mt-2">Ainda não criou uma conta?
                                        <a href={'/register'} className="btn btn-outline-success border-0 text-decoration-none p-1">
                                            Criar agora
                                        </a>
                                    </small>


                                    {/* Login com redes sociais  */}
                                    {/* <div style={{ marginTop: 20 }}>
                                        <p>Outras opções de login</p>
                                        <GoogleLoginButton />
                                        <FacebookLoginButton />
                                    </div> */}
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="text-center bg-white p-2" style={{ minHeight: '5vh', }}>
                <small className="text-dark">
                    Ao fazer login, você concorda
                    <a className="text-decoration-none text-success" target="blank" href="/termos-e-condicoes"> Termos de Serviço </a>
                    com os  e a
                    <a className="text-decoration-none text-success" target="blank" href="/termos-e-condicoes"> Política de Privacidade </a>
                    , incluindo o Uso de Cookies.
                </small>
            </div> */}
        </>
    );
};

export default LoginForm;
