import React, { useEffect, useState } from 'react';
// import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function MessageState() {
    const alert = useSelector(state => state.alert.data?.message);
    const [open, setOpen] = useState(!!alert);

    useEffect(() => {
        setOpen(!!alert)
    }, [alert])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="error"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        // <Stack spacing={2} sx={{ width: '100%', marginLeft: 300 }} >
        //     <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        //         {/* severity="error" */}
        //         <Alert   onClose={handleClose} sx={{ width: '100%' }}>
        //             {alert}
        //         </Alert>
        //     </Snackbar>
        // </Stack>

        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            message={alert}
            action={action}
        />
    );
}

