import SEO from './seo'
import SocialShare from './socialShare'
import { useRef } from 'react';
import useIntersectionObserver from './useIntersectionObserver';

export default function MidiaAnuncio({ publicacao, viewPublicacao }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    // const isDevelopment = process.env.NODE_ENV === 'development';

    const ref = useRef();
    const isVisible = useIntersectionObserver(ref, { threshold: 0.1 });

    // console.log(document.querySelector('meta[property="og:image"]').getAttribute('content') )

    return (
        <>
            {/* PARTILHAR COTEUDOS NAS REDES SOCIAIS */}
            {isVisible &&
                <SEO title={publicacao?.categoria?.categoria} description={publicacao.descricao.slice(0, 200)} url={`https://www.partilhado.meencontre.com/public/api/partilhado/facebook/${publicacao?.id}`} image={`${apiUrl + publicacao.ficheiro}`} />
            }

            <SocialShare publicacao={publicacao} /> 

            {/* <img onClick={() => viewPublicacao(publicacao)} src={`data:image/jpeg;base64,${publicacao.ficheiro}`} alt="img" className="card-img img-fluid" style={{ cursor: 'pointer' }} /> */}
            {/* <img onClick={() => viewPublicacao && viewPublicacao(publicacao)} src={`http://127.0.0.1:8000/public/storage/${publicacao.ficheiro}`} alt={publicacao?.nome} className="card-img img-fluid" style={{ cursor: 'pointer' }} /> */}
            <img ref={ref}  onClick={() => viewPublicacao && viewPublicacao(publicacao)} src={`${apiUrl + publicacao.ficheiro}`} alt={publicacao?.nome} className="card-img img-fluid" style={{ cursor: 'pointer' }} />

            {/* {isDevelopment ?
            <img onClick={() => viewPublicacao && viewPublicacao(publicacao)} src={`http://127.0.0.1:8000/public/storage/${publicacao.ficheiro}`} alt={publicacao?.nome} className="card-img img-fluid" style={{ cursor: 'pointer' }} />
            :
            <img onClick={() => viewPublicacao && viewPublicacao(publicacao)} src={`${apiUrl+publicacao.ficheiro}`} alt={publicacao?.nome} className="card-img img-fluid" style={{ cursor: 'pointer' }} />
             } */}
        </>
    )
}
