// validate
import * as yup from 'yup';


const validacaoAnuncioPessoa = (anuncio) => {

    return new Promise(async (resolve, reject) => {

        let schema = yup.object().shape({

            ficheiro: yup.string("Carregar imagem do anuncio")
                .required("Carregar imagem do anuncio"),

            descricao: yup.string("Faça a descricão do anuncio")
                .required("Faça a descricão do anuncio"),

            genero: yup.string("Qual é o genero da pessoa?")
                .required("Qual é o genero da pessoa?"),

            nome: yup.string("Qual é o nome da pessoa?")
                .required("Qual é o nome da pessoa?"),

            municipio: yup.string("Aconteceu em que municipio?")
                .required("Aconteceu em que municipio?"),

            // provincia: yup.string("Aconteceu em que provincia?")
            //     .required("Aconteceu em que provincia?"),

            categoria: yup.string("Seleciona  categória deste anuncio ")
                .required("Seleciona a categória deste anuncio"),
        })

        try {
            await schema.validate(anuncio)
            return resolve(true)
        } catch (error) {
            return reject(error.errors);
        }
    });
};
const validacaoAnuncioDocumento = (anuncio) => {

    return new Promise(async (resolve, reject) => {

        let schema = yup.object().shape({

            ficheiro: yup.string("Carregar imagem do anuncio")
                .required("Carregar imagem do anuncio"),

            descricao: yup.string("Faça a descricão do anuncio")
                .required("Faça a descricão do anuncio"),

            numero_documento: yup.string("Qual é o número de documento?")
                .required("Qual é o número de documento?"),

            municipio: yup.string("Aconteceu em que municipio?")
                .required("Aconteceu em que municipio?"),

            // provincia: yup.string("Aconteceu em que provincia?")
            //     .required("Aconteceu em que provincia?"),

            categoria: yup.string("Seleciona a  categória deste anuncio ")
                .required("Seleciona a categória deste anuncio"),
        })

        try {
            await schema.validate(anuncio)
            return resolve(true)
        } catch (error) {
            return reject(error.errors);
        }
    });
};

const validacaoRegiterPatrimonio = (patrimonio) => {

    return new Promise(async (resolve, reject) => {

        let schema = yup.object().shape({

            ficheiro: yup.string("Carregar imagem do patrimonio")
                .required("Carregar imagem do patrimonio"),


            patrimonio_id: yup.string("Está em falta o ID do patrimonio")
                .required("Está em falta o ID do patrimonio"),

            nome: yup.string("Atribui um nome para o patrimonio")
                .required("Atribui um nome para o patrimonio"),

            tipoPatrimonio: yup.string("Seleciona tipo de patrimonio a ser registado")
                .required("Seleciona tipo de patrimonio a ser registado"),
        })

        try {
            await schema.validate(patrimonio)
            return resolve(true)
        } catch (error) {
            return reject(error.errors);
        }
    });
};

const validacaoRegister = (register) => {

    return new Promise(async (resolve, reject) => {

        let schema = yup.object().shape({

            password_confirmation: yup.string("Confirma a senha")
                .required("Confirma a senha"),

            password: yup.string("Preenche a senha")
                .required("Preenche a senha"),

            contacto: yup.string("Preenche o contacto")
                .required("Preenche o contacto"),

            nome: yup.string("Preenche o nome ")
                .required("Preenche nome "),

            municipio: yup.string("Preenche municipio?")
                .required("Preenche municipio?"),

            provincia: yup.string("Preenche provincia?")
                .required("Preenche provincia?"),
        })

        try {
            await schema.validate(register)
            return resolve(true)
        } catch (error) {
            return reject(error.errors);
        }
    });
};

export {
    validacaoRegister,
    validacaoAnuncioPessoa,
    validacaoAnuncioDocumento,
    validacaoRegiterPatrimonio,
};



