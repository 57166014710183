import { useEffect, useState } from 'react';
import api from '../../services/api';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import LoadingShort from '../../load/loadingShort';
import Loading from '../../load/loading';
import { useNavigate } from "react-router-dom";
import CombineCards from '../../components/cards/combineCards';


export default function EncerrarProcura() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const user_id = useSelector(state => state.account.user?.id);
    const [loading, setLoading] = useState(false);
    const [publicacao, setPublicacao] = useState('')
    const [descricao, setdescricao] = useState('')

    const handleInputChange = (e) => {
        setdescricao({ ...descricao, [e.target.name]: e.target.value, });
        autoResize()
    };

    useEffect(() => {
        api.get(`/anuncio/${id}`)
            .then(response => {
                response.data.anuncio.user_id == user_id ?
                    setPublicacao([response.data.anuncio])
                    :
                    navigate(-1)
                // console.log(response)
            }).catch(err => {
                // console.log(err)
            })
    }, [id])

    const encerrarProcura = () => {
        setLoading(true);
        api.post(`/perfil/encerrar-procura`, {
            ...descricao,
            publicacao_id: id,
        }).then(response => {
            // console.log(response)
            setLoading(false);

            dispatch({
                type: 'alert',
                payload: { alert: response.data },
            });

            dispatch({
                type: 'minhasPublicacoes',
                payload: { minhasPublicacoes: null, config: null },
            });

            navigate(-1)
        }).catch(err => {
            // console.log(err)
            setLoading(false);
        })
    }

    
    function autoResize() {
        const textarea = document.getElementById('encerrarTextarea');
        // textarea.style.height = 'auto'; // Resetar altura para recalculá-la
        textarea.style.height = textarea.style.height = textarea.style.height; // Resetar altura para recalculá-la
        textarea.style.height = textarea.scrollHeight + 'px'; // Ajustar altura conforme o conteúdo
    }
      

    return (
        <>
            {publicacao ?
                <>
                    <div className="modal-content border-bottom-0" style={{ marginBottom: -3 }}>
                        <div className="modal-header border-0 alert-success p-2">
                            <h5 className="modal-title" id="staticBackdropLabel">Encerrar procura</h5>
                            {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                        </div>
                        <div className="col modal-body">
                            <p>Para fazer o encerramento descreve em poucas palavras como foi a conclusão desta procura.</p>
                            <textarea id="encerrarTextarea" onChange={handleInputChange} className="form-control" name="descricao" rows="2" required placeholder="Descrever.."></textarea>
                        </div>

                        <div className="text-center  border-0 mb-2">
                            <button
                                onClick={encerrarProcura}
                                disabled={loading}
                                className="btn btn-sm btn-success mx-3 w-50">
                                {loading ?
                                    <LoadingShort />
                                    :
                                    'Salvar'
                                }
                            </button>
                        </div>
                    </div>

                    <CombineCards publicacoes={publicacao} perfil={true} />
                </>
                :
                <Loading />
            }
        </>
    )
}
