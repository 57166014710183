
import { Typography } from '@mui/material';

export default function TextoFormatadoFullview({ texto }) {
    const textFormatado = texto.split('\n')

    return (
        textFormatado.map((textFormatado, index) =>
            <Typography key={index} variant="body1" style={{ marginTop: 10, lineHeight: 1.2, }}>
                {textFormatado}
            </Typography>
        )
    )
}


