
import { useEffect, useState } from 'react'
import LoadingSkeleton from '../../load/loadingSkeleton'
import CardPatrimonioDenunciado from '../../components/cards/cardPatrimonioDenunciado';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../services/api';
import LoadingShort from '../../load/loadingShort';

export default function MinhasDenuncias() {
  const dispatch = useDispatch();
  const [denunciadosConfig, setDenunciadosConfig] = useState(useSelector(state => state.patrimoniosDenunciados?.config));
  const [denunciados, setDenunciados] = useState(useSelector(state => state?.patrimoniosDenunciados.data) ?? '');
  const [page, setPage] = useState(useSelector(state => state?.patrimoniosDenunciados?.config?.current_page ?? 1));
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    api.get(`/perfil/meus-patrimonios-denunciado?tipo=denuncia`)
      .then(response => {
        // console.log(response)
        setDenunciados(response.data.anuncios.data);
        setDenunciadosConfig(response.data.anuncios);
        setPage(page + 1)

        dispatch({
          type: 'patrimoniosDenunciados',
          payload: { patrimoniosDenunciados: response.data.anuncios.data, config: response.data.anuncios },
        });
      }).catch(err => 'console.log(err)')
  }, []);


  const encontrarMais = () => {
    setIsLoading(true)
    api.get(`/perfil/meus-patrimonios-denunciado?tipo=denuncia&page=${page}`)
      .then(response => {
        // console.log(response)
        setDenunciados((prevItems) => [...prevItems, ...response.data.anuncios.data]);
        setDenunciadosConfig(response.data.anuncios);
        setPage(page + 1)
        setIsLoading(false)

        dispatch({
          type: 'patrimoniosDenunciados',
          payload: { patrimoniosDenunciados: response.data.anuncios.data, config: response.data.anuncios },
        });
      }).catch(err => setIsLoading(false))
  }

  return (
    <div>
      {denunciados ?
        <>
          {denunciados?.map((denunciado, index) => (
            <div key={index} className="col card mb-3 alert alert-dismissible fade show p-0" role="alert">
              <CardPatrimonioDenunciado publicacao={denunciado} perfil={true} />
            </div>
          ))}

          {(denunciados.length < 1) ?
            <p style={{ textAlign: 'center' }}>
              Nenhum patrimônio denunciado
            </p>
            :
            <>
              {denunciadosConfig?.next_page_url ?
                <div align='center'>
                  <button onClick={encontrarMais} className='btn btn-success m-2'>
                    {!isLoading ? 'Encontrar mais' : <LoadingShort />}
                  </button>
                </div>
                :
                <p style={{ textAlign: 'center' }}>
                  Sem mais denuncias
                </p>
              }
            </>
          }
        </>
        :
        <LoadingSkeleton />
      }

    </div>
  )
}
