import { useEffect, useState } from 'react'
import FiltroIcone from '../../icones/filtro'
import api from '../../services/api';
import { Link } from 'react-router-dom';
import LoadingShort from '../../load/loadingShort';
import CardAnuncio from '../../components/cards/cardAnuncio';
import RegisterAccess from '../../services/registerAccess';

export default function Filtro() {
    const [provincias, setProvincias] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [filtro, setFiltro] = useState([]);
    const [responseFiltro, setResponseFiltro] = useState('');
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        api.get('/provincias')
            .then(response => {
                setProvincias(response.data.provincias);
            }).catch(err => '')


    }, [])

    function HandleChange(e) {
        e.target.files ?
            setFiltro({ ...filtro, [e.target.name]: e.target.files[0] })
            :
            setFiltro({ ...filtro, [e.target.name]: e.target.value })

        e.target.name === 'provincias_id' &&
            api.get(`/municipios/${e.target.value}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                }).catch(err => '')
    }

    const createEsperanca = (e) => {
        e.preventDefault();
        setLoading(true);
        setResponseFiltro('')

        api.get(`/filtro`, filtro)
            .then(response => {
                setResponseFiltro(response.data.anuncios);
                // console.log(response)
                setLoading(false);

            }).catch(err => {
                // console.log(err)
                setLoading(false);
            })
    }

    return (
        <>
        <RegisterAccess page={'pagina filtro'} />
            <div className="modal-content">
                <div className="modal-header border-0">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        <FiltroIcone /><strong> Filtra o que desejas encontrar</strong>
                    </h5>
                    <Link to={'/'} className="btn-close" title="fechar formulário" />
                </div>

                <div className="modal-body">
                    <form onSubmit={createEsperanca} className="m-0 row g-3" >

                        <p className="text-start my-0"><small>Preenche o formulário o máximo possível para fazer uma buscadetalhada</small></p>

                        <div className="col-md-6  p-0">
                            <select
                                name="provincias_id"
                                onChange={HandleChange}
                                className="form-select"
                                defaultValue={filtro?.provincia}
                            >
                                <option value="provincia" selected disabled>Selecionar provincia</option>
                                {provincias.map((provincia) => (
                                    <option key={provincia.id} value={provincia.id} selected>{provincia.designacao}</option>
                                ))}
                            </select>
                        </div>

                        <div className="col-md-6 p-0">
                            <select
                                onChange={HandleChange}
                                className="form-select"
                                name="municipios_id"
                                defaultValue={filtro?.municipios}
                            >
                                <option selected disabled value="município">Selecionar município</option>
                                {municipios.map((municipio) => (
                                    <option key={municipio.id} value={municipio.id} selected>{municipio.designacao}</option>
                                ))}
                            </select>
                        </div>

                    
                            <input required type="text" className="form-control" placeholder="Nome completo" name="name" />
                       

                       
                            <input  type="text" className="form-control" placeholder="Nome do pai" name="pai" />
                       
                            <input type="text" className="form-control" placeholder="Nome da mãe" name="mae" />
                        

                        <div className="text-center">
                            <button
                                disabled={loading}
                                type="submit"
                                className="btn btn-success w-50">
                                {loading ?
                                    <LoadingShort />
                                    :
                                    'Filtrar'
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>


            <br />
            {responseFiltro &&
                <>
                    {responseFiltro.map((filtro) => (
                        <CardAnuncio key={filtro.id} publicacao={filtro} />
                    ))
                    }
                </>
            }
        </>
    )
}
