import { useEffect, useRef, useState, useCallback } from 'react';
import CardAnuncio from '../../components/cards/cardAnuncio';
import CardPublicacao from '../../components/cards/cardPublicacao';
import CardPatrimonioDenunciado from '../../components/cards/cardPatrimonioDenunciado';
import api from '../../services/api';
import { useDispatch } from 'react-redux';
import Loading from '../../load/loading';
import LoadingSkeleton from '../../load/loadingSkeleton';


export default function ControlAnunciosPublicacoes({ perfil, url }) {
    const dispatch = useDispatch();
    const [feedItemsConfig, setIsLoadingConfig] = useState();
    const [feedItems, setFeedItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const feedRef = useRef(null);

    const fetchMoreData = useCallback(async () => {
        setLoading(true);
        dispatch({
            type: 'alert',
            payload: { alert: null },
        });
        try {
            const newData = await fetchData(page);
            setIsLoadingConfig(newData);
            setFeedItems((prevItems) => [...prevItems, ...newData.data]);
            setPage(page + 1);
            setLoading(false);
        } catch (error) {
            // return console.log(error.code)

            if (error.code === "ERR_NETWORK") {
                dispatch({
                    type: 'alert',
                    payload: { alert: { message: 'Neste momento, estás offline.' } },
                });
            }
        }
    }, [page]);

    const fetchData = async (currentPage) => {
        const response = await api.get(`${url}&page=${currentPage}`);
        return response.data.anuncios;
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                fetchMoreData();
            }
        });

        if (feedRef.current) {
            observer.observe(feedRef.current);
        }

        return () => {
            if (feedRef.current) {
                observer.unobserve(feedRef.current);
            }
        };
    }, [feedRef, fetchMoreData]);



    return (
        <div className="card-body row row-cols-1 row-cols-md-4 g-5">
            {(loading && page === 1) ?
                <LoadingSkeleton/>
                :
                <>
                    {feedItems?.map((publicacao, index) => (
                        <div key={index} className="col mb-2 alert p-1">

                                {(publicacao.tipo === 'anuncio' && publicacao.categoria !== 'Denuncia') &&
                                    <CardAnuncio publicacao={publicacao} perfil={perfil} />
                                }
                                {publicacao.tipo === 'publicacao' &&
                                    <CardPublicacao publicacao={publicacao} perfil={perfil} />
                                }
                                {(publicacao.tipo === 'anuncio' && publicacao.categoria === 'Denuncia') &&
                                    <CardPatrimonioDenunciado publicacao={publicacao} perfil={perfil} />
                                }
                        </div>
                    ))}
                </>
            }

            {(feedItemsConfig?.next_page_url || page === 1) ?
                <div ref={feedRef} className="text-center text-success">
                    <Loading />
                    <small>Encontre mais publicações</small>
                </div>
                :
                <p style={{ textAlign: 'center' }}>
                    <small>Sem publicações</small>
                </p>
            }
        </div>
    );
}
