
import MenuLateral from "../../components/menuLateral";
import NavBar from "../../components/navbar";
import Publicidades from "../../components/publicidades";
import LoadingBackdrop from "../../load/loadingBackdrop";
// import LoadingBackdrop from "../../load/loadingBackdrop";
import { useSelector } from 'react-redux';

function Home({ element: component, ...rest }) {
    const user = useSelector(state => state.account.user);

    return (
        <>
            <NavBar />
            {user ?

                <div className="container p-0">
                    <div className="row m-auto ">
                        <div className="col-3 d-none d-md-block p-0 ">
                            <div className="col-2 position-fixed overflow-auto" style={{ height: '88%', maxWidth: '20%', width:370 }}>
                                {/* {{-- #################################################### saber mais sobre o MeEncontre! ############################################ --}} */}
                                <MenuLateral />
                            </div>
                        </div>

                        {/* {{-- ########################################### inicio do feed de noticias ######################################################### --}} */}
                        <div className="col px-0 mx-auto" style={{ maxWidth: '550px' }} >

                            {/* <div className="col mb-2"> */}
                            {component}
                            {/* </div> */}
                        </div>

                        {/* {{-- ################################# area de encontrados. publicidades e exibições de outros conteudos ######################################## --}} */}
                        <div className="col-2 mb-4 d-none d-md-block">
                            <div className="position-sticky" style={{ top: '100px' }}>
                                <Publicidades />
                            </div>
                        </div>
                    </div>
                </div>
                :
                <LoadingBackdrop open={true} />
            }
        </>
    );
}

export default Home;
