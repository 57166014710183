
import { IconButton } from "@mui/material";
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
// import { ExcelFile, ExcelSheet } from 'react-data-export';
// import ReactExport from 'react-data-export';
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


export default function FerramentaNavegacaoExportacao(props) {
    const navigate = useNavigate()

    const handleChangePageNext = () => {
        props.setPage(props.configLista.nextPage)

        // setPagVisto(pagVisto + props?.lista.length)
    };

    const handleChangePagePreviou = () => {
        props.setPage(props.configLista.nextPage - 2)
        // setPagVisto(pagVisto - props.lista.length)
    };

    return (
        <div style={{ padding: 20 }}>
            {/* Página: <strong>{props?.configLista?.previousPage + 1}</strong> */}
            <br />
            Visto <strong>{props.lista.length} </strong> de <strong>{props?.configLista?.total}</strong> no total
            {/* {!props?.configLista?.prev_page_url} */}
            <IconButton disabled type="submiy" onClick={handleChangePagePreviou}>
                <NavigateBeforeIcon />
            </IconButton>

            <IconButton disabled={!props?.configLista?.next_page_url} type="submiy" onClick={handleChangePageNext}>
                <NavigateNextIcon />
            </IconButton>

            <input style={{ padding: 5, width: 100, margin: 5 }} placeholder='Itens' type="number" onChange={(e) => props.setPerPage(e.target.value)} />
            <select defaultValue={'ASC'} style={{ padding: 5, width: 100, margin: 5 }} onChange={(e) => props.setOrder(e.target.value)} id="selectInput">
                <option value="ASC">ASC</option>
                <option value="DESC">DESC</option>
            </select>

            {props.FerramentaNavegacaoExportacao &&
                <>
                    {/* <IconButton type="submiy" onClick={() => ''}>
                        <DescriptionIcon color="action" titleAccess="Exportar para Excel" />
                    </IconButton> */}

                    <IconButton type="submiy" onClick={() => navigate(`/admin/${props.urlLista}`)}>
                        <PrintIcon color="action" titleAccess="Imprimir PDF" />
                    </IconButton>
                </>
            }
        </div>
    )
}
