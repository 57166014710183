

import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import FiltroIcone from '../icones/filtro'
import api from '../services/api';
import { useNavigate } from 'react-router-dom';


export default function EncontrarSomente() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [categorias, setCategoria] = useState([])

    useEffect(() => {
        api.get('/categorias-anuncio')
            .then(res => {
                setCategoria(res.data.categorias);
            }).catch(err => '')
    }, [])

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Filtra">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        // sx={{ ml: 1 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <FiltroIcone />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <h6 className="text-center">Encontre somente:</h6>
                {categorias.map((categoria) => (
                    <MenuItem key={categoria.id} onClick={() => navigate(`/encontrar-somente?p=${categoria.id}`)}> {categoria.categoria}</MenuItem>
                ))}

                <MenuItem key={'Denuncia'}  onClick={() => navigate(`/encontrar-somente?p=1`)}>Patrimônios denunciados</MenuItem>
                {/* <MenuItem> <Link to={'/patrimonios-denunciado?categoria=Denuncia'} className="dropdown-item  btn btn-success"> Patrimônios denunciados </Link></MenuItem> */}
            </Menu>
        </React.Fragment>
    );
}
