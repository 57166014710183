import { useState } from 'react'
import NavConfig from './components/navConfig'
import ConfigConta from './components/configConta';
import ConfigIdioma from './components/configIdioma';
import ConfigNotificacoes from './components/configNotificacoes';
import ConfigPrivacidade from './components/configPrivacidade';
import NavBar from '../../components/navbar';
import Publicidades from '../../components/publicidades';
import RegisterAccess from '../../services/registerAccess';

export default function ConfigUser() {
    const [navSelected, setNavSelected] = useState(0)

    function getContentNav(selected) {
        switch (selected) {
            case 0:
                return <ConfigConta />;
            case 1:
                return <ConfigIdioma />;
            case 2:
                return <ConfigNotificacoes />;
            case 3:
                return <ConfigPrivacidade />;

            default:
                return <ConfigConta />;
        }
    }

    return (
        <>
           <RegisterAccess page={'pagina de configuracoes de usuario'} />
            <NavBar />

            <div className="container d-none d-md-block">
                <div className="row">
                    <div className="col-3 ">
                        <NavConfig setNavSelected={setNavSelected} />
                    </div>

                    <div className="col  border-start" style={{ minHeight: '80vh' }}>
                        {getContentNav(navSelected)}
                    </div>

                    <div className="col-3 px-4">
                        <Publicidades />
                    </div>
                </div>
            </div>

            {/* Configurações em modo responsivo */}
            <div className="container col d-block d-md-none">
                <h5 className="text-center">Configurações</h5>
                <br />
                <ConfigConta />
                <br />
                <ConfigIdioma />
                <br />
                <ConfigNotificacoes />
                <br />
                {/* <ConfigPrivacidade /> */}
            </div>

        </>
    )
}
