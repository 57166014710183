import { combineReducers } from "redux";
// import authService from "../services/authService";
import accountReducer from "./accountReducer";

import alert from "./alertNotificacao";
import viewPublicacao from "./viewPublicacaoReducer";
import publicacoes from "./publicacoesReducer";
import minhasPublicacoes from "./minhasPublicacoesReducer";
import meusPatrimonios from "./meusPatrimoniosReducer";
import patrimoniosDenunciados from "./patrimoniosDenunciadosReducer";
import encontrados from "./encontradosReducer";


const rootReducer = combineReducers({
    account: accountReducer,
    alert: alert,
    publicacoes: publicacoes,
    viewPublicacao: viewPublicacao,
    minhasPublicacoes: minhasPublicacoes,
    meusPatrimonios: meusPatrimonios,
    patrimoniosDenunciados: patrimoniosDenunciados,
    encontrados: encontrados,
})

export default rootReducer 