import { useNavigate } from 'react-router-dom'
import { IconButton } from "@mui/material";
// import { useNavigate } from "react-router-dom";

export default function HeaderSession({ title }) {
    const navigate = useNavigate()

    return (
        <div className='mt-3'>
            <h1 style={{ color: '#355d4d', fontSize: 23, display: 'flex', alignItems:'center' }}>
                <IconButton onClick={() => navigate(-1)}style={{ marginRight: 15, }} title='Voltar'>
                    <img  width={20} src="/icones/setaback1.svg" alt="" />
                </IconButton>
                {title}
            </h1>
            <hr style={{ border: '1px solid #355d4d' }} />
        </div>
    )
}
