import { Card } from '@mui/material';
import PatrimonioIcone from '../icones/patrimonio';

export default function SobrePatrimonio() {
    return (
        <Card style={{ padding: 20 }}>
            <h1 style={{ fontSize: 25 }} >Sobre patrimonios</h1>
            <br />

            <p>
                <strong><PatrimonioIcone /> Patrimônio:</strong> definimos como qualquer bem pessoal que no minimo
                tenha um número de idenficação única.
            </p>
            <p>
                <strong><PatrimonioIcone /> Registar / Registar patrimônio:</strong> funcionalidade que permite os
                usuarios fazerem registro de patrimónios, apenas inserindo o nome do patrimônio no número de identificação (ID)
                e a uma imagem bem visivel do patrimônio.
            </p>



            <br />
            <h1 style={{ fontSize: 20 }} >Tipo de Patrimonio suportado:</h1>

            <p className="m-0">Documentos</p>
            <small>O número de indentificação do documento. Por exemplo o ID para o seu bilhte de identidade é o Próprio número de bilhete.</small>
            <hr className=" bg-success p-0" />

            <p className="m-0">Telefone</p>
            <small>
                O número de IMEI do teu telemóvel. O IMEI é uma sequência única formada pela sequência de 15 números,
                é através do IMEI que é possivel identificar o teu telemóvel. Para ter acesso IMEI do seu smartphone,
                basta digitar <strong>*#06#</strong>
                em seu tectado ou verifica parte trazeira do seu telemóvel ou mesmo na caixa do aparelho.
            </small>
            <hr className=" bg-success p-0" />
            {/* 
            <p className="m-0">Computador</p>
            <small>O número mac do computador é usado como ID</small>
            <hr className=" bg-success p-0" /> */}

            <p className="m-0">Motorizadas e Carros</p>
            <small>O número de matricula do meio é usado como ID</small>




            <br />
            <br />
            <h1 style={{ fontSize: 20 }} >Dicas sobre pesquisa:</h1>

            <p className="m-0">Documentos</p>
            <small>Digite correctamente o número de documento que deseja encontrar</small>
            <hr className=" bg-success p-0" />

            <p className="m-0">Motorizada ou Carro</p>
            <small>Digite correctamente no número de matricula do meio que deseja encontrar</small>
            <hr className=" bg-success p-0" />

            <p className="m-0">Telefone</p>
            <small>Digite correctamente no número de IMEI do telefone que deseja encontrar</small>
            <hr className=" bg-success p-0" />

            {/* <p className="m-0">Computador</p>
            <small>Digite correctamente no número mac do computador que deseja encontrar</small>
            <br /> */}
        </Card>
    )
}
