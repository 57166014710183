import { useEffect, useState } from 'react'
import EliminarIcone from '../../icones/eliminar'
import LoadingShort from '../../load/loadingShort'
import api from '../../services/api'
import { useDispatch } from 'react-redux';
import Loading from '../../load/loading';

export default function CreateCategoriasAnuncio() {
    const dispatch = useDispatch();
    const [categorias, setCategorias] = useState('')
    const [novaCategoria, setNovaCategoria] = useState([])
    const [loading, setLoading] = useState('')

    useEffect(() => {
        api.get('/categorias-anuncio')
            .then(res => {
                setCategorias(res.data.categorias);
            }).catch(err => '')

    }, [])

    const handleInputChange = (e) => {
        setNovaCategoria({ ...novaCategoria, [e.target.name]: e.target.value, });
    };

    const create = (e) => {
        e.preventDefault();
        setLoading('Salvar');

        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        api.post(`/create-categoria-anuncio`, novaCategoria)
            .then(response => {
                // console.log(response)
                setLoading(false);
                setCategorias(response.data.categorias)

                dispatch({
                    type: 'alert',
                    payload: { alert: response.data },
                });

            }).catch(err => {
                // console.log(err)
                setLoading(false);
            })
    }


    const delet = (id) => {
        setLoading(id);
        dispatch({
            type: 'alert',
            payload: { alert: null }
        });

        api.post(`/delete-categoria-anuncio/${id}`)
            .then(response => {
                setLoading(false);
                setCategorias(response.data.categorias)

                dispatch({
                    type: 'alert',
                    payload: { alert: response.data },
                });

            }).catch(err => {
                setLoading(false);
            })
    }

    return (
        <div className="card card-body mb-4">
            <div className="row g-3 m-0" >
                <h4>ADD CATEGORIA DE ANUNCIOS E PUBLICAÇÕES</h4>

                <div className="col-md-6">
                    <input onChange={handleInputChange} required type="text" className="form-control " placeholder="Nova categoria" name="categoria" />
                    <button
                        onClick={create}
                        className="btn btn-success my-2">
                        {loading === 'Salvar'
                            ?
                            <LoadingShort />
                            :
                            'Salvar'
                        }
                    </button>
                </div>


                <div className="col-md-6">
                    <strong>Categorias cadastradas</strong>

                    {categorias ?
                        <ul>
                            {categorias.map((categoria) => (
                                <li key={categoria.id} >
                                    <button onClick={() => delet(categoria.id)} className="btn btn-outline-success border-0 p-0" title="apagar categoria">
                                        {loading === categoria.id
                                            ?
                                            <LoadingShort />
                                            :
                                            <EliminarIcone />
                                        }
                                    </button>
                                    {categoria.categoria}
                                </li>
                            ))}
                        </ul>
                        :
                        <Loading />
                    }
                </div>


            </div>
        </div>
    )
}
