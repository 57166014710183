import { useState, useEffect } from 'react';
import PatrimonioIcone from '../../icones/patrimonio'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import api from '../../services/api';
import { validacaoRegiterPatrimonio } from '../../validacoes';
import MessageError from '../../messages/messageError';
import compressImage from '../../services/compressImage'
import RegisterAccess from '../../services/registerAccess';

export default function RegisterPatrimonio() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [tiposPatrimonio, setTiposPatrimonio] = useState([]);
    const [patrimonio, setPatrimonio] = useState([]);
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')


    useEffect(() => {
        api.get('/patrimonio-tipos')
            .then(response => {
                // console.log(response)
                setTiposPatrimonio(response.data.tiposPatrimonio);
            }).catch(err => '')
    }, []);


    async function HandleChange(e) {
        e.target.files ?
            setPatrimonio({ ...patrimonio, [e.target.name]: await compressImage(e.target.files[0]) })
            :
            setPatrimonio({ ...patrimonio, [e.target.name]: e.target.value })
    }

    const createPatrimonio = () => {
        setLoading(true)

        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        const formData = new FormData();
        formData.append('tipo_id', patrimonio.tipoPatrimonio);
        formData.append('patrimonio_id', patrimonio.patrimonio_id);
        formData.append('nome', patrimonio.nome);
        formData.append('ficheiro', patrimonio.ficheiro);
        // for (let dado of formData.values()) { console.log(dado); }

        api.post(`/create-patrimonio`, formData).then(response => {
            setLoading(false)
            // console.log(response)

            dispatch({
                type: 'alert',
                payload: { alert: response.data },
            });

            navigate('/perfil/meus-patrimonios')
        }).catch(err => {
            setErrorMessage(err.response?.data.message);
            // console.log(err)
            setLoading(false)
        })
    }

    const validate = async e => {
        setErrorMessage('');
        if (!(await valida())) return
    };

    async function valida() {
        try {
            await validacaoRegiterPatrimonio(patrimonio)
            createPatrimonio()
        }
        catch (error) {
            setErrorMessage(error);
        }
    }

    return (
        <>
        <RegisterAccess page={'pagina registar patrimonio'} />
            {/* MESSAGEM DE ERRO  */}
            {errorMessage && <MessageError message={errorMessage} />}

            <div className="modal-content" style={{ minHeight: '90vh' }}>
                <div className="modal-header border-0 my-3">
                    <h5 className="modal-title" id="staticBackdropLabel">
                        <PatrimonioIcone />
                        <strong> Registar patrimônio</strong>
                    </h5>
                    <Link to={'/'} className="btn-close" title="fechar formulário" />
                </div>

                <div className="modal-body">
                    <div className="row g-3">
                        <small className="text-start m-2">Regista seus patrimônios no meencontre, te ajudamos a procurar se o perderes!</small>

                        <div>
                            <select
                                onChange={HandleChange}
                                className="form-select"
                                name="tipoPatrimonio"
                                defaultValue={patrimonio?.tipoPatrimonio}
                            >
                                <option selected disabled>Selecionar tipo de patrimonio</option>
                                {tiposPatrimonio.map((tipoPatrimonio) => (
                                    <option key={tipoPatrimonio.id} value={tipoPatrimonio.id}>{tipoPatrimonio.patrimonio}</option>
                                ))}
                            </select>
                        </div>

                        <div >
                            <input onChange={HandleChange} type="text" name="nome" className="form-control" placeholder="Nome do patrimônio" required />
                        </div>

                        <div >
                            <input onChange={HandleChange} type="text" className="form-control" name="patrimonio_id" placeholder=" ID do patrimônio" required />
                        </div>

                        <small>Adiciona sempre a imagem real do patrimônio</small>
                        <div className="text-center">
                            <label className="form-control text-center" htmlFor="img"
                                style={{ cursor: 'pointer', border: '1px #1f1f1f dashed' }}>

                                {patrimonio?.ficheiro &&
                                    <>
                                        <small> Preview:</small>
                                        <img src={URL.createObjectURL(patrimonio.ficheiro)} width="100%" />
                                    </>
                                }

                                <small>Carregar imagem</small>
                                <input onChange={HandleChange} accept="image/png, image/jpg, image/jpeg" id="img" name="ficheiro" type="file" style={{ display: 'none' }} />
                            </label>
                        </div>

                        <div className="text-center">
                            <button
                                disabled={(loading || !patrimonio?.ficheiro)}
                                onClick={validate}
                                className="w-50 btn btn-success">
                                {loading ?
                                    <small className="spinner-border" role="status" style={{ height: '15px', width: '15px' }}></small>
                                    :
                                    'Registar'
                                }
                            </button>
                        </div>
                    </div>
                </div>

                <Link to={'/sobre-patrimonios'} className="mt-4 text-success btn" >
                    Saiba mais sobre patrimônios.
                </Link>
            </div>

        </>
    )
}
