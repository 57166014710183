import { useEffect, useState } from 'react';
import DateHora from '../utils/timeCalculate'
import EliminarIcone from '../icones/eliminar'
import { useSelector } from 'react-redux';
import LoadingShort from '../load/loadingShort';
import { Avatar } from '@mui/material';
import api from '../services/api';
import Loading from '../load/loading';

export default function ComentariosAutoLoader({ publicacao, setHandlePublicacao }) {
  const user_id = useSelector(state => state.account.user?.id);
  // const [handleComenarios, setHandleComenarios] = useState('');
  const [loading, setLoading] = useState(false);
  const [verMais, setVerMais] = useState(false);
  const [comentariosConfig, setComentariosConfig] = useState('');
  const [comentarios, setComentarios] = useState('');
  const [page, setPage] = useState(1);

  // console.log(comentarios)

  useEffect(() => {
    // setComentarios('')

    api.get(`/comentarios/${publicacao.id}`)
      .then(response => {
        // console.log(response)
        setComentarios(response.data.comentarios.data);
        setComentariosConfig(response.data.comentarios);
        setPage(page + 1)
      }).catch(err => '')
  }, [publicacao]);


  const maisComentarios = () => {
    setVerMais(true)
    api.get(`/comentarios/${publicacao.id}?page=${page}`)
      .then(response => {
        // console.log(response)
        setComentarios((prevItems) => [...prevItems, ...response.data.comentarios.data]);
        setComentariosConfig(response.data.comentarios);
        setPage(page + 1)
        setVerMais(false)
      }).catch(err => setVerMais(false))
  }

  const deleteComentario = (id) => {
    setLoading(id);

    api.post(`/delete-comentario/${id}`)
      .then(response => {
        setLoading(false);
        setHandlePublicacao(response.data.publicacao)

        // remover o comentario eliminado do objecto
        setComentarios(comentarios.filter((comentario) => comentario.id !== id))
      }).catch(err => {
        setLoading(false);
      })
  }

  return (
    <div className="card-body ">
      {comentarios &&
        <>
          {comentarios?.map((comentario, index) => (

            <div key={comentario.id} className='mb-2'>
              <div className="d-flex ">

                <picture className="me-1">
                  {comentario.user?.foto_perfil ?
                    <Avatar src={`https://server.meencontre.com/public/storage/${comentario?.user?.foto_perfil}`} className="img-fluidd-block  rounded-pill  " alt="img" style={{ border: 'solid rgb(2, 71, 0) 1px' }} />
                    :
                    <img src="/img/utilizador.svg" className="img-fluid  rounded-circle" alt="img" style={{ width: '30px' }} />
                  }
                </picture>

                <span className="lh-sm">
                  <strong>{comentario.user?.nome}</strong><br />
                  <small><DateHora date={comentario.created_at} /></small>
                </span>
              </div>

              <div className="d-inline-block mx-4 pb-1">
                {/* {{-- style="background: rgb(231, 231, 231)" --}} */}
                <div className="text-break lh-sm p-2 alert-success rounded text-dark">
                  <small>{comentario.descricao} </small>

                  {(comentario.user?.id == user_id || user_id == publicacao.user_id) &&
                    <button onClick={() => deleteComentario(comentario.id)} className="btn btn-outline border-0 p-0" type="submit" title="apagar comentário">
                      {loading === comentario.id ?
                        <LoadingShort />
                        :
                        <EliminarIcone />
                      }
                    </button>
                  }
                </div>
              </div>
            </div>
          ))}

          {comentariosConfig?.next_page_url ?
            <p onClick={maisComentarios} className="btn text-center text-success">
              <small>Ver mais comentarios</small>
            </p>
            :
            (!comentariosConfig?.next_page_url && comentarios.length > 0) ?
              <div className=" text-center">
                <small>Sem mais comentários</small>
              </div>
              :
              <div className=" text-center">
                <small>Nenhum comentário</small>
              </div>
          }
        </>
      }

      {(!comentarios || verMais) &&<Loading />}
    </div>
  )
}



