import DateHora from "../../utils/timeCalculate";
import { Avatar } from '@mui/material';

export default function ImageHeaderPublicacao({ publicacao, component }) {
    const apiUrl = process.env.REACT_APP_API_URL;

    return (
        <div className="d-inline-flex " style={{ alignItems: 'center' }}>
            <picture className="me-2" >
                {publicacao?.user?.foto_perfil ?
                    <Avatar src={apiUrl + publicacao.user?.foto_perfil} alt="img" style={{ border: 'solid rgb(2, 71, 0) 1px' }} />
                    :
                    <img src="/img/utilizador.svg" alt="img" style={{ width: '40px' }} title="Carrega já imagem de perfil" />
                }
            </picture>

            {publicacao &&
                <div >
                    <p className="lh-sm m-0 text-dark" style={{ display: 'flex', flexDirection: 'column' }}>
                        <strong>{publicacao?.user?.nome}</strong>

                        <small style={{ fontSize: 10 }}>
                            {component === 'notificacoes' ?
                                <DateHora date={publicacao.created_at} />
                                :
                                <DateHora date={publicacao.updated_at} />
                            }
                        </small>
                    </p>
                </div>
            }
        </div>
    )
}
