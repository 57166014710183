import { useEffect, useState } from 'react'
import api from '../../services/api'
import CombineCards from './combineCards'
import { useParams } from 'react-router-dom'

export default function GetPartilhado() {
    const { id } = useParams()
    const [publicacao, setPublicacao] = useState('')

    useEffect(() => {
        api.get(`/anuncio/partilhado/${id}`)
            .then(response => {
                setPublicacao([response.data.anuncio])
                // console.log(response)
            }).catch(err => {
                // console.log(err)
            })
    }, [id])

    return (
        <CombineCards
            publicacoes={publicacao}
            // setHandleUpdate={setHandleUpdate}
            // perfil={perfil}
        />
    )
}
