import React from 'react'

export default function ConfigIdioma() {
    return (
        <div className="container ">
            <h5>Configuração de idioma</h5>
            <hr className=" d-none d-md-block" />
            <div className="p-1">
                <p>PORTUGUES</p>
            </div>
            <hr className="d-block d-md-none" />
        </div>
    )
}
