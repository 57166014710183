import axios from 'axios';
const accessToken = localStorage.getItem("accessToken")

export default axios.create({
    // baseURL: 'https://server.menecontre.com/api/',
    // baseURL: 'https://meencontre.ao/public/api/',
    
    baseURL: 'https://server.meencontre.com/public/api/',
    // baseURL: 'http://127.0.0.1:8000/api/',

    headers: { Authorization: 'Bearer '.concat(accessToken) }
});
 