import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../services/api';
import CombineCards from '../../components/cards/combineCards';
import ListaVazia from '../../utils/listaVazia';
import LoadingShort from '../../load/loadingShort';
import RegisterAccess from '../../services/registerAccess';

export default function EncontrarSomente() {
    const location = useLocation();
    const { search } = location;
    const parametros = new URLSearchParams(search);
    const parametro = parametros.get('p')
    const [results, setResults] = useState('')
    const [config, setConfig] = useState('')
    const [page, setPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    // console.log(parametros)

    useEffect(() => {
        setResults('')

        api.get(`/encontrar-somente/${parametro}`)
            .then(response => {
                // console.log(response)
                setResults(response.data.anuncios.data);
                setConfig(response.data.anuncios);
                setPage(page + 1)
            }).catch(err => 'console.log(err)')
    }, [parametro]);


    const encontrarMais = () => {
        setIsLoading(true)
        api.get(`/encontrar-somente/${parametro}?page=${page}`)
            .then(response => {
                // console.log(response)
                setResults((prevItems) => [...prevItems, ...response.data.anuncios.data]);
                setConfig(response.data.anuncios);
                setPage(page + 1)
                setIsLoading(false)
            }).catch(err => setIsLoading(false))
    }

    return (
        <>
        <RegisterAccess page={'pagina encontrar somente'} />

            <CombineCards
                publicacoes={results}
                // setHandleUpdate={setHandleUpdate}
                perfil={false}
            />

            {config?.next_page_url &&
                <div align='center'>
                    <button onClick={encontrarMais} className='btn btn-success'>
                        {!isLoading ? 'Encontrar mais' : <LoadingShort />}
                    </button>
                </div>
            }

            {(results && results.length < 1) && <ListaVazia text={'Nenhum resultado encontrado'} />}
        </>
    )
}

