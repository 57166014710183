import ParceirosIcone from '../../icones/parceiros'
import RegisterAccess from '../../services/registerAccess'

export default function Parceiros() {
    return (
        <div className="card shadow">
        <RegisterAccess page={'pagina parceiros'} />
            <div className="card-body">
                <h5 className="card-title text-center p-2"><ParceirosIcone /> Nossos Parceiros</h5>
                <p className=" card-text text-justify">
                    Nessa página apresentamos as pessoas singulares, empresas e instituições que trabalham em parceria com meencontre
                    para o desenvolvimento, manuntenção, crescimento e expansão do projecto.
                </p>
            </div>
        </div>
    )
}
