import { useState } from 'react'
import api from '../services/api';
import { useSelector } from 'react-redux';
import LoadingShort from '../load/loadingShort';
import { useDispatch } from 'react-redux';
// import EsperancaIcone from '../icones/esperanca';

export default function CreateEsperanca({ publicacao, setHandlePublicacao }) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const user_id = useSelector(state => state.account.user?.id);

    const createEsperanca = () => {
        setLoading(true);
        dispatch({
            type: 'alert',
            payload: { alert: null },
        });
        api.post(`/create-esperanca`, { publicacao_id: publicacao.id, tipoPublicacao: publicacao.tipo, user_id })
            .then(response => {
                setLoading(false);
                setHandlePublicacao(response.data.publicacao)
                dispatch({
                    type: 'alert',
                    payload: { alert: response.data },
                });
            }).catch(err => {
                setLoading(false);
            })
    }

    return (
        <button
            // disabled={loading}
            onClick={() => createEsperanca()}
            className="btn-outline-success btn-sm border-0 w-100"
        >
            {loading ?
                <>
                    {/* <EsperancaIcone /> */}
                    <LoadingShort />
                </>
                :
                <>
                    {/* <EsperancaIcone />  */}
                    Enviar esperanças
                </>
            }
        </button>
    )
}
