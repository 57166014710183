import CreatePublicacao from '../../components/cards/createPublicacao'
import GetAnunciosPublicacoes from '../../components/cards/get-anuncios-publicacoes'
import { useState } from 'react';
import Auth from '../../services/auth/auth';
import Guest from '../../services/auth/guest';
import BotaoCriarConta from '../../components/botaoCriarConta';
import RegisterAccess from '../../services/registerAccess';
// import GetEncontrados from '../../components/cards/encontrados/getEncontrados';


export default function Feed() {
    const [handleUpdate, setHandleUpdate] = useState(1);

    return (
        <>
            <RegisterAccess page={'pagina inicial'} />
           
            <Guest>
                <div className="card-body d-block d-md-none" style={{ marginTop: -39 }}>
                    <BotaoCriarConta />
                </div>
            </Guest>

            <Auth>
                <CreatePublicacao setHandleUpdate={setHandleUpdate} />
            </Auth>

            {/* {{-- CAROUSSEL --}} */}
            {/* <div className="d-block d-md-none">
                <GetEncontrados />
            </div> */}

            <GetAnunciosPublicacoes handleUpdate={handleUpdate} url={'/anuncios'} />
        </>
    )
}
