// import { useNavigate } from "react-router-dom";
import DateHora from "../../utils/dateHora";
import { useState } from "react";
import api from "../../services/api";
import LoadingShort from "../../load/loadingShort";
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import HeaderConfigs from "../../utils/headerConfigs";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

export default function CardPatrimonio({ item, meusPatrimonios, setMeusPatrimonios }) {
    const apiUrl = process.env.REACT_APP_API_URL;
    const dispatch = useDispatch();
    const [patrimonio, setPatrimonio] = useState(item)
    const [denuncia, setDenuncia] = useState()
    const [loading, setLoading] = useState()
    // const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [openEliminar, setOpenEliminar] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickOpenEliminar = () => {
        setOpenEliminar(true);
    };
    const handleClose = () => {
        setOpen(false);
        setOpenEliminar(false);
    };

    const handleInputChange = (e) => {
        setDenuncia({ ...denuncia, [e.target.name]: e.target.value, });
        autoResize()
    };

    const createDenucia = (e) => {
        e.preventDefault();
        setLoading(true);

        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        api.post(`/perfil/create-denuncia`, {
            ...denuncia,
            id: patrimonio.id
        }).then(response => {
            setPatrimonio(response.data.patrimonio)
            setLoading(false);
            handleClose()
            // console.log(response)

            dispatch({
                type: 'alert',
                payload: { alert: response.data },
            });
        }).catch(err => {
            setLoading(false);
            // console.log(err)
        })
    }

    const retirarDenucia = (e) => {
        e.preventDefault();
        setLoading(true);

        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        api.post(`/perfil/retirar-denuncia`, {
            ...denuncia,
            id: patrimonio.id
        }).then(response => {
            setPatrimonio(response.data.patrimonio)
            setLoading(false);
            handleClose()
            // console.log(response)

            dispatch({
                type: 'alert',
                payload: { alert: response.data },
            });

            window.location.reload()
        }).catch(err => {
            // console.log(err)
            setLoading(false);
        })
    }

    const deletePatrimonio = (e) => {
        e.preventDefault();
        setLoading(true);

        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        api.post(`/perfil/delete-patrimonio/${patrimonio.id}`)
            .then(response => {
                setPatrimonio(response.data.patrimonio)
                setLoading(false);
                handleClose()
                setMeusPatrimonios(meusPatrimonios.filter((meuPatrimonio) => meuPatrimonio.id !== patrimonio.id))

                dispatch({
                    type: 'alert',
                    payload: { alert: response.data },
                });
                window.location.reload()
                // console.log(response)
            }).catch(err => {
                setLoading(false);
                // console.log(err)
            })
    }

    function autoResize() {
        const textarea = document.getElementById('DenunciaTextarea');
        // textarea.style.height = 'auto'; // Resetar altura para recalculá-la
        textarea.style.height = textarea.style.height = textarea.style.height; // Resetar altura para recalculá-la
        textarea.style.height = textarea.scrollHeight + 'px'; // Ajustar altura conforme o conteúdo
    }


    return (
        <div className="card mb-3">

            <div className="card-body d-flex" >
                <div className="card-body py-0" style={{ flexGrow: 1 }} >
                    <p className="m-0">
                        <strong>
                            {patrimonio?.nome}
                            <br />
                            {patrimonio?.patrimonio_id}
                        </strong>
                    </p>
                    <small className=" text-center lh-sm">
                        Data de resgisto:  <DateHora date={patrimonio?.created_at} /><br />
                    </small>
                </div>

                <HeaderConfigs
                    configArea={'patrimonio'}
                    patrimonio={patrimonio}
                    handleClickOpen={handleClickOpen}
                    handleClickOpenEliminar={handleClickOpenEliminar}
                />
            </div>

            {patrimonio?.denuncia == false ?
                <>
                    {/* DENUNCIA */}
                    <BootstrapDialog
                        scroll='body'
                        fullWidth
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                        style={{ margin: -30 }}
                    >
                        <form onSubmit={createDenucia} className="card border-0">
                            <div className="modal-content" style={{ minHeight: '100vh' }}>
                                <div className="modal-header border-0 alert-success">
                                    <h5 className="modal-title" id="staticBackdropLabel">Denuncia de patrimônio</h5>
                                    <button onClick={handleClose} type="button" className="btn-close"></button>
                                </div>

                                <div className="col modal-body">
                                    <p>Para garantia de melhores resultados é importante que faças uma
                                        descrição
                                        (legenda)
                                        sobre esta denuncia. Assim outras pessoas poderão entender o que
                                        voçê
                                        realmente deseja.</p>
                                    <textarea
                                    id="DenunciaTextarea"
                                        onChange={handleInputChange}
                                        className="form-control"
                                        name="descricao"
                                        rows="2"
                                        placeholder="Descrever"
                                        required>
                                    </textarea>
                                </div>

                                <div className=" m-2 text-center">
                                    <button
                                        disabled={loading}
                                        type="submit"
                                        className="btn btn-success w-50">
                                        {loading ?
                                            <LoadingShort />
                                            :
                                            'Denunciar'
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </BootstrapDialog>
                </>
                :
                // RETIRAR DENUNCIA
                <>
                    <BootstrapDialog
                        scroll='body'
                        fullWidth
                        onClose={handleClose}
                        aria-labelledby="customized-dialog-title"
                        open={open}
                    >
                        <form onSubmit={retirarDenucia} className="card border-0" method="post">
                            <div className="modal-content">
                                <div className="modal-header border-0 alert-success">
                                    <h5 className="modal-title" id="staticBackdropLabel">Retirar denuncia</h5>
                                    <button onClick={handleClose} type="button" className="btn-close" ></button>
                                </div>
                                <div className="col modal-body">
                                    <p>Ao retirar uma denuncia nós deduzimos que o patriomônio em causa foi
                                        encontrado.</p>
                                    <p>Deixa uma pequena mensagem para todos que ajudaram neste processo.
                                    </p>
                                    <textarea onChange={handleInputChange} className="form-control" id="message-text" name="descricao" rows="5" required></textarea>
                                </div>

                                <div className="text-center border-0 mb-2">
                                    <button
                                        disabled={loading}
                                        type="submit"
                                        className="w-50 btn btn-sm btn-success mx-3">
                                        {loading ?
                                            <LoadingShort />
                                            :
                                            'Salvar'
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    </BootstrapDialog>
                </>
            }

            {/* ELIMINAR₹PATRIMONIO */}
            <BootstrapDialog
                scroll='body'
                fullWidth
                onClose={handleClose}
                // aria-labelledby="customized-dialog-title"
                open={openEliminar}
            >
                <form onSubmit={deletePatrimonio} className="card border-0" method="post">
                    <div className="modal-header border-0 alert-success p-2">
                        <h5 className="modal-title" id="staticBackdropLabel">Eliminar patrimonio?</h5>
                        <button onClick={handleClose} type="button" className="btn-close"></button>
                    </div>
                    <div className="modal-body text-start">
                        Tem serteza que queres eliminar este patrimonio?
                    </div>
                    <div className="mx-auto modal-footer  border-0">
                        <button onClick={handleClose} type="button" className="btn btn-sm mx-3" >Cancelar</button>
                        <button
                            disabled={loading}
                            type="submit"
                            className="btn btn-sm btn-danger mx-3">
                            {loading ?
                                <LoadingShort />
                                :
                                'Eliminar'
                            }
                        </button>
                    </div>
                </form>
            </BootstrapDialog>


            {/* {-- imagem ou video sobre o patrimonio --} */}
            {patrimonio?.denuncia == true ?
                // <Link to="">
                <img src={apiUrl+patrimonio?.ficheiro} alt="img" className="card-img img-fluid" />
                // </Link>
                :
                <img src={apiUrl+patrimonio?.ficheiro} alt="img" className="card-img img-fluid" />
            }
        </div>
    )
}
