
import ContactosTermosPrivacidade from '../../components/contactos-termosPrivacidade'
import Definicoes from '../../components/definicoesConceitos'
import RegisterAccess from '../../services/registerAccess'

export default function SobreMeencontre() {
    return (
        <div className="card card-body" style={{ textAlign: 'justify' }}>
        <RegisterAccess page={'pagina sobre meencontre'} />

            <h4 className="text-center">Saiba mais sobre meencontre</h4>
            <br />
            <Definicoes />

            <div className="accordion-body">
                <h5>O que é meencontre</h5>
                meencontre: É um website/aplicação que conecta pessoas para ações conjuntas de procurar e divulgar algo ou
                alguém
                que esteja
                desaparecido. Também possibilita o usuario fazer registo de vários bens pessoal denominado como patrimônio,
                meencontre te ajuda a protege-los e procurar em caso de perda ou roubo
            </div>
            {/* {{-- ############################################## Finalidade ############################################ --}} */}
            <div className="accordion-body">
                <h5>Finalidade</h5>
                Tem como finalidade ajudar no processo de procura e divulgação de pessoas desaparecidas, documentos perdidos e
                também pacientes,
                falecidos e reclusos que sem paradeiro que se encontram em instituições. Também servir de base de dados para o
                usuários
                onde literalmente podem registrar/guardar seus patrimônios "bem pessoal" garantindo titulo de propriedade sobre
                os
                mesmos.
            </div>
            {/* {{-- ############################################## Objectivos ############################################ --}} */}
            <div className="accordion-body">
                <h5>Objectivos</h5>
                meencontre foi desenvolvido com o objetivo de dinamizar o processo de procura e divulgação pessoas e bens
                desaparecidos proporcionando interação entre pessoas de lugares diferentes para ações conjuntas na resoluçao
                destes
                problemas.
            </div>
            {/* {{-- ############################################## Quem pode usar ############################################ --}} */}
            <div className="accordion-body">
                <h5>Quem pode usar</h5>
                Usuários não cadastrados são permitidos acessar o site e/ou Aplicações meencontre com restrição a algumas
                funcionalidades podendo apenas ver conteúdos da pagina inicial e saber mais sobre o projecto meencontre.
                <br />
                <br />
                Para utilizar todas as funcionalidades e recursos do site e/ou Aplicações meencontre, você precisará realizar um
                cadastro,
                nos termos da nossa Política de Privacidade. Nessa oportunidade, solicitamos o fornecimento de alguns dados
                pessoais
                seus,
                tais como nome, endereço, idade e endereço de e-mail, dentre outros.
            </div>
            {/* {{-- ############################################## Detalhes sobre o Projeto ############################################ --}} */}
            <div className="accordion-body">
                <h5>Detalhes sobre o Projeto</h5>
                O Projeto, pretende promover reencontros felizes entre mães e filhos separados pelos mais diversos motivos,
                reunir
                famílias,
                filhos que não conhecem suas mães, irmãos separados pela vida, idosos perdidos, reclusos sem paradeiro em
                cadeias,
                pacientes
                e falecidos sem paradeiros em unidades sanitárias etc.
                <br />
                <br />
                É uma inciativa de implementação de mais um recurso que pode dar um grande suporte na tarefa ardua de procurar 
                pessoas e bens desaparecidos.
                Com o objetivo de reunir e partilhar informações sobre o que se pretende encontrar, pode ser a melhor chance
                já que de forma virtual as informações podem ser divulgadas por todo o país e até no exterior de forma muito
                rápida, aumentando as possibilidades de sucesso.
                <br />
                <br />
                Acreditamos que a implementação de uma plataforma digital com estas funcionalidades e por outro a adesão em
                massa de
                usúarios,
                com certeza que vamos trazer uma nova dinamica e um novo rumo a sociedade no tange a soluções para encontrar
                pessoas
                e bens
                desaparecidos assim como aumentar a segurança dos nossos patrimônios.
            </div>

            <ContactosTermosPrivacidade />
        </div>
    )
}
