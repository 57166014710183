
import Auth from '../../services/auth/auth'
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import ImageHeaderPublicacao from '../imageUser/imageHeaderPublicacao';
import api from '../../services/api';
import { useState } from 'react';
// import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HeaderConfigs from '../../utils/headerConfigs';


export default function HeaderCardInformacaoUser({ publicacao }) {
    const user_id = useSelector(state => state.account.user?.id);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    // const url = useLocation();

    const SalvePublicacao = () => {
        setLoading(true);
        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        api.post(`/salve-publicacao`, {
            user_id,
            anuncios_publicacoes_id: publicacao?.id,
        }).then(response => {
            // console.log(response)
            setLoading(false);

            dispatch({
                type: 'alert',
                payload: { alert: response.data },
            });
        }).catch(err => {
            // console.log(err)
            setLoading(false);
        })
    }

    return (
        <>
            <div className="d-flex my-2">
                <div style={{ flexGrow: 1 }}>
                    <ImageHeaderPublicacao publicacao={publicacao} />
                </div>

                {/* {-- #################### Guardar ###################### --} */}
                <Auth>
                    <HeaderConfigs
                        configArea={'publicacao'}
                        SalvePublicacao={SalvePublicacao}
                        publicacao={publicacao}
                        loading={loading}
                    />
                </Auth>
                <IconButton data-bs-dismiss="alert" aria-label="Close"><CloseIcon /></IconButton>
            </div>

            {publicacao?.categoria?.id !== 2 &&
                <h5 className="card-title mt-3">{publicacao?.categoria?.categoria}</h5>
            }
        </ >
    )
}
