import { useEffect, useState } from 'react'
import Loading from '../../load/loading'
import api from '../../services/api'
import ImageHeaderPublicacao from '../imageUser/imageHeaderPublicacao'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

export default function GetNotificacoes({ setCountNotificacoes }) {
    const dispatch = useDispatch();
    const [notificacoes, setNotificacoes] = useState('')

    useEffect(() => {
        api.get(`/notificacoes`)
            .then(response => {
                // console.log(response)
                setNotificacoes(response.data.notificacoes)
                setCountNotificacoes && setCountNotificacoes(response.data.notificacoes.filter((notificacoes) => notificacoes.status == false))
            }).catch(err => {
                // console.log(err)
            })
    }, [])


    const viewPublicacao = (publicacao) => {
        dispatch({
            type: 'viewPublicacao',
            payload: { viewPublicacao: publicacao },
        });
        // navigate(`/full-view/${publicacao?.id}`)
    }

    return (
        <div>
            {notificacoes ?
                <>
                    {notificacoes.map((notificacao) => (
                        <div key={notificacao.id}>
                            {notificacao.tipo == 'comentario' &&
                                <div className="card m-1">
                                    <Link onClick={() => viewPublicacao(notificacao.anuncios_publicacoes)} to={`/full-view/${notificacao.anuncios_publicacoes_id}`} className=" text-decoration-none">
                                        <div className="card-body border-0 alert-success py-1 text-break">
                                            <ImageHeaderPublicacao publicacao={notificacao} component={'notificacoes'}/> <br />
                                            <p className='m-0'> Comentou na tua publicação, {notificacao?.publicacao?.categoria?.categoria} </p>
                                            <small> {notificacao?.comentarios?.descricao.slice(0, 100) + '...'} </small>
                                        </div>
                                    </Link>
                                </div>
                            }

                            {notificacao.tipo == 'esperanca' &&
                                <div className="card m-1">
                                    <Link onClick={() => viewPublicacao(notificacao.anuncios_publicacoes)} to={`/full-view/${notificacao.anuncios_publicacoes_id}`} className=" text-decoration-none">
                                        <div className="card-body border-0 alert-success py-1 text-break ">
                                            <ImageHeaderPublicacao publicacao={notificacao} component={'notificacoes'}/><br />
                                            <p> Enviou esperanças no teu anuncio sobre {notificacao?.anuncios_publicacoes?.categoria?.categoria}, {notificacao?.anuncios_publicacoes?.nome}</p>
                                        </div>
                                    </Link>
                                </div>
                            }

                            {notificacao.tipo == 'transferencia' &&
                                <div className="card m-1">
                                    <div className="card-body border-0 alert-success py-1 text-break">
                                        <ImageHeaderPublicacao publicacao={notificacao} component={'notificacoes'}/> <br />
                                        <small> Transferiu um patrimonio para voçê:
                                            {notificacao?.patrimonio?.nome}
                                            ID_{notificacao?.patrimonio?.patrimonio_id}
                                        </small>
                                    </div>
                                </div>
                            }
                        </div>
                    ))}

                    {notificacoes.length < 1 &&
                        <p className="text-center"><small>Nenhuma notificação encontrada.</small></p>
                    }
                </>
                :
                <Loading />
            }
        </div>
    )
}
