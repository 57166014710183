
import NavBar from '../components/navbar'
import Header from './header'
import ContactosTermosPrivacidade from '../components/contactos-termosPrivacidade'
import PatrimonioDenunciadoIcone from '../icones/patrimonioDenunciado'
import MinhasDenuncias from './components/minhasDenuncias'
import { useSelector } from 'react-redux';
import LoadingBackdrop from '../load/loadingBackdrop'
import { useEffect, useState } from 'react'
import api from '../services/api'
import RegisterAccess from '../services/registerAccess'


export default function MeuPefil({ element: component, }) {
    const messageState = useSelector(state => state.alert.data?.message);
    const user = useSelector(state => state.account.user);
    const [estatistica, setEstatistica] = useState('');

    useEffect(() => {
        api.get(`/perfil/estatistica`)
            .then(response => {
                // console.log(response)
                setEstatistica(response.data)
                // setIsLoading(false);
            }).catch(err => {
                // console.log(err)
            })
    }, [messageState]) //APROVEITANDO A ACTUALIZACAO DO MESSAGE STATE PARA ACTUALIZAR O COMPONENTE DINAMICAMENTE

    return (
        <div >
           <RegisterAccess page={'pagina de perfil de usuario'} />
            <NavBar />
            {user ?

                <div className="container" style={{ marginTop: '-25px' }}>
                    {/* {{-- HEADER PERFILUSER --}} */}
                    <Header estatistica={estatistica} />

                    <div className="row ">
                        <div className="col-4 d-none d-md-block">
                            <div className="mx-2 text-start  position-sticky" style={{ top: '100px' }}>

                                <div className="card border-0">
                                    <p className="text-center mt-2">
                                        <small className="text-success">
                                            <PatrimonioDenunciadoIcone /> Minhas denuncias
                                        </small>
                                        <br />
                                    </p>
                                    <MinhasDenuncias />
                                </div>

                                {/* 
                                <div className="card card-body mt-2">
                                </div> */}

                                <ContactosTermosPrivacidade />
                            </div>
                        </div>

                        <div className="col-5 card-body pt-0 mx-auto" style={{ maxWidth: '600px' }}>

                            {/* {{-- ########################################### actividades de meu perfil ######################################################### --}} */}
                            {component}
                        </div>
                    </div>
                </div>
                :
                <LoadingBackdrop open={true} />
            }
        </div>
    )
}
