import { useState } from 'react'
import Auth from '../../services/auth/auth'
import CreateComentario from '../createComentario'
import CreateEsperanca from '../createEsperanaca'
import DateHora from '../../utils/timeCalculate'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import HeaderCardPerfilUser from '../header/headerCard-perfilUser'
import HeaderCardInformacaoUser from '../header/headerCard-informacaoUser'
import TextoFormatado from '../../utils/textoFormatado'
import MidiaAnuncio from '../../utils/midiaAnuncio'
import CountEsperancasComentarios from '../../utils/countEsperancasComentarios'
// import Comentarios from '../comentarios'

export default function CardPatrimonioDenunciado({ publicacao, perfil }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [handlePublicacao, setHandlePublicacao] = useState(publicacao)

    const viewPublicacao = (publicacao) => {
        dispatch({
            type: 'viewPublicacao',
            payload: { viewPublicacao: publicacao },
        });

        navigate(`/full-view/${publicacao?.id}`)
    }

    return (
        <div className='card'>
            <div className="card-body">
                {perfil ?
                    <HeaderCardPerfilUser publicacao={publicacao} />
                    :
                    <HeaderCardInformacaoUser publicacao={publicacao} />
                }

                <p className="m-0 lh-sm">
                    {publicacao.nome}
                    <br />
                    {publicacao.patrimonio_id}
                </p>
                <small className=" text-center lh-sm">
                    Denunciado <DateHora date={publicacao.created_at} />
                </small>
            </div>

            {/* {-- imagem ou video  --} */}
            <MidiaAnuncio publicacao={publicacao} viewPublicacao={viewPublicacao} />

            <div className="card-body">
                <TextoFormatado texto={publicacao.descricao}/>
            </div>

            <CountEsperancasComentarios publicacao={handlePublicacao} />

            <Auth>
                <div className="card-body">
                    <CreateEsperanca
                        publicacao={publicacao}
                        setHandlePublicacao={setHandlePublicacao}
                    />

                    <CreateComentario
                        publicacao={publicacao}
                        setHandlePublicacao={setHandlePublicacao}
                    />
                    {/* <Comentarios
                        publicacao={handlePublicacao}
                        setHandlePublicacao={setHandlePublicacao}
                    /> */}
                </div>
            </Auth>
        </div>
    )
}
