import ApoiosIcone from '../icones/apoios'
import ParceirosIcone from '../icones/parceiros'
import AgradecimentosIcone from '../icones/agradecimentos'
import DesenvolvedorIcone from '../icones/desenvolvedor'
import SabermaisIcone from '../icones/sabermais'
import BotaoCriarConta from './botaoCriarConta'
import ContactosTermosPrivacidade from './contactos-termosPrivacidade'
import ImagePerfil from './imageUser/imagePerfil'
import PesquisarAnuncio from './formSearch'
import Auth from '../services/auth/auth'
import { Link } from 'react-router-dom'
import UsuarioLogado from './usuarioLogado'
import Guest from '../services/auth/guest'

export default function MenuLateral() {
    return (
        <div className="card-body pt-0">

            <Auth>
                <PesquisarAnuncio />
            </Auth>

            <div>
                <Auth>
                    <div className="my-4">
                        <Link className="text-decoration-none" to={'/perfil/minhas-publicacoes'}>
                            <ImagePerfil nome={true} />
                        </Link>
                        <hr className=" bg-success" />
                    </div>

                    <UsuarioLogado />
                </Auth>

                <Guest>
                    <div className='card-body m-0'>
                        <BotaoCriarConta />
                    </div>
                </Guest>

                <Link to={'/apoios'} className="dropdown-item  btn btn-success my-2">
                    <ApoiosIcone />
                    Apoios
                </Link>

                <Link to={'/parceiros'} className="dropdown-item  btn btn-success my-2">
                    <ParceirosIcone />
                    Parceiros
                </Link>

                <Link to={'/agradecimentos'} className="dropdown-item  btn btn-success my-2">
                    <AgradecimentosIcone />
                    Agradecimentos
                </Link>

                <Link to={'/'} target="blank" className="disabled dropdown-item  btn btn-success">
                    <DesenvolvedorIcone />
                    Desenvolvedor
                </Link>

                <hr className="bg-success" />

                <Link to={'/sobre-meenconte'} className="dropdown-item  btn btn-success" >
                    <h6>
                        <SabermaisIcone />
                        Saber mais
                    </h6>
                </Link>
            </div>

            <ContactosTermosPrivacidade />
        </div>
    )
}
