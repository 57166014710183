
import TerminarsessaoIcone from '../icones/terminarsessao';
import { useDispatch } from 'react-redux';
import { signOut } from '../actions/accountActions';
import { useNavigate } from 'react-router-dom'

export default function TerminarSessao({ setIsLoading }) {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const handleLogout = async () => {
        setIsLoading(true)
        try {
            await dispatch(signOut());
            setIsLoading(false)
            navigate("/")
            window.location.reload()
            // console.log('Logout realizado com sucesso');
            // Lógica adicional, como redirecionamento ou atualização de estado
        } catch (error) {
            setIsLoading(false)
            console.error('Erro ao fazer logout:', error);
        }
    };

    return (
        <button onClick={handleLogout} className="dropdown-item " >
            <TerminarsessaoIcone />
            <span>Terminar sessção</span>
        </button>
    )
}




// import authService from '../services/authService';
// import TerminarsessaoIcone from '../icones/terminarsessao';
// import { useState } from 'react';
// import CustomLoadingMeencontre from '../load/custonLoadingMeencontre';

// export default function TerminarSessao() {
//     const [isLoading, setIsLoading] = useState(false)

//     const handleLogout = async () => {
//         setIsLoading(true)
//         try {
//             await authService.logout();
//             setIsLoading(false)
//             console.log('Logout realizado com sucesso');

//             // Lógica adicional, como redirecionamento ou atualização de estado
//         } catch (error) {
//             setIsLoading(false)
//             console.error('Erro ao fazer logout:', error);
//         }
//     };

//     return (
//         <>
//             {/* {isLoading &&
//                 <CustomLoadingMeencontre />
//             } */}
//             <button onClick={handleLogout} className="dropdown-item" >
//                 <TerminarsessaoIcone />
//                 <span>Terminar sessção</span>
//             </button>
//         </>
//     )
// }
