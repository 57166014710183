import { useEffect, useState } from 'react'
import api from '../../services/api';
import { useSelector } from 'react-redux';
import RecebeuIcone from '../../icones/recebeu';
import TransferiuIcone from '../../icones/transferiu';
import GetUser from './getUser';
import DateHora from '../../utils/dateHora';
import LoadingSkeleton from '../../load/loadingSkeleton';
import LoadingShort from '../../load/loadingShort';
import RegisterAccess from '../../services/registerAccess';

export default function HistoricoTransferencias() {
    // const [loading, setLoading] = useState(false);
    const [historicos, setHistorico] = useState('')
    const [config, setConfig] = useState('')
    const user_id = useSelector(state => state.account.user?.id);
    const [page, setPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        api.get(`/perfil/historico-transferencia`)
            .then(response => {
                // console.log(response)
                setHistorico(response.data.historicos.data)
                setConfig(response.data.historicos)
            }).catch(err => {
                // console.log(err)
            })
    }, [])


    const encontrarMais = () => {
        setIsLoading(true)
        api.get(`/perfil/historico-transferencia?page=${page}`)
            .then(response => {
                // console.log(response)
                setHistorico((prevItems) => [...prevItems, ...response.data.historicos.data]);
                setConfig(response.data.anuncios);
                setPage(page + 1)
                setIsLoading(false)
            }).catch(err => setIsLoading(false))
    }


    return (
        <div className="container">
            <RegisterAccess page={'pagina historico de transferencias'} />

            <h5 className="text-center" >Histórico de transferências de patrimônios</h5>

            {historicos ?
                <>
                    {historicos?.map((historico) => (
                        <div key={historico.id} className="mb-3 card card-body">

                            {historico.user_origem == user_id ?
                                <div className='d-flex'>
                                    <TransferiuIcone /> Transferiu
                                </div>
                                :
                                <div className='d-flex'>
                                    <RecebeuIcone />Recebeu
                                </div>
                            }

                            <img className="img-fluidd-block w-100" src={`data:image/jpeg;base64,${historico.patrimonios?.ficheiro}`} alt="" />

                            <div className="mt-2">
                                <p className="m-0">
                                    <strong>{historico.patrimonios.nome}</strong>
                                </p>

                                <p className="m-0">
                                    <strong>ID: </strong>
                                    {historico.patrimonios.patrimonio_id}
                                </p>

                                <p className="m-0">
                                    <strong>Data de resisto: </strong>
                                    <DateHora date={historico.patrimonios.created_at} />
                                </p>

                                {historico.user_origem == user_id ?
                                    <>
                                        <p className="m-0">
                                            <strong>Data da transferência: </strong>
                                            <DateHora date={historico.patrimonios.updated_at} />
                                        </p>
                                        <strong>Destinatário: </strong>
                                        <GetUser id={historico.user_destinatario} />
                                    </>
                                    :
                                    <>
                                        <p className="m-0">
                                            <strong>Data da recepção em: </strong>
                                            <DateHora date={historico.patrimonios.updated_at} />
                                        </p>

                                        <p className="m-0">
                                            <strong>Origem: </strong>
                                            <GetUser id={historico.user_origem} />
                                        </p>
                                    </>
                                }
                            </div>
                        </div>
                    ))}


                    {config?.next_page_url &&
                        <div align='center'>
                            <button onClick={encontrarMais} className='btn btn-success'>
                                {!isLoading ? 'Encontrar mais' : <LoadingShort />}
                            </button>
                        </div>
                    }

                    {historicos.length < 1 && <p className="text-center">Nenhum Histórico encontrado</p>}
                </>
                :
                <LoadingSkeleton />
            }
        </div>
    )
}
