
import CreatePublicacao from '../../components/cards/createPublicacao'
import { useEffect, useRef, useState, useCallback } from 'react';
import LoadingSkeleton from '../../load/loadingSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../load/loading';
import CombineCards from '../../components/cards/combineCards';
import api from '../../services/api';
import RegisterAccess from '../../services/registerAccess';

export default function MinhasPublicacoes() {
    const [handleUpdate, setHandleUpdate] = useState('');
    const dispatch = useDispatch();
    const [feedItemsConfig, setFeedItemsConfig] = useState(useSelector(state => state?.minhasPublicacoes?.config));
    const [feedItems, setFeedItems] = useState(useSelector(state => state?.minhasPublicacoes?.data) ?? '');
    const [page, setPage] = useState(useSelector(state => state?.minhasPublicacoes?.data !== null ? state?.minhasPublicacoes?.config?.current_page + 1 : 1));
    const feedRef = useRef(null);

    useEffect(() => {
        dispatch({
            type: 'viewPublicacao',
            payload: { viewPublicacao: null },
        }); //dispatch para limpar a viewPublicacao que esta state da aplicação

        dispatch({
            type: 'minhasPublicacoes',
            payload: { minhasPublicacoes: feedItems, config: feedItemsConfig },
        }); //dispatch para por as publicacoes no state da aplicacao e evitar recarregar sempre retornas para p feed

    }, [page])

    const fetchMoreData = useCallback(async () => {
        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        try {
            const newData = await fetchData(page);
            setFeedItemsConfig(newData);
            setFeedItems((prevItems) => [...prevItems, ...newData.data]);

            setPage(page + 1);
        } catch (error) {
            // return console.log(error.code)

            if (error.code === "ERR_NETWORK") {
                dispatch({
                    type: 'alert',
                    payload: { alert: { message: 'Neste momento, estás offline.' } },
                });
            }
        }
    }, [page, handleUpdate]); // A função depende apenas de page e handleUpdate, que é estável entre renderizações

    const fetchData = async (currentPage) => {
        const response = await api.get(`/perfil/meus-anuncios?page=${currentPage}`);
        return response.data.anuncios;
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                fetchMoreData();
            }
        });

        if (feedRef.current) {
            observer.observe(feedRef.current);
        }

        return () => {
            if (feedRef.current) {
                observer.unobserve(feedRef.current);
            }
        };
    }, [feedRef, fetchMoreData, handleUpdate]);


    return (
        <>
           <RegisterAccess page={'pagina minhas publicacoes'} />
            <CreatePublicacao setHandleUpdate={setHandleUpdate} />

            {(page === 1) &&
                <div ref={feedRef} >
                    <LoadingSkeleton />
                </div>
            }

            <CombineCards
                publicacoes={feedItems}
                setHandleUpdate={setHandleUpdate}
                perfil={true}
            />

            {(feedItemsConfig?.next_page_url) &&
                <div ref={feedRef} className="text-center text-success">
                    <Loading />
                    <small>Encontre mais publicações</small>
                </div>
            }
            
            {(feedItems && !feedItemsConfig?.next_page_url) &&
                <p style={{ textAlign: 'center' }}>
                    <small>Sem publicações</small>
                </p>
            }
        </>
    )
}
