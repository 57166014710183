import PatrimonioDenunciadoIcone from '../../icones/patrimonioDenunciado'
import CardPatrimonio from '../../components/cards/cardPatrimonio';
import PatrimonioIcone from '../../icones/patrimonio';
import { Link } from 'react-router-dom';
import MinhasDenuncias from './minhasDenuncias';
import LoadingSkeleton from '../../load/loadingSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState, useCallback } from 'react';
import api from '../../services/api';
import Loading from '../../load/loading';
import RegisterAccess from '../../services/registerAccess';


export default function MeusPatrimonios() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [config, setConfig] = useState(useSelector(state => state.meusPatrimonios?.config));
    const [meusPatrimonios, setMeusPatrimonios] = useState(useSelector(state => state.meusPatrimonios?.data) ?? '');
    const [page, setPage] = useState(useSelector(state => state.meusPatrimonios?.data !== null ? state.meusPatrimonios?.config?.current_page + 1 : 1));
    const feedRef = useRef(null);

    useEffect(() => {
        dispatch({
            type: 'viewPublicacao',
            payload: { viewPublicacao: null },
        }); //dispatch para limpar a viewPublicacao que esta state da aplicação

        dispatch({
            type: 'meusPatrimonios',
            payload: { meusPatrimonios: meusPatrimonios, config: config },
        });//dispatch para por as publicacoes no state da aplicacao e evitar recarregar sempre retornas para p feed

    }, [page])

    const fetchMoreData = useCallback(async () => {
        setLoading(true)
        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        try {
            const newData = await fetchData(page);
            setConfig(newData);
            setMeusPatrimonios((prevItems) => [...prevItems, ...newData.data]);
            setPage(page + 1);
            setLoading(false)

        } catch (error) {
            // return console.log(error.code)

            if (error.code === "ERR_NETWORK") {
                dispatch({
                    type: 'alert',
                    payload: { alert: { message: 'Neste momento, estás offline.' } },
                });
            }
        }
    }, [page]); // A função depende apenas de page, que é estável entre renderizações

    const fetchData = async (currentPage) => {
        const response = await api.get(`/perfil/meus-patrimonios?page=${currentPage}`);
        return response.data.meusPatrimonios;
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                fetchMoreData();
            }
        });

        if (feedRef.current) {
            observer.observe(feedRef.current);
        }

        return () => {
            if (feedRef.current) {
                observer.unobserve(feedRef.current);
            }
        };
    }, [feedRef, fetchMoreData]);



    return (
        <>
            {/* <div className="text-center">
                <form className="card card-body d-flex" >

                    <input className="form-control me-2 rounded-pill bg-transparent" name="filtrapatrimonio"
                        type="search" aria-label="Search" required />

                    <div className="text-center">
                        <button className="w-50 btn btn-success p-1 m-1" type="submit">
                            Filtro
                        </button>
                    </div>
                </form>
                <br />
            </div> */}

            <RegisterAccess page={'pagina meus patrimonios'} />
            {meusPatrimonios ?
                meusPatrimonios.map((patrimonio) => (
                    <CardPatrimonio key={patrimonio.id} item={patrimonio}  meusPatrimonios={meusPatrimonios} setMeusPatrimonios={setMeusPatrimonios}/>
                ))
                :
                <div ref={feedRef}>
                    <LoadingSkeleton />
                </div>
            }

            {(config?.next_page_url) &&
                <div ref={feedRef} className="text-center text-success">
                    <Loading />
                </div>
            }

            {(meusPatrimonios?.length === 0 && !loading) &&
                <>
                    <p className="text-center"><small>Ainda nao tens patrimônio registado.!</small></p>
                    <p className="text-center">
                        <Link to={'/registo-de-patrimonio'} className="nav-link">
                            <PatrimonioIcone />
                            <br />
                            <small>Faça o seu primeiro registo agora</small>
                        </Link>
                    </p>
                </>
            }

            <div className="text-start d-block d-md-none">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-header" id="flush-headingtwo">
                        <div className="text-center cursor-pointer " id="gridCheck" data-toggle="collapse" data-target="#flush-collapsetwo" aria-expanded="false" aria-controls="flush-collapsetwo">
                            <small className="text-success">
                                <PatrimonioDenunciadoIcone /> Ver minhas denuncias
                            </small>
                            <br />
                            <button className="btn btn-success my-2"> Click para ver!</button>
                        </div>
                    </div>
                    <div id="flush-collapsetwo" className="col-6accordion-collapse collapse" aria-labelledby="flush-headingtwo" data-bs-parent="#accordionFlushExample">
                        <MinhasDenuncias />
                    </div>
                </div>
            </div>
        </>
    )
}
