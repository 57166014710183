import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/home";
import Auth from "../services/auth/authControlRoutes";
import ProtectedRoute from "./ProtectedRouter";
import { Provider } from "react-redux";
import store from "../store";
import Feed from "../pages/home/feed";
import RegistoPatrimonio from "../components/cards/registerPatrimonio";
import LoginForm from "../pages/auth/loginForm";
import RegisterForm from "../pages/auth/registerForm";
import Agradecimentos from "../pages/home/agradecimentos"; 
import Apoios from "../pages/home/apoios";
import Parceiros from "../pages/home/parceiros";
import Filtro from "../pages/home/filtro";
import Search from "../pages/home/search";
import SobreMeencontre from "../pages/home/sobreMeencontre";
import TermosCondicoesServico from "../pages/home/termosCondicoesServico";

import MeuPefil from "../userProfile";
import MinhasPublicacoes from "../userProfile/components/minhasPublicacoes";
import Notificacoes from "../userProfile/components/notificacoes";
import Transferencias from "../userProfile/components/historicoTransferencias"; 
import MeusPatrimonios from "../userProfile/components/meusPatrimonios";
import MeusGuardados from "../userProfile/components/meusGuardados";

import CreateAnuncio from "../components/cards/createAnuncio";
import FullViewCard from "../components/cards/fullViewCard";
import HomeWithAuth from "../pages/home/homeWithOutAuth";
import Transferencia from "../userProfile/components/transferencia";
import EncerrarProcura from "../userProfile/components/encerrarProcura";
import LoginRoute from "./LoginRoute";

// CONFIGURACOES
import ConfigUser from "../userProfile/config/configUser";
import EditeAll from "../userProfile/config/editeAll";
import EditeNome from "../userProfile/config/components/updates/editeNome";
import EditeEmail from "../userProfile/config/components/updates/editeEmail";
import EditeContacto from "../userProfile/config/components/updates/editeContacto";
import EditeSenha from "../userProfile/config/components/updates/editeSenha";
import DesativarConta from "../userProfile/config/components/desativarConta";
import EncontrarSomente from "../pages/home/encontrarSomente";

// ADMIN
import TemplateAdmin from "../admin/template/templateAdmin";
import Dashboard from "../admin/dashboard";
import Config from "../admin/config";
import MenuControlGeral from "../admin/mapa/menuControlGeral";
import ResumoProvincia from "../admin/mapa/resumoProvincia";
import ListUsers from "../admin/dashboard/listUsers";
import TemplateGestUser from "../admin/gestUser/template";
import InformacoesUser from "../admin/gestUser/informacoesUser";
import ControlAnuncios from "../admin/dashboard/controlAnuncios";
import ControlPublicacoes from "../admin/dashboard/controlPublicacoes";
import ControlPatrimonios from "../admin/dashboard/controlPatrimonios";
import ControlAccesses from "../admin/components/controlAccesses";
import SobrePatrimonio from "../components/sobrePatrimonio";


function RoutesApp() {
    const access = localStorage.getItem("accessToken")

    return (
        <Provider store={store}>
            <BrowserRouter>
                <Auth>
                    <Routes>
                        <Route path="*" element={<HomeWithAuth />} />
                        <Route exact path="/" element={<HomeWithAuth />} />
                        <Route exact path="/publicacao/partilhada/:id" element={<HomeWithAuth partilhada={true} />} />
                        <Route  path="/termos-e-condicoes" element={<TermosCondicoesServico />} />
                        {/* <Route  path="/full-view/:id" element={<FullViewCard />} /> */}

                        <Route  element={<LoginRoute isAuthenticated={!!access} redirectPath={'/'} > <Home element={<Feed />} />  </LoginRoute>} path="/" />
                        <Route element={<LoginRoute isAuthenticated={!!access} redirectPath={'/feed'} > <LoginForm /> </LoginRoute>} path="/login" />
                        <Route element={<LoginRoute isAuthenticated={!!access} redirectPath={'/feed'} > <RegisterForm /> </LoginRoute>} path="/register" />

                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <Home element={<Agradecimentos />} /> </ProtectedRoute>} path="/agradecimentos" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <Home element={<Apoios />} /> </ProtectedRoute>} path="/apoios" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <Home element={<Parceiros />} /> </ProtectedRoute>} path="/parceiros" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <Home element={<SobrePatrimonio />} /> </ProtectedRoute>} path="/sobre-patrimonios" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <Home element={<SobreMeencontre />} /> </ProtectedRoute>} path="/sobre-meenconte" />

                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/'} > <Home element={<Feed />} /> </ProtectedRoute>} path="/feed" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <FullViewCard /> </ProtectedRoute>} path="/full-view/:id" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <Home element={<CreateAnuncio />} /> </ProtectedRoute>} path="/create-anuncio" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <Home element={<RegistoPatrimonio />} /> </ProtectedRoute>} path="/registo-de-patrimonio" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <Home element={<Filtro />} /> </ProtectedRoute>} path="/filtro" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <Home element={<Search />} /> </ProtectedRoute>} path="/search" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <Home element={<EncontrarSomente />} /> </ProtectedRoute>} path="/encontrar-somente" />

                        {/* <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/'} > <PatrimoniosDenunciado element={<FeedDenuncias url={'/patrimonios-denunciado?categoria=Denuncia'} />} /> </ProtectedRoute>} path="/patrimonios-denunciados" /> */}
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <Home element={<Notificacoes />} /> </ProtectedRoute>} path="/notificacoes" />

                        {/* PERFIL */}
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <MeuPefil element={<MinhasPublicacoes />} /> </ProtectedRoute>} path="/perfil/minhas-publicacoes" />
                        {/* <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <MeuPefil element={<Notificacoes />} /> </ProtectedRoute>} path="/perfil/notificacoes" /> */}
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <MeuPefil element={<Transferencias />} /> </ProtectedRoute>} path="/perfil/historico-transferencias" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <MeuPefil element={<Transferencia />} /> </ProtectedRoute>} path="/perfil/transferir-patrimonio/:id" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <MeuPefil element={<MeusPatrimonios />} /> </ProtectedRoute>} path="/perfil/meus-patrimonios" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <MeuPefil element={<MeusGuardados />} /> </ProtectedRoute>} path="/perfil/meus-guardados" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <MeuPefil element={<EncerrarProcura />} /> </ProtectedRoute>} path="/perfil/encerrar-procura/:id" />

                        {/* CONFIGURACOES DE USER */}
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <ConfigUser /> </ProtectedRoute>} path="/configuracoes" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <EditeAll element={<EditeNome />} /> </ProtectedRoute>} path="configuracoes/edite-nome" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <EditeAll element={<EditeEmail />} /> </ProtectedRoute>} path="configuracoes/edite-email" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <EditeAll element={<EditeContacto />} /> </ProtectedRoute>} path="configuracoes/edite-contacto" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <EditeAll element={<EditeSenha />} /> </ProtectedRoute>} path="configuracoes/edite-senha" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <EditeAll element={<DesativarConta />} /> </ProtectedRoute>} path="/configuracoes/desativar-conta" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <EditeAll element={<DesativarConta />} /> </ProtectedRoute>} path="/configuracoes/desativar-conta/:id" />

                        {/* ADMIN */}
                        {/* <Route element={<ProtectedRoute isAuthenticated={!!user?.includes('Admin')} redirectPath={'/login'} > <TemplateAdmin element={<Menu />} /> </ProtectedRoute>} path="/admin" /> */}
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <TemplateAdmin element={<Dashboard />} /> </ProtectedRoute>} path="/admin/dashboard" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <TemplateAdmin element={<ListUsers />} /> </ProtectedRoute>} path="/admin/list-users" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <TemplateAdmin element={<TemplateGestUser element={<InformacoesUser />} />} /> </ProtectedRoute>} path="/admin/gest-user/:id" />

                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <TemplateAdmin element={<ControlAnuncios />} /> </ProtectedRoute>} path="/admin/control-anuncios" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <TemplateAdmin element={<ControlPublicacoes />} /> </ProtectedRoute>} path="/admin/control-publicacoes" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <TemplateAdmin element={<ControlPatrimonios />} /> </ProtectedRoute>} path="/admin/control-patrimonios" />

                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <TemplateAdmin element={<MenuControlGeral />} /> </ProtectedRoute>} path="/admin/mapa" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <TemplateAdmin element={<ResumoProvincia />} /> </ProtectedRoute>} path="/admin/provincia/:provincia" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <TemplateAdmin element={<Config />} /> </ProtectedRoute>} path="/admin/configuracoes" />
                        <Route element={<ProtectedRoute isAuthenticated={!!access} redirectPath={'/login'} > <TemplateAdmin element={<ControlAccesses />} /> </ProtectedRoute>} path="/admin/control-accesses" />

                    </Routes>
                </Auth>
            </BrowserRouter>
        </Provider>
    );
}
export default RoutesApp;

