
import { useEffect, useRef, useState, useCallback } from 'react'
import LoadingSkeleton from '../../load/loadingSkeleton'
// import CardPatrimonioDenunciado from '../../components/cards/cardPatrimonioDenunciado';
import api from '../../services/api';
import { useDispatch } from 'react-redux';
import CardPatrimonio from '../../components/cards/cardPatrimonio';
import { Avatar, Typography } from '@mui/material';

export default function ControlPatrimonios({ perfil, url }) {
    const dispatch = useDispatch();
    const [feedItemsConfig, setIsLoadingConfig] = useState();
    const [feedItems, setFeedItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const feedRef = useRef(null);

    // console.log(feedItems)
    // console.log(feedItemsConfig)
    const fetchMoreData = useCallback(async () => {
        setLoading(true);
        dispatch({
            type: 'alert',
            payload: { alert: null },
        });
        try {
            const newData = await fetchData(page);
            setIsLoadingConfig(newData);
            setFeedItems((prevItems) => [...prevItems, ...newData.data]);
            setPage(page + 1);
            setLoading(false);
        } catch (error) {
            // return console.log(error.code)

            if (error.code === "ERR_NETWORK") {
                dispatch({
                    type: 'alert',
                    payload: { alert: { message: 'Neste momento, estás offline.' } },
                });
            }
        }
    }, [page]);

    const fetchData = async (currentPage) => {
        const response = await api.get(`/control-patrimonios?page=${currentPage}`);
        // console.log(response)
        // const data = await response.json();
        return response.data.patrimonios;
    };

    useEffect(() => {
        // Configurar o IntersectionObserver quando o componente montar
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                // Se o elemento observado estiver visível (o usuário fez scroll até lá),
                // carregue mais dados no feed
                fetchMoreData();
            }
        });

        // Observar o elemento do feed
        if (feedRef.current) {
            observer.observe(feedRef.current);
        }

        // Limpar o observador quando o componente desmontar
        return () => {
            if (feedRef.current) {
                observer.unobserve(feedRef.current);
            }
        };
    }, [feedRef, fetchMoreData]);


    return (
        <div className="card-body row row-cols-1 row-cols-md-4 g-5">
            {(loading && page === 1) ?
                <LoadingSkeleton />
                :
                <>
                    {feedItems?.map((patrimonio) => (
                        <div key={patrimonio.id} className="col mb-3 alert alert-dismissible fade show p-0" role="alert">
                            {/* {-- ######################################- CARD PATRIMONIO -##################################################### --} */}
                            {/* <CardPatrimonioDenunciado publicacao={patrimonio} perfil={perfil} /> */}
                          
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar src={`https://server.meencontre.com/public/storage/${patrimonio.user?.foto_perfil}`} style={{ marginRight: 5 }} />
                                <Typography noWrap style={{ lineHeight: 1 }} variant="body1">
                                    {patrimonio.user.nome}
                                </Typography>
                            </div>

                            <CardPatrimonio item={patrimonio} />
                        </div>
                    ))}
                </>
            }

            {(feedItemsConfig?.next_page_url || page === 1) ?
                <div ref={feedRef} className="text-center text-success">
                    <small className="spinner-border" role="status">_M_</small>
                    <br />
                    <small>Patrimonios</small>
                </div>
                :
                <p style={{ textAlign: 'center' }}>
                    Sem mais Patrimonios
                </p>
            }
        </div>
    )
}
