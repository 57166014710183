import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ isAuthenticated, children, redirectPath }) => {
    // console.log(isAuthenticated)

    if (isAuthenticated) {
        return children;
    }
    return <Navigate to={redirectPath} replace />;
};

export default ProtectedRoute