import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import LoadingShort from '../../../load/loadingShort';
import { useNavigate, useParams } from "react-router-dom";
import api from '../../../services/api';

export default function DesativarConta() {
    const user = useSelector(state => state.account.user);
    const [edite, setEdite] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const { id } = useParams()

    const handleInputChange = (e) => {
        setEdite({ ...edite, [e.target.name]: e.target.value, });
    };

    const handleEdite = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        // api.post(`/config/desativar-conta`, { ...edite, user_id: user.id })
        api.post(`/config/desativar-conta`, { ...edite, user_id: id })
            .then(response => {
                // console.log(response)
                setLoading(false);

                dispatch({
                    type: 'alert',
                    payload: { alert: response.data },
                });

                navigate(-1)

            }).catch(err => {
                console.log(err)
                setLoading(false);

                dispatch({
                    type: 'alert',
                    payload: { alert: err.response.data },
                });
            })
    }

    return (
        <form onSubmit={handleEdite} method="post">

            <p><strong className=" text-danger">DESATIVAR CONTA DE USUARIO</strong></p>
            <br />
            <input onChange={handleInputChange} className="form-control" type="password" name="password"  placeholder="Por favor insira a sua senha" />

            <div className="my-4">
                <button
                    // disabled
                    // disabled={loading}
                    type="submit"
                    className="btn btn-success w-50">
                    {loading ?
                        <LoadingShort />
                        :
                        'Desativar'
                    }
                </button>
            </div>
        </form>
    )
}
