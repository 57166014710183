import { useSelector } from 'react-redux';
import { Avatar } from '@mui/material';

export default function ImageNavbar({ nome }) {
    const user = useSelector(state => state.account.user);
    const apiUrl = process.env.REACT_APP_API_URL;

    return (
        <picture >
            {user?.foto_perfil ?
                <Avatar src={apiUrl + user?.foto_perfil} alt="img" style={{ border: 'solid rgb(2, 71, 0) 1px' }} />
                :
                <img src="/img/utilizador.svg" alt="img" style={{ width: '40px' }} title="Carrega já imagem de perfil" />
            }
        </picture>
    )
}
