import NavBar from '../../components/navbar'

export default function EditeAll({ element: component}) {
    return (
        <>
            <NavBar />

            <div className="container col-lg-4 text-center py-5">
                {component}
            </div>
        </>
    )
}
