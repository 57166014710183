import authService from '../services/authService';
export const LOGIN_SUCCESS = '@ACCOUNT/LOGIN_SUCCESS';
export const SILENT_LOGIN = '@ACCOUNT/SILENT_LOGIN';
export const SIGNOUT = '@ACCOUNT/SIGNOUT';
export const SIGNUP = '@ACCOUNT/SIGNUP';

const signInSocial = (socialData) => {
  return async (dispatch) => {
    const user = await authService.loginSocial(socialData); 

    dispatch({ 
      type: LOGIN_SUCCESS,
      payload: {
        user,
      },
    });
  };
};

const signIn = (credentials) => {
  return async (dispatch) => {
    const user = await authService.login(credentials); 

    dispatch({ 
      type: LOGIN_SUCCESS,
      payload: {
        user,
      },
    });
  };
};

const signUp = (fullName, email, password) => {
  return async (dispatch) => {
    const user = await authService.signUp(fullName, email, password);

    dispatch({
      type: SIGNUP,
      payload: {
        user,
      },
    });
  };
};

const setUserData = () => {
  return async (dispatch) => {
    const user = await authService.renewToken();
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user, 
      },
    });
  };
};


const signOut = () => {
  return async (dispatch) => {
    await authService.logout();
    dispatch({
      type: SIGNOUT,
    });
  };
};

export {signInSocial, signIn, signUp, setUserData, signOut };
