import Backdrop from '@mui/material/Backdrop';
import { useEffect } from 'react';

export default function LoadingBackdrop(props) {

    useEffect(() => {
        if (props.open) {
            const timeoutId = setTimeout(() => {
                localStorage.removeItem('accessToken');
                window.location.reload();
            }, 30000);

            return () => { clearTimeout(timeoutId); };
        }
    }, []);

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={props.open}
        >
            <div>
                <div className="d-flex justify-content-center text-success">
                    <small className="spinner-border" role="status"/>
                </div>

                <p style={{ marginLeft: 10, color: '#fff' }} >
                    {props.text ?? ''}
                </p>
            </div>
        </Backdrop>
    )
}

