
export default function NavConfig({ setNavSelected }) {
    return (
        <nav>
            <h5>Configurações</h5>
            <hr className="background-success" />
                <li onClick={() => setNavSelected(0)} className="dropdown-item btn btn-success">Conta</li>
                <li onClick={() => setNavSelected(1)} className="dropdown-item btn btn-success">Idioma </li>
                <li onClick={() => setNavSelected(2)} className="disabled dropdown-item btn btn-success">Notificações por email</li>
                {/* {{-- < li onClick={() => setNavSelected(3)} className="dropdown-item btn btn-success">Privacidade</> --}} */}
        </nav>
    )
}
