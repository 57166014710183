import { useEffect, useState } from 'react';
import authService from '../../services/authService';
import { Link } from 'react-router-dom';
import LoadingShort from '../../load/loadingShort';
import apiCNP from '../../services/apiCNP';
import MessageError from '../../messages/messageError';
import { FormControl, IconButton, InputAdornment } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { validacaoRegister } from "../../validacoes";
import RegisterAccess from '../../services/registerAccess';

export const RegisterForm = () => {
    const [userData, setUserData] = useState('');
    const [provincias, setProvincias] = useState([]);
    const [provincia, setProvincia] = useState('');
    const [municipios, setMunicipios] = useState([]);
    const [loading, setLoading] = useState(false);
    const [outrosLocais, setOutrosLocais] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);


    useEffect(() => {
        document.title = "criar conta" 

        apiCNP.get('/provincias')
            .then(response => {
                setProvincias(response.data.provincias);
            }).catch(err => '');
    }, []);


    function HandleChange(e) {
        e.target.files ?
            setUserData({ ...userData, [e.target.name]: e.target.files[0] })
            :
            setUserData({ ...userData, [e.target.name]: e.target.value });

        e.target.name === 'provincia' &&
            apiCNP.get(`/municipios-by-provincia/${e.target.value}`)
                .then(res => {
                    setMunicipios(res.data.municipios);
                    setProvincia(res.data.provincia);
                }).catch(err => '');

    }

    const handleRegister = async () => {
        setLoading(true);

        try {
          await authService.register(
                {
                    ...userData,
                    'provincia': provincia?.designacao ?? userData.provincia,
                    'nacionalidade': userData.nacionalidade ?? 'Angola',
                    'status': true,
                    'is_active': true,
                    'verificacao_identidade': false
                });

            //  senEmail();

            setLoading(false);
            // navigate("/feed")
            window.location.reload();
        } catch (error) {
            setLoading(false);
            // console.log(error)
            setErrorMessage(error.message);
        }
    };

    const validate = async (e) => {
        setErrorMessage('');
        if (!(await valida())) return;
    };

    async function valida() {
        try {
            await validacaoRegister(userData);
            handleRegister();
        }
        catch (error) {
            setErrorMessage(error);
        }
    }


//    async function senEmail() {
//         const msg = {
//             to: 'jonilson0050@gmail.com',
//             // to: userData?.email,
//             subject: 'A sua conta de usuario na plaforma meencontre foi criada com sucesso.',
//             html: `
//             <p>
//             Este é um email de validação de conta de usuario, o seu codigo de validação é  666872127
//             </p>

//            Este e-mail foi gerado automaticamente pela plataforma <b>meencontre </b> - Um lugar de eperanças</p>
//      `,
//         };
//        await apiArmed.post('/email/send', msg)
//             .then(response => {
//                 console.log('sendEmail...')
//                 console.log(response)
//             }).catch(err => {
//                 // console.log(err) 
//             });
//     }

    return (
        <>
        <RegisterAccess page={'pagina criar conta'} />
            {/* MESSAGEM DE ERRO  */}
            {errorMessage && <MessageError message={errorMessage} />}

            <div className='bg-white d-flex' style={{ minHeight: '100vh', marginTop: -105 }}>

                <div className="row align-items-center container m-auto p-0">

                    <div className="col d-none d-md-block ">
                        {/* <Link to={'/'}  > */}
                        <img className=" d-block" src="/icones/iconesMeencontre/meencotreVertical.svg" alt="meencontre" />
                        {/* </Link> */}
                        <h1 className='text-center' style={{ fontSize: 25 }}>
                            Um lugar de esperanças
                            <Link to={'/login'} className="btn btn-outline-success border-0 text-decoration-none">Entrar</Link>
                        </h1>
                    </div>

                    <div className="col">
                        <div className="d-block d-md-none mt-5">
                            {/* <Link to={'/'} > */}
                            <img className="img-fluid d-block m-auto" src="/icones/iconesMeencontre/meencotreVertical.svg" alt="meencontre" style={{ width: '70%' }} />
                            {/* </Link> */}

                            <h1 className='text-center' style={{ fontSize: 25 }}>
                                Um lugar de esperanças
                                <Link to={'/login'} className="btn btn-outline-success border-0 text-decoration-none ">Entrar</Link>
                            </h1>
                        </div>

                        <br />
                        <div className="row g-3">

                            <h1 className="text-dark" style={{ fontSize: 30 }}> <strong>Criar conta</strong> </h1>

                            <label htmlFor='outrosLocais'>
                                <input onClick={() => setOutrosLocais(!outrosLocais)} id="outrosLocais" className="form-check-input me-2" type="checkbox" />
                                Outros locais
                            </label>
                            {outrosLocais ?
                                <>
                                    <div> <input onChange={HandleChange} type="text" className="form-control" name="nacionalidade" placeholder="Nacionalidade" /> </div>
                                    <div> <input onChange={HandleChange} type="text" className="form-control" name="provincia" placeholder="Província" /> </div>
                                    <div> <input onChange={HandleChange} type="text" className="form-control" name="municipio" placeholder="Município" /></div>

                                </>
                                :
                                <>
                                    <div className="col-12 ">
                                        <select onChange={HandleChange} className="form-select" name="nacionalidade">
                                            <option value="Angola" selected> Angola </option>
                                        </select>
                                    </div>

                                    <div className="col-md-6">
                                        <select
                                            onChange={HandleChange}
                                            name="provincia"
                                            className="form-select"
                                            defaultValue={''}>
                                            <option value='' disabled selected>Selecionar provincia</option>
                                            {provincias.map((provincia) => (
                                                <option key={provincia._id} value={provincia._id}>{provincia.designacao}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-md-6">
                                        <select
                                            onChange={HandleChange}
                                            className="form-select"
                                            name="municipio"
                                            defaultValue={''}
                                        >
                                            <option disabled value='' selected>Selecionar município</option>
                                            {municipios.map((municipio) => (
                                                <option key={municipio._id} value={municipio.designacao}>{municipio.designacao}</option>
                                            ))}
                                        </select>
                                    </div>
                                </>}


                            {/* <!-- Name --> */}
                            <div className="col-md-6">
                                <input onChange={HandleChange} id="nome" className="form-control" type="text" name="nome" placeholder="Nome" required />
                            </div>

                            <div className="col-md-6">
                                <input onChange={HandleChange} id="contacto" className="form-control" type="number" name="contacto" placeholder="Contacto" required />
                            </div>

                            <div className="col-12">
                                <input onChange={HandleChange} id="email" className="form-control" type="email" name="email" placeholder="Email" required />
                            </div>

                            {/* <!-- Password --> */}
                            <div className="col-md-6">
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        required
                                        size="small"
                                        name="password"
                                        placeholder="Senha"
                                        onChange={HandleChange}
                                        id="outlined-adornment-password"
                                        autoComplete="current-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={<InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>} />
                                </FormControl>
                            </div>

                            {/* <!-- Confirm Password --> */}
                            <div className="col-md-6">
                                <FormControl fullWidth variant="outlined">
                                    <OutlinedInput
                                        required
                                        size="small"
                                        name="password_confirmation"
                                        placeholder="Confirmar senha"
                                        onChange={HandleChange}
                                        id="outlined-adornment-password"
                                        autoComplete="current-password"
                                        type={showPassword ? 'text' : 'password'} />
                                </FormControl>
                            </div>

                            <div className="text-center border-0 py-2" style={{ background: 'none' }}>
                                <button
                                    disabled={!userData?.nome || loading}
                                    onClick={validate}
                                    // disabled={loading}
                                    className="w-50 btn btn-success btn-sm lh-sm p-2">
                                    {loading ?
                                        <LoadingShort />
                                        :
                                        'Criar conta'}
                                </button>
                            </div>

                            <div className="lh-sm mt-4">
                                <small className="text-dark">
                                    Ao criar conta, você concorda com os Termos de
                                    Serviço e a Política de Privacidade, incluindo o Uso de Cookies.
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegisterForm;