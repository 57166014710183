
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import MenuLateral from "../../components/menuLateral";
import NavBar from "../../components/navbar";
import Feed from "./feed";
import Publicidades from "../../components/publicidades";

function HomeWithOutAuth({ partilhada }) {
    const navigate = useNavigate()
    const access = localStorage.getItem("accessToken")
    
    useEffect(() => {
        if (!!access && !partilhada) { navigate('/feed') }
    }, [])

    return (
        <>
            <NavBar />
            <div className="container p-0">
                <div className="row m-auto ">
                    <div className="col-3 d-none d-md-block p-0 ">
                        <div className="col-2 position-fixed overflow-auto" style={{ height: '88%', maxWidth: '20%', width: 370 }}>
                            {/* {{-- #################################################### saber mais sobre o MeEncontre! ############################################ --}} */}
                            <MenuLateral />
                        </div>
                    </div>

                    {/* {{-- ########################################### inicio do feed de noticias ######################################################### --}} */}
                    <div className="col px-0 mx-auto" style={{ maxWidth: '550px' }} >
                        <Feed />
                    </div>

                    {/* {{-- ################################# area de encontrados. publicidades e exibições de outros conteudos ######################################## --}} */}
                    <div className="col-2 mb-4 d-none d-md-block">
                        <div className="position-sticky" style={{ top: '100px' }}>
                            <Publicidades />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeWithOutAuth;
