import { Typography } from '@mui/material';


export default function ListaVazia({ text }) {
    return (
        <div align='center' style={{ marginTop: 50 }}>
            <Typography style={{ fontWeight: 'bold' }} >
                {text ?? 'A lista está vazia'}
            </Typography>
        </div>
    )
}
