import LoadingShort from "../../load/loadingShort";
import { validacaoAnuncioPessoa } from "../../validacoes";


export default function CreateAnuncioPessoa({ createAnuncio, anuncio, HandleChange, provincias, municipios, generos, isLoading, setErrorMessage, outrosLocais, autoResize }) {

    const validate = async e => {
        setErrorMessage('');
        if (!(await valida())) return
    };

    async function valida() {
        try {
            await validacaoAnuncioPessoa(anuncio)
            createAnuncio()
        }
        catch (error) {
            setErrorMessage(error);
        }
    }

    return (
        <div className="row g-3" encType="multipart/form-data">

            {outrosLocais ?
                    <>
                        <div> <input onChange={HandleChange} type="text" className="form-control" name="provincia" placeholder="Província" /> </div>
                        <div> <input onChange={HandleChange} type="text" className="form-control" name="municipio" placeholder="Município" /></div>

                    </>
                    :
                    <>
                        <div className="col-md-6">
                            <select
                                name="provinciaId"
                                onChange={HandleChange}
                                className="form-select"
                                defaultValue={''}
                            >
                                <option value='' selected disabled>Selecionar provincia</option>
                                {provincias.map((provincia) => (
                                    <option key={provincia._id} value={provincia._id}>{provincia.designacao}</option>
                                ))}
                            </select>
                        </div>


                        <div className="col-md-6">
                            <select
                                onChange={HandleChange}
                                className="form-select"
                                name="municipio"
                                defaultValue={''}
                            >
                                <option selected disabled value=''>Selecionar município</option>
                                {municipios.map((municipio) => (
                                    <option key={municipio._id} value={municipio.designacao}>{municipio.designacao}</option>
                                ))}
                            </select>
                        </div>
                    </>
            }

            <div> <input onChange={HandleChange} required type="text" className="form-control" name="nome" placeholder="Nome completo" /></div>
            <div> <input onChange={HandleChange} type="text" className="form-control" name="nome_pai" placeholder="Nome completo do pai" /> </div>
            <div> <input onChange={HandleChange} type="text" className="form-control" name="nome_mae" placeholder="Nome completo da mãe" /></div>

            <div className="col-md-6">
                <input onChange={HandleChange} type="number" className="form-control" name="idade" placeholder="Idade" />
            </div>

            <div className="col-md-6">
                <select
                    onChange={HandleChange}
                    className="form-select"
                    name="genero"
                    defaultValue={''}
                >
                    <option value={''} selected disabled>Genero</option>
                    {generos.map((genero) => (
                        <option key={genero.id} value={genero.genero}>{genero.genero}</option>
                    ))}
                </select>
            </div>

            <div>
                <textarea onChange={HandleChange} maxLength="2000" id="myTextarea" onInput={autoResize} className="form-control" placeholder="Descrição sobre o acontecimento" name="descricao" required ></textarea>
            </div>

            <div className="text-center">
                <label className="form-control text-center" htmlFor="img" style={{ cursor: 'pointer', border: '1px #1f1f1f dashed' }}>
                    {anuncio?.ficheiro &&
                        <>
                            <small> Preview:</small>
                            <img src={URL.createObjectURL(anuncio.ficheiro)} width="100%" />
                        </>
                    }

                    <small>Carregar imagem</small>
                    <input onChange={HandleChange} accept="image/png, image/jpg, image/jpeg" id="img" name="ficheiro" type="file" style={{ display: 'none' }} />
                </label>
            </div>

            <div className="text-center">
                <button
                    onClick={validate}
                    disabled={isLoading}
                    type="submit"
                    className="w-50 btn btn-success">
                    {!isLoading
                        ? 'Anunciar'
                        : <LoadingShort/>
                    }
                </button>
            </div>
        </div>
    )
}
