import { useState } from 'react'
import EliminarIcone from '../../icones/eliminar'
import LoadingShort from '../../load/loadingShort'
import api from '../../services/api'
import { useDispatch } from 'react-redux';

export default function CreatePaises() {
    const dispatch = useDispatch();
    const [paises, setPaises] = useState([])
    const [loading, setLoading] = useState('')


    const delet = (id) => {
        setLoading(id);
        dispatch({
            type: 'alert',
            payload: { alert: null }
        });

        api.post(`/--/${id}`)
            .then(response => {
                setLoading(false);
                setPaises(response.data)
                dispatch({
                    type: 'alert',
                    payload: { alert: response.data },
                });

            }).catch(err => {
                setLoading(false);
            })
    }

    return (
        <div class="card card-body mb-4">
            <div class="row g-3 m-0" >
                <h4>ADD PAISES</h4>

                <div class="col-md-6">
                    <input required type="text" class="form-control" placeholder="Novo país" name="pais" />
                    <button
                        disabled
                        // onClick={create}
                        class="btn btn-success mt-2">
                        {loading === 'Salvar'
                            ?
                            <LoadingShort />
                            :
                            'Salvar'
                        }
                    </button>
                </div>

                <div class="col-md-6">
                    <strong>Paises cadastrados</strong>
                    <ul>
                        {paises.map((pais) => (
                            <li key={pais.id} >
                                <button onClick={() => delet(pais.id)} className="btn btn-outline-success border-0 p-0" type="submit" title="apagar comentário">
                                    {loading === pais.id
                                        ?
                                        <LoadingShort />
                                        :
                                        <EliminarIcone />
                                    }
                                </button>
                                {pais.designacao}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>

    )
}
