import ApoiosIcone from "../../icones/apoios";
import RegisterAccess from "../../services/registerAccess";

export default function Apoios() {
    return (
        <div>
        <RegisterAccess page={'pagina apoios'} />
            <div className="card shadow">
                <div className="card-body">
                    <h5 className="card-title text-center p-2"><ApoiosIcone/> Nossos apoiadores</h5>
                    <p className=" card-text text-justify">
                        Nessa página apresentamos as pessoas singulares e empresas que acreditam no potencial deste
                        pojecto e têm dado suporte para o crescimento do mesmo.
                    </p>
                </div>
            </div>

            {/* <div className="m-4 mt-5">
                < img className="img-fluid d-block m-auto " src="imagens/meencontreM.jpg"
                    alt="MeEncontre!-Um lugar de esperança" style="width: 30%" />
                < img className="img-fluid d-block " src="imagens/apoiadores/logoCNP.png" alt="MeEncontre!-Um lugar de esperança" style="width: 30%" />

                < a classNameName={classNamees.img} href="https://cnp.ao/" target='_blank' rel="noreferrer" title='Visitar a plataforma CNP' />
                <img src="imagens/apoiadores/logoCNP.png" alt="CNP" width={120} />
        </div> */}
        </div >
    )
}
