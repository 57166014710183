
import { makeStyles, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CountUp from 'react-countup';


const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        width: '30%',
        minWidth: 180,
        height: 120,
        margin: 10, 
        border: '1px solid #e0e0e0',
        borderRadius: 10,
        transition: '.5s',
        '&:hover': { background: '#e0e0e0', transform: 'scale(1.1)', },

        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },

    count: {
        fontFamily: 'Work Sans',
        fontSize: 22,
        fontWeight: 'bold',
        color: '#284a62',
        transition: '.5s',
        // '&:hover': { fontSize: 40 }
    },
}))

export default function ServicesMenuControlGeral(props) {
    const classes = useStyles()

    return (
        <CardActionArea className={classes.root} >
            <Link style={{ textDecoration: 'none', color:'#292b2b' }} to={'/admin/provincia/'+props.provincia}>
                <CardContent style={{ display: 'flex' }}>
                    <div >
                        <img src={props.icone} alt={props.provincia} width={70} />
                    </div>

                    <div style={{ marginLeft: 10, width: '100%' }}>
                        <div style={{ display: 'flex' }}>
                            <Typography style={{ flexGrow: 1}}>
                                {props.provincia.toUpperCase()}
                            </Typography>

                            <Typography  align="right" className={classes.count}>
                                {/* BIBLIOTECA PARA APLICAR EFEITO DE CONTAGEM CRESCENTE */}
                                {/* <CountUp end={props.count} /> */}
                                <CountUp end={props.membrosGeral} />
                            </Typography>
                        </div>

                        <hr style={{ border: '1px solid #355d4d', margin: '5px 0' }} />

                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <div>
                                <small >
                                    USERS
                                </small>
                                <Typography align="right" >
                                <CountUp end={props.advogados} />
                                </Typography>
                            </div>
                            <div>
                                <small >
                                    ACTIVOS
                                </small>
                                <Typography align="right" >
                                    <CountUp end={props.estagiarios} />
                                </Typography>
                            </div>
                            <div>
                                <small >
                                N-ACTIVOS
                                </small>
                                <Typography align="right" >
                                <CountUp end={props.patronos} />
                                </Typography>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Link>
        </CardActionArea>
    )
}