
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { makeStyles } from '@material-ui/core';
import { Avatar, Box, Button, Card, IconButton, InputBase, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import Loading from '../../load/loading';
import MessageError from '../../messages/messageError';
import DateHora from '../../utils/dateHora';
import VisibilityIcon from '@mui/icons-material/DescriptionOutlined'
// import FerramentaNavegacaoEexpot from '../../secretariado_areaTecnica/listas/FerramentaNavegacaoEexpot';

const useStales = makeStyles({
    icone: {
        margin: 7,
        cursor: 'pointer',
        // '&:hover': {
        //     backgroundColor: '#b1b7ea',
        //     borderRadius: 3
        // },
    },
    hover: { '&:hover': { backgroundColor: '#d2d6ff', cursor: 'pointer', } }
})

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

export default function PesquisarUser(props) {
    const navigate = useNavigate()
    const classes = useStales()
    const [open, setOpen] = useState(false);
    const [name, setname] = useState('');
    const [pesquisa, setPesquisa] = useState('');
    const [openLoad, setOpenLoad] = useState(false);
    const [messageError, setMessageError] = useState('');
    const [print, setPrint] = useState(false)
    // const [configLista, setConfigLista] = useState('')

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const search = async (e) => {
        e.preventDefault()
        setPesquisa('')
        setMessageError('')
        setOpenLoad(true)

        try {
            const response = await api.post(`/get-user-by-name/${ name }`);
            // console.log(response)
            setPesquisa(response.data.users);
            // setConfigLista(response.data);
            setOpenLoad(false)

        } catch (error) {
            if (error.message === "Network Error") {
                setMessageError(error.message)
            }
            // console.log(error)
            setOpenLoad(false)
            setPesquisa('');
            setMessageError(error.response.data.message);
        }
    }

    return (
        <div>
            <PersonSearchIcon onClick={handleClickOpen} className={classes.icone} />

            <BootstrapDialog
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogContent >
                    {messageError && <MessageError message={messageError} />}

                    <Box m={2}>
                        <form onSubmit={search} style={{ display: 'flex', }}>
                            <InputBase
                                style={{ maxWidth: 560, marginLeft: 'auto', marginRight: 'auto' }}
                                required
                                autoFocus
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Pesquisar User"
                                onChange={(e) => setname(e.target.value)}
                            />
                            <IconButton type="submiy" aria-label="search">
                                <PersonSearchIcon />
                            </IconButton>
                        </form>
                    </Box>

                    {openLoad && <Loading text={'Pesquisando user'} />}

                    {pesquisa &&
                        <Card variant="outlined">
                        <TableContainer id={'listagemUser'} >
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {!print &&
                                            <>
                                                <TableCell align="center" ><Typography noWrap> <strong>Detalhes</strong></Typography></TableCell>
                                                <TableCell align="center" ><Typography noWrap> <strong>Foto</strong></Typography></TableCell>
                                            </>
                                        }
                                        <TableCell align="center" ><Typography noWrap> <strong>Nome</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>Status</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>Perfil</strong></Typography></TableCell>
                                        <TableCell align="center" ><Typography noWrap> <strong>Data criação</strong></Typography></TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {pesquisa.map((user) => (
                                        <TableRow key={user?.id} className={classes.hover}>
                                            {!print &&
                                                <>
                                                    <TableCell onClick={() => { navigate(`/admin/gest-user/${user?.id}`) }} align="center" >
                                                        <Button color="primary" size="small" variant="outlined">
                                                            <VisibilityIcon fontSize="small" />
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell align="center" component="th" scope="row">
                                                        <Avatar src={`data:image/jpeg;base64,${user?.foto_perfil}`} />
                                                    </TableCell>
                                                </>
                                            }
                                            <TableCell ><Typography noWrap>{user?.nome}</Typography></TableCell>
                                            <TableCell align="center" >{user?.status} </TableCell>
                                            <TableCell align="center" > <Typography noWrap>{user?.perfil}</Typography></TableCell>
                                            <TableCell > <Typography noWrap><DateHora date={user?.created_at} /> </Typography></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                            {/* 
          <FerramentaNavegacaoEexpot
            FerramentaNavegacaoExportacao={FerramentaNavegacaoExportacao}
            urlLista={urlLista}
            configLista={configLista}
            lista={lista}
            resolucao={resolucao}
            setView={setView}
            page={page}
            setPage={setPage}
            exportarExcel={exportarExcel}
          /> */}
                        </Card>
                    }
                </DialogContent>
            </BootstrapDialog>
        </div >
    );
}
