import { useState } from 'react'
import EliminarIcone from '../../icones/eliminar'
import LoadingShort from '../../load/loadingShort'
import api from '../../services/api'
import { useDispatch } from 'react-redux';

export default function CreateMunicipios() {
    const dispatch = useDispatch();
    const [provincias, setProvincias] = useState([])
    const [municipios, setMunicipios] = useState([])
    const [loading, setLoading] = useState('')


    const delet = (id) => {
        setLoading(id);
        dispatch({
            type: 'alert',
            payload: { alert: null }
        });

        api.post(`/--/${id}`)
            .then(response => {
                setLoading(false);
                setMunicipios(response.data)
                dispatch({
                    type: 'alert',
                    payload: { alert: response.data },
                });

            }).catch(err => {
                setLoading(false);
            })
    }

    return (
        <div className="card card-body mb-4">
            <div className="row g-3 m-0" >
                <h4>ADD MUNICIPIOS</h4>



                <div className="col-md-6">
                    <select className="form-select" >
                        <option disabled value="provincia" selected>Selecionar Provincia</option>
                        <ul>
                            {provincias.map((provincia) => (
                                <option key={provincia.id} value={provincia.id}>{provincia.designacao}</option>
                            ))}
                        </ul>
                    </select>
                </div>

                <div className="col-md-6">
                    <strong>Municipios cadastrados</strong>
                    <ul>
                        {municipios.map((municipio) => (
                            <li key={municipio.id} >
                                <button onClick={() => delet(municipio.id)} classNameName="btn btn-outline-success border-0 p-0" type="submit" title="apagar comentário">
                                    {loading === municipio.id
                                        ?
                                        <LoadingShort />
                                        :
                                        <EliminarIcone />
                                    }
                                </button>
                                {municipio.designacao}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="col-md-6">
                    <input required type="text" className="form-control " placeholder="Novo municipio" name="municipio" />
                    <button
                        disabled
                        // onClick={create}
                        class="btn btn-success mt-2">
                        {loading === 'Salvar'
                            ?
                            <LoadingShort />
                            :
                            'Salvar'
                        }
                    </button>
                </div>
            </div>
        </div>

    )
}
