import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon
} from 'react-share';
import ShareIcon from '@mui/icons-material/Share';
import { Tooltip } from '@mui/material';


const SocialShare = ({ publicacao }) => {
    // const url = `https://www.partilhado.meencontre.com/public/api/partilhado/facebook/${publicacao?.id}`

    return (
        <div style={{ display: 'flex', justifyContent: 'end', gap: 5, padding: 4 }}>
            {/* <small>Compartilhe este conteúdo:</small> */}
            <Tooltip placement='top' title="Compartilhe este conteúdo no:">
                <ShareIcon className='text-success ' />
            </Tooltip>

            <TwitterShareButton url={`https://www.partilhado.meencontre.com/public/api/partilhado/Twitter/${publicacao?.id}`} title={publicacao?.categoria?.categoria}>
                <TwitterIcon size={25} round />
            </TwitterShareButton>

            <WhatsappShareButton url={`https://www.partilhado.meencontre.com/public/api/partilhado/whatsapp/${publicacao?.id}`}>
                <WhatsappIcon size={25} round />
            </WhatsappShareButton>

            <FacebookShareButton url={`https://www.partilhado.meencontre.com/public/api/partilhado/facebook/${publicacao?.id}`} quote={publicacao?.categoria?.categoria} hashtag={'#meencontre_com'} >
                <FacebookIcon size={25} round />
            </FacebookShareButton>
        </div>
    );
};

export default SocialShare;
