import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { signOut } from '../../actions/accountActions';
import { useSelector } from 'react-redux';

function UserAdmin() {
    // const user = JSON.parse(localStorage.getItem("user"))
    const user = useSelector(state => state.account.user);
    const [isOpen, setOpen] = useState(false);
    const ref = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOpen = () => { setOpen(true) };

    const handleClose = () => { setOpen(false) };

    const handleSignOut = () => {
        dispatch(signOut());
        handleClose();
        navigate('/login')
        window.location.reload()
    };

    // console.log(user)

    return (
        <>
            <Avatar
                ref={ref}
                onClick={handleOpen}
                alt={user?.nome}
                src={`data:image/jpeg;base64,${user?.foto_perfil}`} 
                style={{ cursor: 'pointer' }}
            />
            <Menu
                anchorEl={ref.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={isOpen}
                onClose={handleClose}
                getContentAnchorEl={null}
            >
                <MenuItem disabled onClick={() => navigate('/admin/perfil-admin')}>
                    Configurações
                </MenuItem>
                <MenuItem onClick={handleSignOut}>
                    {/* <img src="/icones/menu/1deferido-assistenciacnp.svg" alt="" width={30} /> */}
                    Terminar sessão</MenuItem>
            </Menu>
        </>
    );
}

export default UserAdmin;
