import React from 'react'

export default function ConfigPrivacidade() {
    return (
        <div className="container ">
            <h5>Configuração de privacidade</h5>
            <hr />
            <div className="">
                <div className="col">
                    <p><a href="{{route('usuario.desativarconta') }}" className="text-decoration-none">Desativar conta</a></p>
                    {/* <p><a href="">Apagar conta</a></p>  */}
                </div>
            </div>
        </div>
    )
}
