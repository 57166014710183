import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import LoadingShort from '../../../../load/loadingShort';
// import { useNavigate } from "react-router-dom";
import api from '../../../../services/api';
import EditeIcone from '../../../../icones/edite';
import RegisterAccess from '../../../../services/registerAccess';

export default function EditeNome() {
    const user = useSelector(state => state.account.user);
    const [edite, setEdite] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    // const navigate = useNavigate()

    const handleInputChange = (e) => {
        setEdite({ ...edite, [e.target.name]: e.target.value, });
    };

    const handleEdite = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        api.post(`/config/edite-nome`, { ...edite, user_id: user.id })
            .then(response => {
                // console.log(response)
                setLoading(false);

                dispatch({
                    type: 'alert',
                    payload: { alert: response.data },
                });

                // navigate(-1)

            }).catch(err => {
                // console.log(err)
                setLoading(false);

                dispatch({
                    type: 'alert',
                    payload: { alert: err.response.data },
                });
            })
    }

    return (
        <form onSubmit={handleEdite} method="post">
          <RegisterAccess page={'pagina edite nome'} />

            <p>Editar Nome_<EditeIcone />
                <br />
                <strong>{user?.nome}</strong>
            </p>

            <input onChange={handleInputChange} className="form-control" placeholder="Novo nome" type="text" name="nome" required />
            <br />
            <input onChange={handleInputChange} className="form-control" type="password" name="password" required placeholder="Por favor insira a sua senha" />

            <div className="my-4">
                <button
                    disabled={loading}
                    type="submit"
                    className="btn btn-success w-50">
                    {loading ?
                        <LoadingShort />
                        :
                        'Salvar'
                    }
                </button>
            </div>
        </form>
    )
}
