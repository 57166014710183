
import { useState } from 'react';
import api from '../../services/api';
import ImageHeaderPublicacao from '../imageUser/imageHeaderPublicacao';
// import { useNavigate } from "react-router-dom";
import LoadingShort from '../../load/loadingShort';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import HeaderConfigs from '../../utils/headerConfigs';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
}));

export default function HeaderCardPerfilUser({ publicacao, setHandleUpdate }) {
    const dispatch = useDispatch();
    // const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const deletePublicacao = (e) => {
        e.preventDefault();
        setLoading(true);
        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        api.post(`/perfil/delete-publicaco/${publicacao.id}`
        ).then(response => {
            setHandleUpdate(response.data.publicacao)
            handleClose()
            // console.log(response)
            setLoading(false);
            dispatch({
                type: 'alert',
                payload: { alert: response.data },
            });

            window.location.reload()

        }).catch(err => {
            // console.log(err)
            setLoading(false);
        })
    }

    return (
        <div>
            <div className="d-flex mb-3" >
                <div style={{ flexGrow: 1 }} >
                    <ImageHeaderPublicacao publicacao={publicacao} />
                </div>

                <HeaderConfigs
                    configArea={'publicacaoPerfil'}
                    publicacao={publicacao}
                    handleClickOpen={handleClickOpen}
                />
            </div>

            {publicacao?.categoria?.id !== 2 &&
                <h5 className="card-title mt-3">{publicacao?.categoria?.categoria}</h5>
            }

            <BootstrapDialog
                scroll='body'
                fullWidth
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <form onSubmit={deletePublicacao} className="card m-0 border-0">
                    <div className="modal-content">
                        <div className="modal-header border-0 alert-success p-2">
                            <h5 className="modal-title" id="staticBackdropLabel">Eliminar {publicacao.tipo}?</h5>
                            <button onClick={handleClose} type="button" className="btn-close"></button>
                        </div>

                        <div className="modal-body text-start">
                            Tem serteza que queres eliminar {publicacao.tipo}?
                        </div>

                        <div className="mx-auto modal-footer  border-0">
                            <button onClick={handleClose} type="button" className="btn btn-sm  mx-3" >Cancelar</button>
                            <button
                                type="submit"
                                className="btn btn-sm btn-danger mx-3">
                                {loading ?
                                    <LoadingShort />
                                    :
                                    'Eliminar'
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </BootstrapDialog>
        </div >
    )
}
