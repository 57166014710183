import ServicesMenuProvincia from '../../utils/servicesMenuProvincia';
import { useParams } from 'react-router-dom';
import { Typography, makeStyles } from "@material-ui/core";
import { Box } from '@mui/system';
import HeaderSession from '../../utils/headerSession';
import { useEffect, useState } from 'react';
import api from '../../services/api';
import CountUp from 'react-countup';

const useStyles = makeStyles((theme) => ({

    containerCardesService: {
        // height: '60vh',
        // marginTop: 30,
        padding: '2rem',
        display: 'flex',
        flexWrap: 'wrap',
        // justifyContent: 'center',
        alignContent: 'center',
        gap: '1rem',
        padding: 0,
    }
}))
export default function ResumoProvincia() {
    const classes = useStyles();
    const { provincia } = useParams()

    const [farmacias, setFarmacias] = useState([])
    const [depositos, setDepositos] = useState([])
    const [membros, setMembros] = useState([])
    const [patronos, setPatronos] = useState([])
    // const [reload, setReload] = useState('')


    useEffect(() => {
        {
            provincia === 'luanda' &&
                Promise.all([
                    // api.get('/membros?perfil=Estagiário(a)'),
                    // api.get('/membros?perfil=Advogado(a)'),
                    // api.get('/membros'),
                    // api.get('/patronos'),
                    // api.get('/farmacias/approveds'),

                ]).then(response => {
                    // setfarmacias(response[0].data.totalItems);
                    // setdepositos(response[1].data.totalItems);
                    // setMembros(response[2].data.totalItems);
                    // setPatronos(response[3].data.totalItems);
                    // setRecemInscritos(response[4].data.totalItems);

                    // console.log(response[4].data);

                    // window.setTimeout(() => {
                    //     setReload(response)
                    //     // console.log('reload')
                    // }, 5000);

                }).catch(error => {
                    // if (error.response.status === 422) {
                    //     setMessageError(error.response.data.message)
                    // }
                    // console.log(error)
                })
        }
    }, [])

    
    // PEGAR OS PAGAMENTOS DA PROVINCIA
    const [total, setTotal] = useState(0);
    useEffect(() => {
        api.get(`/pagamentos?status=Aprovado`)
            .then(response => {
                // console.log(response)

                const totalValorPago = response.data.pagamentos.reduce((total, pagamento) => {
                    const valorPagoInteiro = parseInt(pagamento.valorPago, 10) || 0;
                    return total + valorPagoInteiro;
                }, 0);

                setTotal(totalValorPago)

            }).catch(err => { '' })
    }, []);
    // FIM PEGAR OS PAGAMENTOS


    return (
        <div >
        {/* <RegisterAccess page={`Resumo ${provincia}`}/> */}
            <HeaderSession title={'PROVINCIA SELECIONADA'} />

            <div style={{ marginTop: '5vh', display: 'flex', alignContent: 'center', justifyContent: 'center' }}>

                <div style={{ position: 'relative' }}>
                    <Typography align='center' variant='h1' style={{ fontSize: 25, fontWeight: 'bolder', color: '#292b2b' }}>
                        {provincia.toUpperCase()}
                    </Typography>
                    <br />
                    <img src={'/icones/mapaProvinciasAngola/' + provincia + '.svg'} alt={provincia} width={300} />
                    <Typography align='center' variant='h1' style={{ fontSize: 18, fontWeight: 'bolder', color: '#fff', position: 'absolute', top: '12em', left: '5em' }}>
                        Geral
                        <br />
                        <CountUp end={membros} />
                    </Typography>
                </div>

                <Box className={classes.containerCardesService}>
                    <ServicesMenuProvincia
                        icone={'/icones/menu/depositos.svg'}
                        title={'USERS TOTAL'}
                        count={depositos}
                    />

                    <ServicesMenuProvincia
                        icone={'/icones/menu/advogado-estagiario2.svg'}
                        title={'USERS ACTIVOS'}
                        count={farmacias}
                    />

                    <ServicesMenuProvincia
                        title={'USERS N-ACTIVOS'}
                        icone={'/icones/menu/patrono.svg'}
                        count={patronos}
                    />

                    {/* <ServicesMenuProvincia
                        title={'FINANÇAS'}
                        icone={'/icones/template/financas.svg'}
                        count={total}
                        financas={true} 
                    /> */}
                </Box>
            </div>
        </div>
    )
}
