// import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';


const LoginRoute = ({isAuthenticated, children, redirectPath }) => {

    // const isAuthenticated = useSelector(state => state.account.user);

    if (isAuthenticated) {
        return <Navigate to={redirectPath} replace />;
    }
    return children ;
};

export default LoginRoute