import GetNotificacoes from '../../components/cards/getNotificacoes'
import NotificacoesIcone from '../../components/notificacoes'
import RegisterAccess from '../../services/registerAccess'

export default function Notificacoes() {
    return (
        <div>
           <RegisterAccess page={'pagina de notificacoes'} />
            <h5 id="usuario.notificacao" className="text-center m-3"><NotificacoesIcone/> Notificações</h5>
            <GetNotificacoes />
        </div>
    )
}
