import api from './api';

const authService = {
  loginSocial: async (socialData) => {
    try {
      const response = await api.post(`/loginSocial`, socialData);
      console.log(response)
      localStorage.setItem('accessToken', response.data.token);
      return response.data.user;
    } catch (error) {
      throw error.response.data;
    }
  },


  login: async (credentials) => {
    try {
      const response = await api.post(`/login`, credentials);
      // console.log(response)
      localStorage.setItem('accessToken', response.data.token);
      return response.data.user;
    } catch (error) {
      throw error.response.data;
    }
  },

  register: async (userData) => {
    // console.log(userData)
    try {
      const response = await api.post(`/register`, userData);
      // console.log(response)
      localStorage.setItem('accessToken', response.data.token);
      return response.data;
    } catch (error) {
      // console.log(error)
      throw error.response.data;
    }
  },

  logout: async () => {
    try {
      await api.post(`/logout`);
      localStorage.removeItem('accessToken')
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  },

  renewToken: async () => {
    try {
      const response = await api.get('/renew-token');
      localStorage.setItem('accessToken', response.data.token);
      return response.data.user;
    } catch (error) {
      // console.error('Erro ao renovar o token:', error);
      return null;
    }
  },

  getUser: async () => {
    try {
      const response = await api.get(`/user`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },


  setToken: (token) => { localStorage.setItem('accessToken', token); },
  getToken: () => localStorage.getItem('accessToken'),
  removeToken: () => localStorage.removeItem('accessToken'),
  // isAuthenticated: () => !!this.getToken(),
  isAuthenticated: () => !!localStorage.getItem('accessToken'),
};

export default authService;
