import { Avatar, AvatarGroup } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

export default function CountEsperancasComentarios({ publicacao, viewPublicacao }) {
    const user_id = useSelector(state => state.account.user?.id);

    // console.log(publicacao?.esperancas)

    return (
        <div className="card-body row align-items-end border-bottom  p-0 m-0 p-1">
            {/* {-- quantidade de esperancas de usuarios --} */}
            <small className="col text-start ">
                {publicacao.esperancas.length < 2 ?
                    <small className="text-end col ">{publicacao.esperancas.length} esperança </small>
                    :
                    <small className="text-end col ">{publicacao.esperancas.length} esperanças </small>
                }
                <br />
                {publicacao.esperancas.find((e) => e.user_id == user_id) &&
                    <small className="text-success">Enviaste esperança</small>
                }
            </small>

            {/* <AvatarGroup total={publicacao?.esperancas.length}> 
                <div className='d-flex'>
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                    <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                    <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
                    <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
                </div>
            </AvatarGroup> */}

            {/* {-- quantidade de comentarios de usuarios --} */}

            {publicacao.comentarios.length < 2 ?
                <span className="text-end col fw-bold">{publicacao.comentarios.length} comentário
                <br />
                    {viewPublicacao &&
                        <Link onClick={() => viewPublicacao(publicacao)} to={`/full-view/${publicacao?.id}`} className="text-decoration-none text-success">
                            <small>Ver comentários</small>
                        </Link>
                    }
                 </span>
                :
                <span className="text-end col fw-bold">{publicacao.comentarios.length} comentários
                    <br />
                    {viewPublicacao &&
                        <Link onClick={() => viewPublicacao(publicacao)} to={`/full-view/${publicacao?.id}`} className="text-decoration-none text-success">
                            <small>Ver comentários</small>
                        </Link>
                    }
                </span>
            }
        </div>
    )
}
