import HeaderSession from '../utils/headerSession'
import CreateCategoriasAnuncio from './components/createCategoriasAnuncio'
import CreateGeneros from './components/createGeneros'
import CreateMunicipios from './components/createMunicipios'
import CreatePaises from './components/createPaises'
import CreateProvincias from './components/createProvincias'
import CreateTiposPatrimonio from './components/createTiposPatrimonio'

export default function Config() {
    return (
        <div className='mt-3 container'>
             <HeaderSession title={'CONFIGURAÇÕES'} />

            <CreateCategoriasAnuncio />
            <CreateTiposPatrimonio/>
            <CreateGeneros />
            
            <CreatePaises />
            <CreateProvincias />
            <CreateMunicipios />
        </div>
    )
}
