import { useEffect, useRef, useState, useCallback } from 'react';
import LoadingSkeleton from '../../load/loadingSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../load/loading';
import api from '../../services/api';
import CombineCards from './combineCards';
import GetPartilhado from './getPartilhado';
import { useParams } from 'react-router-dom';


export default function GetAnunciosPublicacoes({ setHandleUpdate, handleUpdate, perfil, url }) {
    const { id } = useParams()
    const dispatch = useDispatch();
    const [feedItemsConfig, setFeedItemsConfig] = useState(useSelector(state => state.publicacoes?.config));
    const [feedItems, setFeedItems] = useState(useSelector(state => state?.publicacoes.data) ?? '');
    const [page, setPage] = useState(useSelector(state => state?.publicacoes?.config?.last_page ?? 1));
    const feedRef = useRef(null);

    // console.log(feedItems)
    // console.log(page)

    useEffect(() => {
        dispatch({
            type: 'viewPublicacao',
            payload: { viewPublicacao: null },
        }); //dispatch para limpar a viewPublicacao que esta state da aplicação

        dispatch({
            type: 'publicacoes',
            payload: { publicacoes: feedItems, config: feedItemsConfig },
        }); //dispatch para por as publicacoes no state da aplicacao e evitar recarregar sempre retornas para p feed
    }, [page])

    const fetchMoreData = useCallback(async () => {

        dispatch({
            type: 'alert',
            payload: { alert: null },
        });

        try {
            const newData = await fetchData(page);
            setFeedItemsConfig(newData);
            setFeedItems((prevItems) => [...prevItems, ...newData.data]);
            setPage(page + 1);

        } catch (error) {
            // return console.log(error)

            if (error.code === "ERR_NETWORK") {
                dispatch({
                    type: 'alert',
                    payload: { alert: { message: 'Neste momento, estás offline.' } },
                });
            }
        }
    }, [page, handleUpdate]); // A função depende apenas de page e handleUpdate, que é estável entre renderizações

    const fetchData = async (currentPage) => {
        const response = await api.get(`${url}?page=${currentPage}`);
        return response.data.anuncios;
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                fetchMoreData();
            }
        });

        if (feedRef.current) {
            observer.observe(feedRef.current);
        }

        return () => {
            if (feedRef.current) {
                observer.unobserve(feedRef.current);
            }
        };
    }, [feedRef, fetchMoreData, handleUpdate]);


    return (
        <>
        
        { id && <GetPartilhado/>} 

            {(page === 1 && !feedItems) &&
                <div ref={feedRef} >
                    <LoadingSkeleton />
                </div>
            }

            <CombineCards
                publicacoes={feedItems}
                setHandleUpdate={setHandleUpdate}
                perfil={perfil}
            />

            {(feedItemsConfig?.next_page_url) &&
                <div ref={feedRef} className="text-center text-success">
                    <Loading />
                    <small>Encontre mais publicações</small>
                </div>

            }
            {(feedItems && !feedItemsConfig?.next_page_url) &&
                <p style={{ textAlign: 'center' }}>
                    <small>Sem publicações</small>
                </p>
            }
        </>
    );
}
