
import HeaderSession from '../../utils/headerSession'
import ControlAnunciosPublicacoes from '../components/control-anuncios-publicacoes'

export default function ControlPublicacoes() {
    return (
        <>
            <HeaderSession title={'CONTROL PUBLICAÇÕES'} />
            <div className='container mx-auto'>
                <ControlAnunciosPublicacoes perfil={true} url={'/control-anuncios-publicacoes?tipo=publicacao'} />
            </div>
        </>
    )
}
