
import './Loading.css'; // Arquivo de estilos CSS para o componente de loading

const Loading = ({ text }) => {
    return (
        <div className="loading-container" style={{ marginTop: 50 }}>
            <div className="text-center text-success">
                <small className="spinner-border" role="status"/>
            </div>
            {/* <p>Carregando...</p> */}

            {text && <p >{text}</p> }
        </div>
    );
};

export default Loading;